import React, { useState, useEffect } from 'react';
import MonitorBox from './MonitorBox';
import MonitorTabs from './MonitorTabs';
import { useSelector } from 'react-redux';
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import Loader from './../../../components/Loader';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase'
import StationsDialog from './StationsDialog';
import DeviceMenu from './DeviceMenu';
import SearchBox from './../../../components/Searchbox';
import FourierApi from './../../../utils/FourierApi';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'roboto',
    paddingBottom: '70px'
  },
  empty: {
    color: 'gray',
    paddingLeft: '11px'
  }
}));

// tabs types
const TABS = ['all', 'radio', 'tv', 'radioInternet', 'storage_warning', 'tunnel_warning'];

const MonitorView = (props) => {

  const classes = useStyles();
  const firebase = useFirebase();
  const [ selectedTab, setSelectedTab ] = useState(0); 
  // a device is selected when its 'more' button is pressed
  const [ deviceSelected, setDeviceSelected ] = useState({ deviceId: null, menuAnchor: null }); 
  // the stations dialog shows the stations of the selected device
  const [ showStationsDialog, setShowStationsDialog ] = useState(false);
  // input from searchbox 
  const [ searchText, setSearchText ] = useState('');
  // indicates the transition between tabs (used for css animation)
  const [ changingTab, setChangingTab ] = useState(false);
  
  const [ stations, setStations ] = useState([]);
  const [ role, setRole ] = useState(null);
  const [ allowedCitiesIds, setAllowedCitiesIds ] = useState(null);

  useEffect(() => {
    let isMounted = true;

    FourierApi  
      .getCities({
        pageSize: '0'
      })
      .then(cities => {
        if(isMounted) setAllowedCitiesIds(cities.map(city => city.id));
      });
    FourierApi
      .getProfile()
      .then(profile => {
        if(isMounted) setRole(profile.rol);
      })
    FourierApi
      .getStations({
        pageSize: '0'
      })
      .then(stations => {
        if(isMounted) setStations(stations);
      });
      
    return () => isMounted = false;
  }, []);

  useFirebaseConnect('devices');
  const rawDevices = useSelector(({ firebase: { data } }) => data.devices);
  
  if (!isLoaded(rawDevices) || allowedCitiesIds === null) {
    return <Loader/>;
  }

  let devices = convertDevices(rawDevices, allowedCitiesIds);
  let filteredDevices = [...devices];
  // filtering devices by the input in the searchbox
  if (searchText.trim()) {
    filteredDevices = filteredDevices
      .filter(device => 
          device.id && normalizeString(device.id.toString()).includes(searchText)
          || device.description && normalizeString(device.description).includes(searchText)
          || device.name && normalizeString(device.name).includes(searchText)
          || device.rtunnel_port && normalizeString(device.rtunnel_port.toString()).includes(searchText)
      ) 
  }

  // looking for issues in the devices
  const { diskWarningCounter, tunnelWarningCounter } = calculateWarningCounters(devices);

  // toggling the 'upload' property of the device on db
  const toggleUploadState = () => {
    const { deviceId } = deviceSelected;
    const device = filteredDevices.find(device => device.id === deviceId);
    if (device && device.id && device.id.trim()) {
      const newUpload = !device.upload;

      console.log(newUpload)

      firebase.update(`devices/${device.id}`, {
        upload: newUpload
      })
    }
  }

  return (
    <div className={classes.root}>
       <MonitorTabs 
          onChange={(e, index) => { 
            setChangingTab(true); // indicates the start of the css animation
            setTimeout(() => {
              setChangingTab(false);
              setSelectedTab(index);
              // css animation is over so now we actually change the selected tab
            }, 100);
          }}
          diskWarnedCounter={diskWarningCounter}
          tunnelWarnedCounter={tunnelWarningCounter}
          />
      <Grid
        container
        spacing={2}
        style={{margin: '0 auto', width: '100%'}}>
        <Grid item xs={12}>
          <SearchBox 
            placeholder='Encuentra una caja'
            style={{ margin: '0' }}
            onChange={e => setSearchText(normalizeString(e.target.value))}
            />
        </Grid>
        {
          filteredDevices && filteredDevices.length ?
            filteredDevices
              .filter(device => belongsInTab(selectedTab, device))
              .map(device => (
                <MonitorBox
                  device={device}
                  key={device.id}
                  gone={changingTab}
                  onMore={e => {
                    // when the 'more button' is pressed on a monitor box then that device becomes 'selected'
                    setDeviceSelected({ deviceId: device.id, menuAnchor: e.currentTarget })
                  }}
                  // onRecorderClick={this.openSources.bind(this, {box: item})} 
                />))
            :
            <Typography
              className={classes.empty}
              variant="subtitle1">
              No se encontraron cajas
            </Typography>
        }
        { 
          showStationsDialog ?
            // dialog that shows the stations of the selected device
            <StationsDialog
              stations={stations}
              device={filteredDevices.find(device => device.id === deviceSelected.deviceId)}
              open={true}
              onClose={() => { 
                setShowStationsDialog(false);
                setDeviceSelected({ device: null, menuAnchor: null }); 
              }}
              />
            : null
        }
        {
          deviceSelected.deviceId?
            // menu that pops up when the 'more' button of the monitor box is selected
            <DeviceMenu
              role={role}
              stations={stations}
              device={filteredDevices.find(device => device.id === deviceSelected.deviceId)}
              menuAnchor={deviceSelected.menuAnchor}
              open={Boolean(deviceSelected)}
              onToggleUploadState={() => toggleUploadState()}
              onEdit={() => props.history.push(`/cities/edit/${deviceSelected.deviceId}`)}
              onOpenStations={() => setShowStationsDialog(true)}
              onClose={() => { setDeviceSelected({ device: null, menuAnchor: null }) }}
              />
            : null
        }
      </Grid>
    </div>
  );

};

export default MonitorView;

const normalizeString = str => {
  return str.toUpperCase()
    .normalize('NFD')
    .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
    .normalize();
}

// callback to filter the monitor boxes by the selected tab
const belongsInTab = (tabIndex, device) => {
  if (TABS[tabIndex] === 'all') return true;
  if (TABS[tabIndex] === device.boxType) return true;
  if (TABS[tabIndex] === 'storage_warning') return isStorageInDanger(device);
  if (TABS[tabIndex] === 'tunnel_warning') return isTunnelFailing(device);
 // return TABS[tabIndex] === 'radio'; // if a device doesn't have a type it is put in radio section
}

// indicates if the device is running out of disk storage
const isStorageInDanger = device => (
  device && device.monitor && device.monitor.disk && device.monitor.disk.used 
    && device.monitor.disk.used * 100 / device.monitor.disk.total > 80
);

const isTunnelFailing = device => (
  device.status === 'online'
  && device.rtunnel_port
  && device.tunnelStatus !== 'online'
);

// algorithm to sort the devices
const devicesSortCallback = (a, b) => {
  const weightA = 0
    + (a.status === 'online' ? 4 : 0)
    + (a.server && a.server.isUp ? 1 : 0)
    + (a.uploader && a.uploader.isUp ? 1 : 0)
    + (a.ondemand && a.ondemand.isUp ? 1 : 0)
    + (a.tunnelStatus === 'online' ? 1 : 0)
    + (a.monitor && isStorageInDanger(a.monitor) ? 0 : 1);
  const weightB = 0
    + (b.status === 'online' ? 4 : 0)
    + (b.server && b.server.isUp ? 1 : 0)
    + (b.uploader && b.uploader.isUp ? 1 : 0)
    + (b.ondemand && b.ondemand.isUp ? 1 : 0)
    + (b.tunnelStatus === 'online' ? 1 : 0)
    + (b.monitor && isStorageInDanger(b.monitor) ? 0 : 1);
  if (weightA > weightB) {
    return -1;
  } else if (weightB > weightA) {
    return 1;
  } else {
    return 0;
  }
}

// counting the issues in the disk of a device
const calculateWarningCounters = devices => {
    var diskWarningCounter = 0;
    var tunnelWarningCounter = 0;
    for (var key in devices) {
      const device = devices[key];
      if (isStorageInDanger(device)) {
        diskWarningCounter += 1;
      }
      if (isTunnelFailing(device)) {
        tunnelWarningCounter += 1;
      }
    }
    return { diskWarningCounter, tunnelWarningCounter };
}

// transforming the devices from object to array, filtering by the users's allowed cities 
  // and adding some properties
const convertDevices = (devices, allowedDevicesIds) => {
  const newDevices = [];
  for (let key in devices) {
    if (allowedDevicesIds.includes(key)) {
      const device = devices[key];
      device.id = key;
      device.monitor = device.monitor || {};
      device.monitor.server = device.monitor.server || {};
      device.monitor.monitor = device.monitor.monitor || {};
      device.monitor.uploader = device.monitor.uploader || {};
      device.monitor.ondemand = device.monitor.ondemand || {};
      device.fake = !device.boxType;
      device.boxType = device.boxType || 'radio';
      newDevices.push(device);
    }
  }
  newDevices.sort(devicesSortCallback);
  return newDevices;
}