import { useState, useEffect, useContext } from 'react';
import PermissionsContext from './../context/permissions/permissionsContext';

const useAllowed = (rules) => {

  const [ allowed, setAllowed ] = useState(false);
  const { permissions } = useContext(PermissionsContext);

  useEffect(() => {
    if (permissions != null) {
      const modules = Object.keys(rules);
      const allowed = modules.every(module => {
        const actions = rules[module];
        const permission = permissions[module];
        if (permission === undefined) return false;
        return actions.every(action => permission && permission[action])
      });
      setAllowed(allowed);
    }
  }, [ rules, permissions ]);

  return (permissions === null? null : allowed);

}

export default useAllowed;