import { 
  SET_BOXTYPE,
  SET_STATIONS_IDS,
  SET_CITY,
  SET_SOURCE,
  SET_SOURCE_INDEX_PLAYING,
  SET_LOADING_STREAM,
  SET_STREAMING,
  SET_ERROR
} from './../../types';
    
export default (state, action) => {
  switch (action.type) {
    case SET_STATIONS_IDS:
      return {
        ...state,
        stationsIds: action.payload
      }
    case SET_BOXTYPE:
      return {
        ...state,
        boxType: action.payload
      }
    case SET_CITY:
      return {
        ...state,
        city: action.payload
      }
    case SET_SOURCE:
      return {
        ...state,
        source: action.payload
      } 
    case SET_SOURCE_INDEX_PLAYING: 
      return {
        ...state,
        indexPlaying: action.payload
      }
    case SET_LOADING_STREAM:
      return {
        ...state,
        loading: action.payload
      }
    case SET_STREAMING:
      return {
        ...state,
        streaming: action.payload
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default: 
      return state;
  }
}