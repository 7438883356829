import React from 'react';
import { TextField, List, ListItem, ListItemIcon, ListItemText, Collapse, 
  Checkbox, Grid, Box, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'roboto',
    height: 'calc(100vh - 500px)',
    overflowY: 'auto', 
    marginTop: 10
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const StationsSelector = ({onChangeTextField, searchStations, filteredCities, onExpanded, stationsAndChannels, onChangeAllStationsForCity, onChangeStationsChecked, onChangeAllCitiesChecked, allCitiesChecked}) => {
  
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10} md={10}>
          <TextField
            fullWidth
            value={searchStations}
            onChange={event => onChangeTextField(event.target.value)}
            variant="filled"
            label="Filtrar estaciones o canales"
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <Box display="flex" justifyContent="center">
            <Checkbox
              disabled={!filteredCities.length}
              checked={allCitiesChecked}
              onChange={event => onChangeAllCitiesChecked(event.target.checked)}
              color="primary"
            />
          </Box>
          <Typography align="center">Todos</Typography>
        </Grid>
      </Grid>
      <List component="nav" className={classes.root}> 
      {
        filteredCities.map((city, index) => (
          <div key={city.id}>
            <ListItem button  onClick={() => onExpanded(index, !city.expanded)}>
              <ListItemIcon>
                <Checkbox
                  checked={city.allstationsCheckedForCity}
                  onChange={event => {onChangeAllStationsForCity(index, event.target.checked)}}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText > { city.nombre } ({ city.id }) </ListItemText>
              {
                city.expanded 
                ? 
                  <ExpandLess /> 
                : <ExpandMore />
              }
            </ListItem>
             
            <Collapse in={city.expanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              {
                city.estaciones.map((_estacion, _index) => 
                  (stationsAndChannels.find(s => s.id == _estacion && s.ciudadEstacion.find(c => c.monitoreable && c.idEstacion == _estacion && c.idCiudad == city.id)))
                  ? 
                    (
                      <ListItem key={_index} className={classes.nested}>
                        <ListItemIcon>
                          <Checkbox
                            checked={city.estacionesChecked[_index]}
                            onChange={event => onChangeStationsChecked(event.target.checked, index, _index)}
                            color="primary"
                          />
                        </ListItemIcon>
                        <ListItemText>
                        [{ (stationsAndChannels.find(s => s.id == _estacion)).estado }] 
                        [{ (stationsAndChannels.find(s => s.id == _estacion)).frecuencia }]
                        [{ (stationsAndChannels.find(s => s.id == _estacion)).siglas }]
                        { " " + (stationsAndChannels.find(s => s.id == _estacion)).nombre + " " } 
                        { (stationsAndChannels.find(s => s.id == _estacion)).descripcion ? (stationsAndChannels.find(s => s.id == _estacion)).descripcion : 'Sin descripción' }
                        [{ " " + (stationsAndChannels.find(s => s.id == _estacion)).cadena ? (stationsAndChannels.find(s => s.id == _estacion)).cadena : 'Sin cadena' }]
                       </ListItemText>
                      </ListItem>
                    )
                  :
                    (null)
                )
              }
              </List>
            </Collapse>
          </div>
        ))
      } 
      </List>
    </>
  );
}

export default StationsSelector
