import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle } from '@material-ui/core';

const DeleteDialog = ({ open, onClose, onAccept }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle >¡Advertencia!</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
        ¿Seguro que desea eliminar este registro?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={onClose} variant="contained" >
          Cancelar
          </Button>
          <Button onClick={onAccept}  variant="contained" color="secondary" autoFocus>
          Aceptar
          </Button>
      </DialogActions>
  </Dialog>
  );
};

export default DeleteDialog;