import asyncComponent from '../components/AsyncComponent';

const HomeView = asyncComponent(() => import('./HomeView'));
const CampaignsView = asyncComponent(() => import('./campaigns/CampaignsView'));
const CampaignNewView = asyncComponent(() => import('./campaigns/CampaignNewView'));
const CampaignEditView = asyncComponent(() => import('./campaigns/CampaignEditView'));
const ReportsView = asyncComponent(() => import('./reports/ReportsView'));
const StationsView = asyncComponent(() => import('./firebaseStations/StationView'));
const StationsEditView = asyncComponent(() => import('./firebaseStations/StationEditView'));
const DevicesView = asyncComponent(() => import('./devices/DevicesView'));
import DeviceChannelsView from './devices/DeviceChannelsView';
import DeviceIntenetRadiosView from './devices/DeviceIntenetRadiosView';
import DeviceRadiosView from './devices/DeviceRadiosView';
import DevicesRemainingFilesView from './devices/DevicesRemainingFilesView';
const UsersView = asyncComponent(() => import('./users/UsersView'));
const LogInView = asyncComponent(() => import('./LogInView'));
const DeviceEditView = asyncComponent(() => import('./devices/DeviceEditView'));
import MonitorView from './monitor/MonitorView';
import UserProfileView from './users/UserProfileView';
import StationNewView from './firebaseStations/StationNewView';
const ScheduledView = asyncComponent(() => import('./ScheduleView/index.js'));
import AdminView from './AdminView';
import UserNewView from './users/UserNewView';
import UserEditView from './users/UserEditView';
const ReportsNewView = asyncComponent(() => import('./reports/ReportsNewView'));
const CampaignSelectView = asyncComponent(() => import('./campaigns/CampaignSelectView'));
const DependenciesView = asyncComponent(() => import('./dependencies/DependenciesView'));
const DependencyNewView = asyncComponent(() => import('./dependencies/DependencyNewView'));
const DependencyEditView = asyncComponent(() => import('./dependencies/DependencyEditView'));
const DependencyView = asyncComponent(() => import('./dependencies/DependencyView'));
const EntitiesView = asyncComponent(() => import('./entities/EntitiesView'));
const EntityNewView = asyncComponent(() => import('./entities/NewEntityView'));
const EntityEditView = asyncComponent(() => import('./entities/EntityEditView'));
const PlayerView = asyncComponent(() => import('./player/PlayerView/index.js'));
const NewStateView = asyncComponent(() => import('./states/NewStateView'));
const NewCityView = asyncComponent(() => import('./cities/NewCityView'));
const EditCityView = asyncComponent(() => import('./cities/EditCityView'));
const CityStations = asyncComponent(() => import('./cities/CitySources/index.js'));
const CityView = asyncComponent(() => import('./cities/CityView'));
const CitiesView = asyncComponent(() => import('./cities/CitiesView'));
const NewStationView = asyncComponent(() => import('./stations/NewStationView'));
const AuthView = asyncComponent(() => import('./test/AuthView'));
const New_StationsView = asyncComponent(() =>  import('./stations/StationsView'));
const EditStationView = asyncComponent(() => import('./stations/EditStationView'));
import StationView from './stations/StationView';
const StatesView = asyncComponent(() => import('./states/StatesView'));
const StateEditView = asyncComponent(() => import('./states/StateEditView.js'));
const CampaignView = asyncComponent(() => import('./campaigns/CampaignView'));
const AudiosView = asyncComponent(() => import('./audios/AudiosView'));
const AudioEditView = asyncComponent(() => import('./audios/AudioEditView'));
const AudioVersions = asyncComponent(() => import('./audios/AudioVersions'));
import CampaignReport from './campaigns/CampaignReport';
import CampaignStationReport from './campaigns/CampaignStationReport';
const GuidelineNewView = asyncComponent(() => import('./campaigns/GuidelineNewView'));
const UserPermissionsView = asyncComponent(() => import('./users/UserPermissions'));
const UserView = asyncComponent(() => import('./users/UserView'));
const NotFoundView = asyncComponent(() => import('./NotFoundView'));
const DevicesStatusView = asyncComponent(() => import('./monitor/DevicesStatusView'));
import StationsStreamView from './StationsStreamView';
import CampaignCreate from './campaigns/CampaignCreate/index.js';
const CampaignCreate2 = asyncComponent(() => import('./campaigns/CampaignCreate2'));
const CampaingCreatePlantilla = asyncComponent(() => import('./campaigns/CampaingCreatePlantilla'));
const PlantillasView =  asyncComponent(() => import('./plantillas/PlantillasView'));
const PlantillasNewView =  asyncComponent(() => import('./plantillas/PlantillasNewView'));
const GruposView =  asyncComponent(() => import('./grupos/GruposView'));
const PlayerAudioView =  asyncComponent(() => import('./playerAudio/PlayerAudioView'));
const MonitorView2 =  asyncComponent(() => import('./monitor2/MonitorView'));
const PlayerAdmin =  asyncComponent(() => import('./playerAdmin'));
const BackgroundView =  asyncComponent(() => import('./background/BackgroundView'));
const ReportWebView =  asyncComponent(() => import('./campaigns/CampaignStationReport/ReportWebView'));


export {
  HomeView, CampaignsView, ReportsView, 
  StationsView, UsersView, LogInView, StationsEditView,
  DevicesView, AudiosView, DeviceEditView, ScheduledView,
  DeviceChannelsView, DeviceIntenetRadiosView, UserNewView,
  UserProfileView, AdminView, StationNewView, StationsStreamView,
  UserEditView, CampaignNewView, CampaignEditView, ReportsNewView,
  MonitorView, CampaignSelectView, DeviceRadiosView, PlayerView,
  DependenciesView, EntitiesView, EntityNewView, DependencyNewView,
  NewStateView, NewCityView, NewStationView, NotFoundView,
  AuthView, New_StationsView, EditStationView, StatesView,
  CitiesView, EditCityView, DependencyEditView, EntityEditView,
  CampaignView, CampaignReport, UserPermissionsView,
  UserView, DependencyView, CityView, StationView, DevicesStatusView,
  CampaignStationReport, GuidelineNewView, DevicesRemainingFilesView,
  CampaignCreate, StateEditView, CityStations, AudioEditView,
  AudioVersions, CampaignCreate2, PlantillasView, PlantillasNewView,
  CampaingCreatePlantilla, GruposView, PlayerAudioView, MonitorView2,
  PlayerAdmin, BackgroundView, ReportWebView
};
