import React from 'react';
import { 
  List, ListItem, Collapse,
  ListItemSecondaryAction, Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '0'
  },
  listItem: {
    backgroundColor: '#e6e6e6'
  }
}));

const SourcesList = ({ open, sources, selected, onChange, selectAll }) => {
  const classes = useStyles();
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List
        className={classes.root}>
        {
          sources
            .map(source => {
              if (source) {
                const checked = selected.includes(source.id)
                return (
                  <ListItem
                    className={classes.listItem}
                    key={source.id}>
                    [{source.estado}] [{ source.frecuencia }] { source.nombre } 
                    <ListItemSecondaryAction>
                      <Checkbox 
                        disabled={selectAll}
                        onChange={() => onChange && onChange(source.id)}
                        checked={selectAll ? true : checked}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }
              return null;
            })
        } 
      </List>
    </Collapse>
  );
}

export default SourcesList;