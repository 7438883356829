import React from 'react';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';

const AudioSelector = ({ audios=[], onChange, id }) => {
  return (
    <FormControl fullWidth variant="filled">
      <InputLabel> Audio </InputLabel>
      <Select
        onChange={event => onChange(event.target.value)}
        value={id}>
        {
          audios.map(audio => (
            <MenuItem key={audio.id} value={audio.id}>
              { `${audio.nombre} - ${audio.clave}` }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default AudioSelector;