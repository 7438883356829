import React from 'react';
import { makeStyles } from '@material-ui/core';
import StreamAnimation from '../../../components/StreamAnimation';

const useStyles = makeStyles(theme => ({
  root: {
  },
  anim: {
    height: 'calc(100% - 35px)'
  },
  info: {
    height: "35px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#abaaaa",
    paddingLeft: theme.spacing(1.5),
    fontFamily: "roboto", 
    '& > span': {
      marginRight: theme.spacing(1),
    },
    '& > span:empty': {
      marginRight: '0',
    }
  }
}));

const StationInfo = ({ source }) => {
  const classes = useStyles();
  const { nombre, frecuencia, nombreCiudad, idCiudad } = source;
  return (
    <div className={classes.root}>
      <div className={classes.anim}>
        <StreamAnimation text={source.frecuencia}/>
      </div>
      <div className={classes.info}>
        <span> { nombreCiudad || idCiudad } </span> |&nbsp;
        <span> { nombre } </span> |&nbsp;
        <span> { frecuencia }  </span>
      </div>
    </div>
  );
};

export default StationInfo;