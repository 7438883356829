import {
  Home, Sms, Description,
  Group, Games, Inbox
} from '@material-ui/icons';

import {
  ADMIN,
  SOPORTE,
  OPERADOR
} from './../../constants/roles';

const links = [
  {
    to: '/reports',
    text: 'Buscador', 
    permission: 'BUSCADOR', 
    icon: Description, 
    role: OPERADOR 
  },
  { 
    to: '/schedule', 
    text: 'Pautas',
    permission: 'CAMPANA',
    icon: Sms, 
    role: OPERADOR 
  },
  { 
    to: '/player', 
    text: 'Histórico',
    permission: 'REPRODUCTOR', 
    icon: Group, 
    role: OPERADOR 
  },
  { 
    to: '/stations-stream', 
    text: 'En Vivo', 
    permission: 'TRANSMISION',
    icon: Games, 
    role: OPERADOR 
  },
  { 
    to: '/admin', 
    text: 'Administración',
    permission: null,
    icon: Inbox, 
    role: ADMIN 
  }
];

export default links;