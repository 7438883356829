import React, { useEffect, useState } from 'react';
import FourierApi from '../../../utils/FourierApi';
import Container from '../../../components/Container';
import Loader from '../../../components/Loader';
import Alert from '../../../components/Alert';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListIcon from '@material-ui/icons/List';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Report = ({history}) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [campaingsSelected, setCampaingsSelected] = useState([]);
  const [error, setError] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      try {
        const _campaigns = await FourierApi.getCampaigns();

        if(mounted) setCampaigns(_campaigns);  
      } catch (error) {
        console.log(error);
      } finally {
        if(mounted) setLoading(false);
      }
    }

    getData();

    return () => mounted = false;
  }, []);


  if(loading) return <Loader />;

  const handleChange = (event, newValue) => {
    setCampaingsSelected(newValue);
  };
  
  const downloadZip = async () => {
    if(!campaingsSelected.length) {
      setError('Favor de seleccionar una o más campañas.');
      setTimeout(() => {
        setError('');
      }, 3000);
      
      return;
    }
    
    try {
      setDownloading(true);
      const ids = campaingsSelected.map(cs => (cs.id)).join();
      const urls = await FourierApi.getReport(ids);

      if(!urls.data.reportes.length) {
        setError('No se encontraron resultados.');
        setTimeout(() => {
          setError('');
        }, 3000);

        return;
      }

      const config = { responseType: 'blob' };
      const zip = new JSZip();
      const urlsLength = urls.data.reportes.length;

      for (let i = 0; i < urlsLength; i++) {
        const blob = await axios.get(urls.data.reportes[i].url, config);
        zip.file(urls.data.reportes[i].nombre, blob.data);
        setPercentage(((i * 100) / urlsLength).toFixed(2));
      }

      const content = await zip.generateAsync({ type: "blob" });

      setPercentage(100);
      saveAs(content, urls.data.nombreZip);
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
    }
  }
  const toReportWeb = async () => {
    if(!campaingsSelected.length) {
      setError('Favor de seleccionar una o más campañas.');
      setTimeout(() => {
        setError('');
      }, 3000);
      
      return;
    }

    history.push({
      pathname: '/reports/web',
      state: { campaingsSelected: campaingsSelected }
    });
  }

  return (
    <Container>
      <Card style={{padding: 10}}>
        <div style={{ width: '100%' }}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Autocomplete
                multiple
                onChange={handleChange}
                options={campaigns}
                getOptionLabel={(campaign) => campaign.folio + " - " +  campaign.id}
                defaultValue={campaingsSelected}
                renderInput={(params) => (
                  <TextField
                    style={{width: 300}}
                    {...params}
                    variant="standard"
                    label="Campañas"
                  />
                )}
              />
            </Box>
            <Box pr={1}>
              <Button 
                disabled={downloading}
                endIcon={downloading ? <CircularProgress size={20}> hola</CircularProgress> : <GetAppIcon/>} 
                variant="contained" 
                color="primary"
                onClick={downloadZip}
              >
                 {downloading ? 'Descargando - ' + percentage + '%' : 'Descargar'}
              </Button>
            </Box>
            <Box>
              <Button 
                endIcon={<ListIcon/>} 
                variant="contained" 
                color="primary"
                onClick={toReportWeb}
              >
                Reporte web 
              </Button>
            </Box>
          </Box>
        </div>
      </Card>
      <Alert
        open={Boolean(error)}
        onClose={() => setError('')}
        message={error}
        severity="error" 
      />
    </Container>
  )
}

export default Report;