import React from 'react';
import Container from './../components/Container';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    color: '#636161'
  }
}));

//
const NotFoundView = () => {
  const classes = useStyles();
  return (
    <Container press>
      <Typography
        className={classes.title}
        variant="h4" />
    </Container>
  );
};

export default NotFoundView;