import React from 'react';
import { IconButton } from '@material-ui/core';
import { 
  AddCircleOutline as AddIcon,
  Clear as ClearIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  addIcon: {
    color: theme.palette.secondary.main
  }
}));

const InspectButton = ({ inspecting, ...rest }) => {
  const classes = useStyles();
  let icon = <AddIcon className={classes.addIcon}/>;
  if (inspecting) {
    icon = <ClearIcon />;
  }
  return (
    <IconButton
      { ...rest }>
      { icon }
    </IconButton>
  );
}

export default InspectButton;