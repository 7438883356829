import React, { useContext, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import StreamContext from './../../../context/stream/streamContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '0!important',
    height: '0!important'
  }
}));

const MAX_TRIES = 100;

const StreamPlayer = ({ url: streamUrl }) => {
  const classes = useStyles();
  const { setLoadingStream, setStreaming,
    setError, setSource, source } = useContext(StreamContext); 
  const [ tries, setTries ] = useState(0);
  const [ url, setUrl ] = useState(url);

  useEffect(() => {
    setUrl(streamUrl);
  }, [ streamUrl ]);

  const handleError = error => {
    console.error(error);
    if (tries <= MAX_TRIES) {
      setUrl('');
      setTimeout(() => {
        setUrl(streamUrl);
      }, 50);
      setTries(tries => tries + 1);
    } else {
      setTries(0);
      setStreaming(false);
      setLoadingStream(false); 
      setSource(null);
      setError(true);
    }
  }

  const handleStart = () => {
    setStreaming(true);
    setLoadingStream(false);
    setTries(0);
  }

  return (
    <ReactPlayer
      className={classes.root}
      playing
      url={url}
      onStart={handleStart}
      onError={handleError}
      onEnded={handleError}
    />
  );
};

export default StreamPlayer;