import React from 'react';
import MultipleSelector from './MultipleSelector';

const DependenciesSelector = props => {
  const { dependencies, disabled, selectedDependencies, onChange } = props;
  return (
    <MultipleSelector
      items={dependencies}
      name="dependencias"
      disabled={disabled}
      selectedItems={selectedDependencies}
      label={'Dependencia'}
      getOptionLabel={dependency => dependency.nombre}
      getChipLabel={dependency => dependency.nombre}
      onChange={onChange}
      plural={'dependencias'}
      />
  );
};

export default DependenciesSelector;