import React from 'react';
import { Select, FormControl, InputLabel, MenuItem, Grid, TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SelectAudios = ({ audios, onChange, audioId, md, width }) => {
  return (
    <Grid item md={md} xs={9}>
      <Autocomplete
        style={{width: width}}
        value={audios.find(a => a.id === audioId) ? audios.find(a => a.id === audioId) : ""}
        ListboxProps={{ style: { maxHeight: '30rem' } }}
        options={audios.sort((a,b) => a.nombre > b.nombre ? 1 : -1)}
        getOptionLabel={(audio) => audio ? `${audio.nombre} - ${audio.creacion.substring(0,19)} - ${audio.clave}` : ""}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.id: '');
        }}
        renderInput={(params) => <TextField {...params} fullWidth label="Audios" variant="standard" />}
      />
    </Grid>
  )
}

export default SelectAudios;