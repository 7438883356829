import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField, Chip, Grid, Typography, MenuItem, Select, InputLabel, FormControl
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '6px',
    padding: "16px",
    backgroundColor: "#e8e8e8",
    border: '1px solid #d4d4d4'
  },
  noItems: {
    color: "#a79797",
    marginTop: "13px",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '15px 3px 0 3px',
    backgroundColor: '#868686',
    color: '#ffffff',
    '& svg': {
      color: '#c0c0c0',
      transition: 'all 0.2s ease'
    },
    '& svg:hover': {
      color: '#dbdada'
    },
    '&:focus': {
      backgroundColor: '#a4a2a2'
    }
  },
}));

const MultipleSelector = ({ items, selectedItems, label, getChipLabel,
  getOptionLabel, onChange, plural, style, name, disabled }) => {
  const [tipo, setTipo] = useState('');
  
  items = items || [];
  const classes = useStyles();

  const handleChange = item => {
    if (item && item.id) {
      onChange([ ...selectedItems, item.id ]);
    }
  }

  const handleItemDelete = itemId => {
    onChange(selectedItems.filter(i => i !== itemId));
  }

  return (
    <div style={style} className={classes.root}>
      <Autocomplete
        value=''
        name={name}
        onChange={(e, item) => handleChange(item)}
        options={items.filter(item => !selectedItems.includes(item.id))}
        getOptionLabel={item => item? getOptionLabel(item) : 'Agregar'}
        style={{ width: '100%', marginTop: 10 }}
        renderInput={params => (
          <TextField {...params} label={label} fullWidth/>
        )}
        />  
      {
        selectedItems.length?
          items
            .filter(i => selectedItems.includes(i.id))
            .map(item => (
              <Chip
                size="small"
                key={item.id}
                label={getChipLabel && getChipLabel(item) || ''}
                className={classes.chip}
                onDelete={disabled? null : () => handleItemDelete(item.id)}
                />
            ))
            : 
            <Typography
              className={classes.noItems}
              variant="subtitle1">
              No se han agregado { plural ? plural.toLowerCase() : 'elementos' }.
            </Typography>
      }
    </div>
  );
};

export default MultipleSelector;