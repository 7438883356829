export class Request {

  _getQueryString(params) {
    const esc = encodeURIComponent;
    return Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join("&")
  }

  post(url = "", params = {}, headers = {}, opts = {}) {
    return fetch(url,
      {
        body: JSON.stringify({ ...params }),
        cache: 'no-cache',
        headers: {
          'content-type': 'application/json',
          ...headers
        },
        method: 'POST',
        ...opts
      }).then(response => response.json());
  }

  get(url = "", params = {}, headers = {}, opts = {}) {
    const URL = url + '?' + this._getQueryString(params);
    return fetch(URL, {headers: { ...headers }, ...opts})
      .then(response => response.json());
  }
  
  patch(url = "", params = {}, headers = {}, opts = {}) {
    return fetch(url,
      {
        body: JSON.stringify({ ...params }),
        cache: 'no-cache',
        headers: {
          'content-type': 'application/json',
          ...headers
        },
        method: 'PATCH',
        ...opts
      }).then(response => response.json());
  }

  request(url, opts) {
    return fetch(url, opts).then(response => response.json())
  }

  futch(url, opts={}, onProgress) {
    return new Promise( (res, rej)=>{
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || 'get', url);
        for (var k in opts.headers||{})
            xhr.setRequestHeader(k, opts.headers[k]);
        xhr.onload = e => {
          res(JSON.parse(e.target.responseText));
        }
        xhr.onerror = rej;
        if (xhr.upload && onProgress)
            xhr.upload.onprogress = onProgress;
        xhr.send(opts.body);
    });
  }
}