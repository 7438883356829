import React from 'react';
import {
  Grid, TextField
} from '@material-ui/core';

const NameTextField = ({ value, error, disabled, onChange }) => {
  return (
    <Grid item xs={12} sm={4} md={12}>
      <TextField
        fullWidth
        error={error}
        disabled={disabled}
        label="Nombre completo" 
        variant="filled" 
        name="nombre"
        value={value}
        onChange={onChange}/>
    </Grid>
  );
};

export default NameTextField;