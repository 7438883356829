import React, { Component, useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    position: 'relative'
  },
  dragContainer: {
    top: "0",
    left: "0",
    right: "0",
    border: "dashed #4ab8cf 3px",
    bottom: "0",
    zIndex: "9999",
    fontFamily: "roboto",
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  message: {
    position: 'absolute',
    top: '42%',
    right: 0,
    left: 0,
    userSelect: 'none',
    textAlign: 'center',
    color: 'grey',
    fontSize: 36
  }
});

let DragAndDrop = (props) => {

  const [drag, setDrag] = useState(false);
  const dropRef = useRef();

  let dragCounter = 0;

  useEffect(() => {
      
    let div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

  }, [])

  let handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  let handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  }

  let handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDrag(false);
    }
  }

  let handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter = 0;    
    }
  }
  
  return (
    <div
      className={props.classes.root}
      ref={dropRef}>
      { drag &&
        <div
          className={props.classes.dragContainer}
          >
          <div
            className={props.classes.message}
            >
            <div>¡Suelta aquí!</div>
          </div>
        </div>
      }
      { props.children }
    </div>
  );

}

export default withStyles(styles)(DragAndDrop);

// https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929