import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  HighlightOff as HighlightOffIcon 
} from '@material-ui/icons';
import {
  Error as ErrorIcon
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: '#64ba47',//red[500],
    color: 'white',
    padding: theme.spacing(1.4),
    margin: theme.spacing(1, 0),
    fontFamily: 'roboto',
    width: '100%',
    borderRadius: '3px',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    '&.error': {
      backgroundColor: '#f44336'
    }
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1)
    }
  },
  button: {
    cursor: 'pointer',
    opacity: 0.7,
    transition: 'all 0.2s ease',
    '&:hover': {
      opacity: 1,
    }
  }
}));

const Message = ({ icon: Icon, message, onClose, error }) => {
  const classes = useStyles();

  if (typeof Icon === 'boolean') {
    Icon = ErrorIcon;
  }

  return (
    message? 
      <div className={`${classes.error} ${error? 'error' : ''}`}>
        <span className={classes.errorMessage}> 
          { Icon ? <Icon/> : null }
          <label>
            { message }
          </label>
        </span>
        {
          onClose?
            <HighlightOffIcon
              onClick={onClose}
              className={classes.button}
              />
            : null
        }
      </div>
      :
      null
  );
};

export default Message;