import { useState, useEffect } from 'react';
import { isEmpty, isLoaded} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import FourierApi from './../utils/FourierApi';

const useVerifyRole = (role, update) => {
  const auth = useSelector(state => state.firebase.auth);
  const [ allowed, setAllowed ] = useState(null);

  useEffect(() => {
    let unmounted = false;

    if (isLoaded(auth)) {
      if (isEmpty(auth)) {
        if (!unmounted) {
          setAllowed(false);
        }
      }
      else {
        FourierApi
          .getProfile()
          .then(profile => {
            if (!unmounted) {
              setAllowed(profile.rol <= role);
            }
          })
          .catch(() => {
            if (!unmounted) {
              setAllowed(false);
            }
          })
      }
    }
    return () => unmounted = true;
  }, [auth]);

  return allowed; // true => allowed, false => not allowed, null => loading
}

export default useVerifyRole;