import React from 'react';
import {
  Grid, TextField
} from '@material-ui/core';

const KeyTextField = ({ value, error, disabled, onChange }) => {
  return (
    <Grid item xs={12} sm={4} md={12}>
      <TextField
        fullWidth
        disabled={disabled}
        error={error}
        label="Clave" 
        type="password"
        variant="filled" 
        name="clave"
        value={value}
        helperText={error? 'La contraseña debe tener al menos 6 carácteres.' : ''}
        onChange={onChange}/>
    </Grid>
  );
};

export default KeyTextField;