import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableSortLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      cursor: 'pointer'
    },
    userSelect: 'none',
    color: '#818181'
  }
}));

const HeaderCell = ({ name, sortable, sorted, direction, onClick }) => {
  const classes = useStyles();
  if (!name) {
    return <TableCell />;
  }
  if (!sortable) {
    return (
      <TableCell 
        className={classes.root}>
          { name }
      </TableCell>
    );
  }
  return (
    <TableCell 
      className={classes.root}>
        <TableSortLabel 
          active={sorted}
          direction={direction}
          onClick={onClick}>
          { name }
        </TableSortLabel>
    </TableCell>
  );
};

export default HeaderCell;