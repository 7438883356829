import React, { useState } from 'react';
import {
  Menu, MenuItem, ListItemIcon, ListItemText,
  ListItemSecondaryAction, Switch, Divider
} from '@material-ui/core';
import {
  LiveTv as LiveTvIcon,
  Radio as RadioIcon,
  Cloud as CloudIcon,
  CloudUpload as CloudUploadIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Edit as EditIcon,
  Feedback as FeedbackIcon
} from '@material-ui/icons';
import MonitorBitacoraView from './../../monitor/MonitorBitacoraView';
import MonitorStatusReport from './../../monitor/MonitorStatusReport';
import { SOPORTE } from './../../../constants/roles';

const DeviceMenu = ({ role, menuAnchor, open, onClose, stations,device, onOpenStations, onEdit, onToggleUploadState }) => {

  const [ openStatusReport, setOpenStatusReport ] = useState(false);

  return (
    <>
      <Menu
        anchorEl={menuAnchor} // reference to the monitorbox which 'more button' was clicked so the component knows its position
        open={open}
        PaperProps={{style:{minWidth: 270}}}
        anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
        transformOrigin={{ vertical: 'top', horizontal: 'right'}}
        onClose={onClose}>
        {
          <MenuItem 
            onKeyDown={event => event.stopPropagation()}
            onClick={onOpenStations}> {/* // handles the opening of the stations dialog  */}
            <ListItemIcon>
              {
                device.boxType === 'tv'?
                  <LiveTvIcon />
                  :
                  (device.boxType === 'radio'?
                    <RadioIcon />
                    : <CloudIcon />)
              }
            </ListItemIcon>
            <ListItemText>
              {
                device.boxType === 'tv'?
                  'Ver canales'
                  :
                  (device.boxType === 'radio'?
                    'Ver estaciones de radio'
                    : 'Ver radios de internet')
              }
            </ListItemText>
          </MenuItem>
        }
        <MenuItem
          onKeyDown={event => event.stopPropagation()}>
          <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
          <ListItemText>
            <MonitorBitacoraView box={device}></MonitorBitacoraView> 
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => setOpenStatusReport(true)}
          onKeyDown={event => event.stopPropagation()}>
          <ListItemIcon><FeedbackIcon /></ListItemIcon>
          <ListItemText>
            Estado de Monitoreo
          </ListItemText>
        </MenuItem>
        {
          role <= SOPORTE?
            <MenuItem
              onKeyDown={event => event.stopPropagation()}
              onClick={onEdit}>
              <ListItemIcon><EditIcon /></ListItemIcon>
              <ListItemText>Editar caja</ListItemText>
            </MenuItem>
            : null
        }
        <Divider />
        <MenuItem onClick={onToggleUploadState}>
          <ListItemIcon><CloudUploadIcon /></ListItemIcon>
          <ListItemText>Subir audios</ListItemText>
          <ListItemSecondaryAction>
            <Switch
              checked={device.upload}
              onChange={onToggleUploadState}/>
          </ListItemSecondaryAction>
        </MenuItem>
      </Menu>
      <MonitorStatusReport 
        stations={stations}
        open={openStatusReport}
        onClose={() => { setOpenStatusReport(false); onClose && onClose(); }}
        id={device? device.id : null}/>
    </>
  );
};

export default DeviceMenu;