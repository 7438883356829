import React, { useState, useRef, useEffect } from 'react';
import StatusIcon from './StatusIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { TextField } from '@material-ui/core';
import { Typography, Tooltip } from '@material-ui/core';
import { getLastUpdate, getFormattedDate } from '../../../utils/Date';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  primaryText: {
    maxWidth: '250px',
    '& > span': {
      color: '#b2b2b2'
    }
  },
  comment: {
    marginLeft: theme.spacing(2)
  },
  editComment: {
    color: '#b2b2b2',
    fontStyle: 'italic'
  }
}));

const StatusRow = ({ source, status, onChange }) => {

  const classes = useStyles();
  const { calidad, comentario, actualizado } = status;
  const [ editing, setEditing ] = useState(false);
  const [ newComentario, setNewComentario ] = useState(comentario);

  const handleStartEditing = () => {
    setEditing(true);
    setNewComentario(comentario);
  }

  const handleCommentChange = event => {
    setNewComentario(event.target.value);
  }

  const handleCommentSave = () => {
    setEditing(false);
    const newStatus = {
      ...status,
      comentario: newComentario
    };
    onChange && onChange(newStatus);
  }

  const handleQualityChange = quality => {
    const newStatus = {
      ...status,
      calidad: quality
    };
    onChange && onChange(newStatus);
  }

  return (
    <>
      <ListItem>
        <StatusIcon 
          onChange={handleQualityChange}
          status={calidad}/>
        <ListItemText 
          primary={
            <div className={classes.primaryText}>
              {source.nombre} <span> - {source.frecuencia} - {source.siglas}</span>
            </div>
          }
          secondary={actualizado?
            <Tooltip
              title={getFormattedDate(actualizado)}>
              <span>
                { getLastUpdate(actualizado) }
              </span>
            </Tooltip>
            : ''
          } />
        {
          editing ?
            <>
              <TextField 
                value={newComentario}
                name="comentario"
                className={classes.comment}
                style={{ width: '370px' }}
                onChange={handleCommentChange}
                placeholder="comentario"/>
              <Button 
                color="primary"
                style={{ marginLeft: '15px' }}
                variant="contained"
                onClick={handleCommentSave}>
                  Listo
              </Button>
            </>
          :
            <>
              <Typography
                variant="subtitle1"
                className={classes.comment}
                style={{maxWidth: '370px', lineHeight: '18px'}}>
                  { 
                    comentario || <span className={classes.editComment}>Escribe un comentario</span>
                  }
              </Typography>
              <Button 
                color="primary"
                style={{ marginLeft: '15px' }}
                variant="contained"
                onClick={handleStartEditing}
                >Cambiar</Button>
            </>  
        } 
      </ListItem>
      <Divider />
    </>
  );
}

export default StatusRow;