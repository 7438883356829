import React, { useState } from 'react';
import { 
  TV, RADIO, RADIO_INTERNET
} from './../../../constants/boxType';
import {
  Grid, Typography, Checkbox
} from '@material-ui/core';

const BoxtypeFilter = ({ onChange }) => {
	
  const [ checkedTypes, setCheckedTypes ] = useState([ TV, RADIO, RADIO_INTERNET ]);
	
  const handleChange = type => {
		let newCheckedTypes;
		if (checkedTypes.includes(type)) {
			newCheckedTypes = checkedTypes.filter(t => t != type);
		}
		else {
			newCheckedTypes = [ ...checkedTypes, type ];
		}
		onChange && onChange(newCheckedTypes);
		setCheckedTypes(newCheckedTypes);
  }
	
  return (
	<Grid container justify="space-between">
		<Grid item>
			<Typography 
				variant="subtitle1">
				Radio
			</Typography>
			<Checkbox
			  checked={checkedTypes.includes(RADIO)}
			  onChange={() =>  handleChange(RADIO)}
			  />
		</Grid>
		<Grid item>
			<Typography 
				variant="subtitle1">
			  Tv
			</Typography>
			<Checkbox 
			  checked={checkedTypes.includes(TV)}
			  onChange={() => handleChange(TV)}
			  />
		</Grid>
		<Grid item>
			<Typography 
				variant="subtitle1">
			  Radio Internet
			</Typography>
			<Checkbox
			  checked={checkedTypes.includes(RADIO_INTERNET)}
			  onChange={() => handleChange(RADIO_INTERNET)}
			  />
		</Grid>
	</Grid>
  );
}

export default BoxtypeFilter;