import React, { useState } from 'react';
import { Grid, TextField, IconButton, ListItemText, List, ListItem, 
  ListItemSecondaryAction } from '@material-ui/core'; 
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  empty: {
    color: 'gray',
    fontFamily: 'roboto',
    textAlign: 'center',
    padding: theme.spacing(2)
  },
}));

const DatesInput = ({ datetimes=[], onAdded, onDelete }) => {
  const classes = useStyles();
  const [ date, setDate ] = useState('');
  const [ time, setTime ] = useState('');

  const handleAddClick = () => {
    onAdded(date, time);
    setTime('');
  }

  return (
    <Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={date}
            InputLabelProps={{
              shrink: true,
            }}
            label="Fecha"
            onChange={event => setDate(event.target.value)}
            variant="filled"
            type="date"/>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={time}
            label="Hora"
            onChange={event => setTime(event.target.value)}
            variant="filled"
            type="time"/>
        </Grid>  
        <Grid item xs={2}>
          <IconButton onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
        {
          datetimes.length 
          ?
            <div>
              <List dense>
              {
                datetimes.map((datetime, index) => (
                  <ListItem key={ datetime }>
                    <ListItemText> { datetime } </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => onDelete(index)} >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
              </List>
            </div>
          :
            <div className={classes.empty}> Agrega un fecha</div>
        }
    </Grid>
  );
}

export default DatesInput;