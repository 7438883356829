export const TV = 'tv';
export const RADIO = 'radio';
export const RADIO_INTERNET = 'radioInternet';
export const SOURCES_PROP = {
  [TV]: 'channels',
  [RADIO]: 'radios',
  [RADIO_INTERNET]: 'radiosInternet',
};
export const SOURCE_ID_PROP = {
  [TV]: 'channel',
  [RADIO]: 'station',
  [RADIO_INTERNET]: 'id'
};