import React, { Component } from 'react';
import {
  Grid, Card, CardHeader,
  Avatar, Chip, IconButton,
  CardActions, Tooltip,
  List, Dialog, DialogTitle,
  DialogContent
} from '@material-ui/core';
import { filesize } from 'humanize';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SdStorageIcon from '@material-ui/icons/SdStorage';
import ComputerIcon from '@material-ui/icons/Computer';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import MicIcon from '@material-ui/icons/Mic';
import UsbIcon from '@material-ui/icons/Usb';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import DeviceCalibrationForm from './../../devices/DeviceCalibration';

import {
  Cloud as CloudIcon,
} from '@material-ui/icons';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import RadioIcon from '@material-ui/icons/Radio';
import ServiceItem from './ServiceItem';

const theme = {
  success: '#5cb85c',
  successDark: '#4ba74b',
  successLight: '#c9f8db',
  successMid: '#73e78c',
  error: '#e72f28',
  warning: 'gold',
  yellowGreen: '#9ACD32'
};

const styles = {
  root: {
    transition: 'all 0.22s ease'
  },
  id: {
    color: '#aaa',
    fontWeight: 'normal',
    marginLeft: '16px',
    fontSize: '12px'
  },
  boxPaper: {
    padding: '0'
  },
  gone: {
    transform: 'scale(0.75)',
    opacity: 0
  }
}; 

class MonitorBox extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    // console.log('rendering monitor box');
    const { device } = this.props;
    const { monitor } = device;
    const isOn = device.status === 'online';

    if (isOn && monitor.server && !monitor.server.counterErrors) {
      monitor.server.counterErrors = calculateErrors(device);
    }

    const title = (
      device.description ?
        <span>
          {device.description}
          <span className={this.props.classes.id}>
            {device.id}
          </span>
        </span>
        : device.id
    );

    const avatar = (
      <Avatar
        style={{ background: isOn ? theme.success : theme.error }}>
        {
          device.boxType === 'tv' ? <LiveTvIcon />
            : (device.boxType === 'radioInternet' ? <CloudIcon /> 
                : <RadioIcon />)
        }
      </Avatar>
    );

    const action = (
      <span>
        <IconButton
           onClick={this.props.onMore}>
          <MoreVertIcon />  {/* 'more button' */}
        </IconButton>
      </span>
    );

    return (
      <Grid className={`${this.props.classes.root} ${this.props.gone? this.props.classes.gone : ''}`}
        item lg={4} xs={12} sm={6} xl={3}>
        <Card className={this.props.classes.boxPaper}>
          <CardHeader
            title={title}
            subheader={monitor && moment(monitor.update).fromNow()}
            avatar={avatar}
            action={action}
            />
          {
          /* isOn */
            true ? 
            (
              <List>
                <ServiceItem
                  name="Grabación"
                  icon={MicIcon}
                  info={monitor.server}
              //   onClick={props.onRecorderClick}
                  badge={monitor.server? monitor.server.counterErrors : null}
                  />
                <ServiceItem
                  name="Subida"
                  icon={CloudUploadIcon}
                  info={monitor.uploader}
                  />
                <ServiceItem
                  name="Búsqueda"
                  icon={FingerprintIcon}
                  info={monitor.ondemand}
                  badge={monitor.ondemand? monitor.ondemand.queueSize : null}
                  />
              </List>
            )
            : null
          }

          <CardActions>
            {
              device.rtunnel_port && device.rtunnel_port !== 'no' ?
                <TunnelPortChip box={device} /> 
                : null 
            }
            { 
              device.boxType !== 'tv' && device.boxType !== 'radioInternet' ?
                <ReceiversChip monitor={monitor}/>
                : null
            }
            {
              monitor.disk && monitor.disk.used ?
                <DiskChip monitor={monitor} />
                : null
            }
            <VersionChip monitor={monitor}/>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  shouldComponentUpdate(nextProps) {
    const { device } = this.props;
    const { device: nextDevice } = nextProps;
    return device.description !== nextDevice.description
        || this.props.gone !== nextProps.gone
        || device.monitor && nextDevice.monitor
          && device.monitor.update !== nextDevice.monitor.update
        || device.upload != nextDevice.upload
        // || JSON.stringify(device.calibrations) !== JSON.stringify(nextDevice.calibrations);
  }
}

// algorithm to calculate the quantity of errors in a device
const calculateErrors = device => {
  let sources;
  if (device.boxType === 'tv') {
    sources = device.channels || [];
  } 
  else if (device.boxType === 'radioInternet') {
    sources = device.radiosInternet || [];
  } 
  else {
    sources = device.stations || [];
  }
  return sources.reduce((a, b) =>
    a + (b.status !== 'ok'
      && b.status !== 'idle'
      && b.status !== 'record' ? 1 : 0)
  , 0);
}

const TunnelPortChip = ({ box }) => {
  const { rtunnel_port, tunnelStatus } = box;
  return (
    <Chip
      label={rtunnel_port}
      style={{ background: '#fafafa' }}
      avatar={
        <Avatar
          style={{
            background: '#fafafa',
            color: tunnelStatus === 'online' ? theme.success : theme.error
          }}>
          <SettingsEthernetIcon />
        </Avatar>
      }
    /> 
  );
}

const ReceiversChip = ({ monitor }) => {
  return (
    <Chip
      label={monitor.receiversAvailable !== undefined? monitor.receiversAvailable : '?'}
      style={{ background: '#fafafa' }}
      avatar={
        <Avatar style={{ background: '#fafafa' }}>
          <UsbIcon />
        </Avatar>
      }
      />
  );
}

const DiskChip = ({ monitor }) => {
  const storageWarning = monitor.disk && monitor.disk.used 
          && monitor.disk.used * 100 / monitor.disk.total > 80;
  return (
    <Chip 
      label={`${filesize(monitor.disk.used, 1024, 1)} / ${filesize(monitor.disk.total, 1024, 1)}`} 
      style={{ background: '#fafafa' }}
      avatar={
        <Avatar
          style={{
            background: storageWarning ? theme.error : '#fafafa',
            color: storageWarning ? '#ffffff' : null
          }}
        >
          <SdStorageIcon />
        </Avatar>
      }
    /> 
  );
}

const VersionChip = ({ monitor }) => {
  return (
    <Tooltip
      title="Versión de monitor">
      <Chip
        label={monitor.monitor.version}
        avatar={
          <Avatar style={{background: '#fafafa'}}>
            <ComputerIcon />
          </Avatar>
        }
        style={{ background: '#fafafa' }}
      />
    </Tooltip>
  );
}

export default withStyles(styles)(MonitorBox);
