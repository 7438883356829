import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { STATUS, STATUS_COLORS } from './Status';

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'block',
    width: '17px',
    height: '17px',
    borderRadius: '100%',
    margin: '0 30px 0 0',
    cursor: 'pointer',
    transition: 'all 0.1s ease',
    '&:hover':{
      transform: 'scale(1.1)',
    },
    '&.clicked': {
      transform: 'scale(1.5)',
    }
  }
}));

const nextStatus = (status) => {
  switch (status) {
    case STATUS.great:
      return STATUS.obsolete;
    case STATUS.ok:
      return STATUS.great;
    case STATUS.bad:
      return STATUS.ok;
    case STATUS.obsolete:
      return STATUS.bad;
  }
}

const StatusIcon = ({ status: initStatus, onChange }) => {
  const classes = useStyles();
  const [ status, setStatus ] = useState(initStatus || STATUS.great);
  const [ clicked, setClicked ] = useState(false);
  const color = STATUS_COLORS[status];

  const changeStatus = () => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
      onChange(nextStatus(status)); // listener 
      setStatus(nextStatus(status));
    }, 100);
  }

  return (
    <span
      className={`${classes.icon} ${clicked? 'clicked' : ''}`}
      style={{ backgroundColor: color }}
      onClick={() => changeStatus()}
    />
)}

export default StatusIcon;