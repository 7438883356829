import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'block',
    fontSize: '25px',
    padding: '10px 23px',
    margin: '10px',
    color: '#ffffff',
    userSelect: 'none',
    textAlign: 'center',
    fontFamily: 'roboto',
    backgroundColor: '#ececec'
  }
}));

const SourceLoader = () => {
  const classes = useStyles();
  const [ dots, setDots ] = useState(1);
  useEffect(() => {
    let isMounted = true; 

    const id = setInterval(() => {
      if(isMounted) {
        setDots(dots => dots === 5? 1 : dots + 1)
      }
    }, 150);
    return () => {
      isMounted = false;
      clearInterval(id);
    }
  }, []);

  let dotsStr = '';
  for (let i = 0; i < dots; i++) {
    dotsStr += '· ';
  }
  return (
    <span className={classes.loader}>
      { dotsStr }
    </span>
  )
}

export default SourceLoader;