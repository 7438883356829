import React from 'react'
import { Grid, Checkbox, Typography, Box} from '@material-ui/core'

const Checkboxes = ({onChange, checkboxes}) => {
  return (
    <Grid container>
      <Grid item md={4} xs={4}>
        <div style={{padding: 10}}>
          <Box display="flex" justifyContent="center">
            <Checkbox
              checked={checkboxes[0]}
              onChange={event => {onChange(0, event.target.checked)}}
              color="primary"
            />
          </Box>
          <Typography align="center">Radio</Typography>
        </div>
      </Grid>
      <Grid item md={4} xs={4}>
        <div style={{padding: 10}}>
          <Box display="flex" justifyContent="center">
            <Checkbox
              checked={checkboxes[1]}
              onChange={event => {onChange(1, event.target.checked)}}
              color="primary"
            />
          </Box>
          <Typography align="center"> TV</Typography>
        </div>
      </Grid>
      <Grid item md={4} xs={4}>
        <div style={{padding: 10}}>
          <Box display="flex" justifyContent="center">
            <Checkbox
              checked={checkboxes[2]}
              onChange={event => {onChange(2, event.target.checked)}}
              color="primary"
            />
          </Box>
          <Typography align="center">Radio internet</Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export default Checkboxes
