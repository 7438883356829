import React from 'react';
import Paper from '@material-ui/core/Paper';
import { TextField, InputAdornment } from "@material-ui/core";
import { Search as SearchIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '0',
    margin: '0 0 20px 0'
  }
}));

const Searchbox = ({ onChange, onSelect, placeholder,
   className, options, getOptionLabel, style }) => {

  const classes = useStyles();

  const textFieldProps = {
    variant: "outlined",
    fullWidth: true,
    placeholder,
    onChange,
  }

  return (
    <Paper
      className={`${className} ${classes.paper} paper`}
      style={style}>
      {
        options?
          <Autocomplete
            onChange={(e, item) => onSelect(item)}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={params => (
              <TextField
                {...params}
                {...textFieldProps}
                />
            )}
            />
          : <TextField {...textFieldProps} />
      }
    </Paper>
  );

  const Input = ({ params }) => (
    <Paper className={`${className} paper`} style={{ padding: 0, margin: '0 0 20px 0', ...style }}>
      <TextField
        {...params} 
        variant="outlined"
        fullWidth
        type="search" 
        placeholder={placeholder}
        onChange={onChange}
        />
      </Paper>
  );

  return <Input />;

  if (options) {
    return (
      <Autocomplete
        onChange={(e, item) => onSelect(item)}
        options={options}
        getOptionLabel={item => String(item)}
        renderInput={params => <Input params={params} />}
        /> 
    );
  }

  return <Input />;
};

export default Searchbox;