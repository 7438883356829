import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, Grid,
  TextField 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from './../../components/Container';
import Loader from './../../components/Loader';
import Message from '../../components/Message';
import FourierApi from './../../utils/FourierApi';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleInput from './../../components/MultipleInput';

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(2,4)
  },
  form: {
    padding: theme.spacing(4, 10),
    borderTop: '1px solid #898989'
  },
  input: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2)
    }
  },
  button: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
  }
}))

const StationView = ({ match }) => {
  const classes = useStyles();
  const [ locationState, setLocationState ] = useState('');
  const [ states, setStates ] = useState([]);
  const [ name, setName ] = useState('');
  const [ error, setError ] = useState(null);
  const [ code, setCode ] = useState('');
  const [ acronym, setAcronym ] = useState('');
  const [ frequency, setFrequency ] = useState(0);
  const [ stationLoaded, setStationLoaded ] = useState(false);
  const [ statesLoaded, setStatesLoaded ] = useState(false);
  const [ names, setNames ] = useState(null);

  useEffect(() => {
    let isMounted = true; 

    FourierApi
      .getStations({
        id: match.params.id
      })
      .then(result => {
        if(isMounted) {
          if (result && result.length) {
            const station = result[0];
            setLocationState(station.estado? { id: station.estado } : '');
            setCode(station.clave || '');
            setName(station.nombre || '');
            setAcronym(station.siglas || '');
            setFrequency(station.frecuencia || '');
            setNames(station.siglasAlternas || []);
            setStationLoaded(true);
          }
          else {
            setError('La estación no fue encontrada.');
          }
        }
      });
    FourierApi
      .getStates()
      .then(result => {
        if(isMounted) {
          setStates(result);
          setStatesLoaded(true);
        }
      });
      
    return () => isMounted = false;
  }, []);

  if (!stationLoaded || !statesLoaded) {
    return <Loader />;
  }

  return (
    <Container press>
      <Paper>
        <Typography className={classes.title} variant="h4" color="primary">
          { acronym || code }
        </Typography>
        <form 
          className={classes.form}>
          <Message error message={error} onClose={() => setError(null)}/>
          <Grid
            container
            justify="space-between"
            spacing={2}>
            <Grid xs={12} md={6} item>
              <Grid container spacing={2}>
                <Grid container item xs={12} sm={6} alignItems="center">
                  <TextField
                    className={classes.input} 
                    label="Nombre" 
                    fullWidth
                    disabled
                    variant="filled" 
                    value={name}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disabled
                    value={locationState}
                    options={states}
                    getOptionLabel={state => state.id || 'Ninguno'}
                    style={{ width: '100%' }}
                    renderInput={params => (
                      <TextField {...params} label="Estado" variant="filled" fullWidth/>
                    )}
                    />  
                </Grid>
                <Grid container item xs={12} sm={6} alignItems="center">
                  <TextField
                    className={classes.input} 
                    label="Clave" 
                    fullWidth
                    disabled
                    variant="filled" 
                    value={code}/>
                </Grid>
                <Grid container item xs={12} sm={6} alignItems="center">
                  <TextField
                    className={classes.input} 
                    label="Siglas" 
                    fullWidth
                    disabled
                    variant="filled" 
                    value={acronym}/>
                </Grid>
                <Grid container item xs={12} sm={6} alignItems="center">
                  <TextField
                    className={classes.input} 
                    type="number"
                    label="Frecuencia" 
                    fullWidth
                    disabled
                    variant="filled" 
                    value={frequency}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>  
              <MultipleInput
                title="Siglas alternas"
                disabled
                inputs={names}/>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default StationView;