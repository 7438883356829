import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#ffffff',
    position: 'absolute',
    zIndex: '1',
    right: 0,
    top: '15px',
  }
}));

const AbsoluteFab = (props) => {
  const classes = useStyles();
  let fabProps = {...props};
  delete fabProps.title;
  return (
    <Tooltip
      placement="top"
      title={props.title}>
      <Fab disabled={props.disabled} className={classes.root} {...fabProps}>
        { props.children }
      </Fab>
    </Tooltip>
  );
};

export default AbsoluteFab;