import React, {useContext } from 'react';
import { Grid, List } from '@material-ui/core';
import ListHeader from './ListHeader';
import AudioItem from './AudioItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  list: {
    maxHeight: '320px',
    overflow: 'auto',
    paddingTop: '0'
  }
}));

const AudiosList = ({ guideline, files, found, ad, playingIndex, 
  playing, onPlayAudio, onCheckClicked, onHitDelete }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <List className={classes.list}>
        <ListHeader />
        {
          files.map((file, index) => {
            let hitsInAudio = [];
            for (let i in found) {
              if (found[i] === file.timestamp) {
                hitsInAudio.push(i); 
              }
            }
            const isSelected = parseInt(index, 10) === parseInt(playingIndex, 10);
            const isPlaying = isSelected && playing;
            return (
              <AudioItem 
                key={file.timestamp}
                file={file}
                hits={hitsInAudio}
                guideline={guideline}
                timezone={ad.timezone}
                selected={isSelected}
                playing={isPlaying}
                onHitDelete={timestamp => {
                  onHitDelete && onHitDelete(timestamp);
                }}
                onClickPlay={event => {
                  event.stopPropagation();
                  onPlayAudio && onPlayAudio(index);
                }}
                onCheckClicked={startPlaying => {
                  onCheckClicked && onCheckClicked(startPlaying, index);
                }}
              />
            );
          }) 
        }
      </List>
    </Grid>
  );  
}

export default AudiosList;