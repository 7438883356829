export const STATUS = {
  great: 'great',
  ok: 'ok',
  bad: 'bad',
  obsolete: 'obsolete'
};
  
export const STATUS_COLORS = {
  [STATUS.obsolete]: '#cc0707',
  [STATUS.bad]: '#f8871e',
  [STATUS.ok]: '#54cbf3',
  [STATUS.great]: '#8dd311'
};
  