import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import './range.css';
import firebase from '../../../firebase';
import FourierApi from '../../../utils/FourierApi';
import Button from '@material-ui/core/Button';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import FastForwardIcon from '@material-ui/icons/FastForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReactPlayer from 'react-player';
import AudiosList from './AudiosList';
import InspectButton from './InspectButton';
import DeleteDialog from './DeleteDialog';
import MenuButton from './MenuButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    background: '#fafafa'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    width: '350px'
  },
  playButton: {
    '& svg': {
      cursor: 'default'
    }
  },
  iconItem: {
    marginRight: theme.spacing(1),
    minWidth: '0!important'
  },
  fastForward: {
    '&.inactive': {
      color: '#bcbcbc'
    }
  },
  slider: {
    margin: '0!important',
    '&::-webkit-slider-runnable-track': {
      transition: 'all 0.2s ease'
    },
    '&::slider-runnable-track': {
      transition: 'all 0.2s ease'
    },
    '&.inspect': {
      '&::-webkit-slider-runnable-track': {
        backgroundColor: `${theme.palette.secondary.main}!important`
      },
      '&::slider-runnable-track': {
        backgroundColor: `${theme.palette.secondary.main}!important`
      }
    }
  },
  timer: {
    fontFamily: 'roboto',
    fontSize: '15px',
    '&.inspect': {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark
    },
  },
  addHitButton: {
    marginBottom: theme.spacing(2)
  },
  status: {
    margin: '0',
    fontFamily: 'roboto',
    '& > p': {
      display: 'inline-block'
    }
  },
  redo: {
    marginRight: theme.spacing(1)
  }
}));

let audioPlayer = null;

const DetailCard = (props) => {
  const classes = useStyles();
  const [ hitToDelete, setHitToDelete ] = useState(null);
  const [ inspecting, setInspecting ] = useState(false);
  const [ status, setStatus ] = useState(null);
  const [ found, setFound ] = useState({});
  const [ url, setUrl ] = useState(null);
  const [ playing, setPlaying ] = useState(true);
  const [ volume, setVolume ] = useState(0.8);
  const [ muted, setMuted ] = useState(false);
  const [ played, setPlayed ] = useState(0);
  const [ duration, setDuration ] = useState(0);
  const [ playbackRate, setPlaybackRate ] = useState(1.0);
  const [ loop, setLoop ] = useState(false);
  let [ playedSeconds, setPlayedSeconds ] = useState(-1);
  const [ files, setFiles ] = useState([]);
  let [ playingIndex, setPlayingIndex ] = useState(0);
  const [ forceDownload, setForceDownload ] = useState(false);
  const [ startPlaying, setStartPlaying ] = useState(null);
  const [ expandPlayList, setExpandPlayList ] = useState(false);
  const [ isAdmin, setIsAdmin ] = useState(false);
  const [ seeking, setSeeking ] = useState(false);
  const [ audioId, setAudioId] = useState('');

  useEffect(() => {
    if(props.audioVersiones) {
      setAudioId(props.audioVersiones[0].id)
    }
  }, [props.audioVersiones])

  useEffect(() => {
    let isMounted = true;

    const componentDidMount = () => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          firebase.database().ref()
            .child('users')
            .child(user.uid)
            .once('value')
            .then(snap => {
              let value = snap.val();
              if(isMounted && value !== null) {
                setIsAdmin(true);
              }
            });
        }
      });
  
      if (!props.ad)
        return;
  
      const { ad } = props;
      let date = moment.unix(ad.date).tz(ad.timezone);
      if (date === undefined || (date !== undefined && !date.isValid))
        return;
  
      FourierApi
        .getFiles({
          ciudad: ad.city,
          estacion: ad.station,
          fecha: date.format('YYYY-MM-DD HH:mm:ss')
        })
        .then(result => {
          if(isMounted) {
            if (result.length === 0 || props.ad.eventId === 36374) {
              props.onNoAudios();
            }
            const response = result.map(file => ({
              filename: file.archivo,
              timestamp: +moment(file.registro) / 1000
            })); 
            let status = "Anuncio no encontrado";
            let startPlaying = 0;
            let files = [];
            let index = 0;
            for (let i in response) {
              files.push({
                timestamp: response[i]['timestamp'],
                filename: response[i]['filename']
              });
            }
            files.sort((a, b) => { return a.timestamp - b.timestamp });
            let aux = {};
            for (let f in files) {
              let _file = files[f];
              for (let i = 0; i < ad.found.length; i++) {
                const foundTimestamp = ad.found[i];
                if (foundTimestamp !== null ) {
                  const val = foundTimestamp - _file.timestamp;
                  if (val >= 0 && val < 300) {
                    if (aux[foundTimestamp] === undefined) {
                      aux[foundTimestamp] = { val: val, timestamp: _file.timestamp };
                      index = f;
                    } else if (aux[foundTimestamp]["val"] > val) {
                      aux[foundTimestamp] = { val: val, timestamp: _file.timestamp };
                      index = f;
                    }
                  }
                }
              }
            }
            let found = {};
            for (let i in aux) {
              status = "Anuncio encontrado";
              const resultDate = moment.unix(i).tz(ad.timezone);
              const _date = moment.unix(aux[i].timestamp).tz(ad.timezone);
              startPlaying = (resultDate.diff(_date) / 1000);
              found[i] = moment.unix(aux[i].timestamp).tz(ad.timezone).unix();
            }
            setStartPlaying(startPlaying);
            setPlayed(startPlaying);
            setStatus(status);
            setFiles(files);
            setPlayingIndex(index);
            setForceDownload(true);
            setFound(found);
          }
        });
    }
    componentDidMount();

    return () => isMounted = false;
  }, []);

  useEffect(() => {
    const componentDidUpdate = () => {
      if (forceDownload && files[playingIndex] !== undefined) {
        const { ad } = props;
        let filename = ad.city + '/' + ad.station + '/' + files[playingIndex].filename;
        firebase.storage().ref(filename)
          .getDownloadURL()
          .then(url => { 
            setUrl(url);
            setForceDownload(false);
          })
          .catch(_handleGetFilesError);
      }
    }  
    componentDidUpdate();
  }, [ forceDownload, playingIndex ]);

  const playAudio = index => {
    setPlayingIndex(index);
    setForceDownload(true);
    setPlaying(true);
  }
  
  const _handleGetFilesError = error => {
    const status = '';
    switch (error.code) {
      case 'storage/object_not_found':
        status = "Archivo no se encuentra en Storage";
        break;
      case 'storage/unauthorized':
        status = "No tiene permiso para acceder al archivo";
        break;
      case 'storage/canceled':
        status = "Subida del archivo cancelada";
        break;
      case 'storage/unknown':
      default:
        status = "Ocurrió un error al obtener el archivo";
        break;
    }
    setStatus(status);
  }
  
  const _download = () => {
    let _files = [];
    let _found = false;
    for (let i in found) {
      for (let j in files) {
        if (found[i] === files[j].timestamp) {
          _found = true;
          _files.push(files[j].filename);
        }
      }
    }
    if (!_found) {
      for (let j in files) {
        _files.push(files[j].filename);
      }
    }
    const { ad } = props;
    let prefix = ad.city + '/' + ad.station + '/';
    for (let f in _files) {
      firebase.storage().ref(prefix + _files[f])
        .getDownloadURL()
        .then(url => {
          window.open(url);
        })
        .catch(_handleGetFilesError);
    }
  }
    
  const handleAudioPlay = index => {
    if (playing) {
      if (playingIndex === index) {
        onPause();
      }
      else {
        playAudio(index);
      }
    }
    else {
      playAudio(index);
    }
  }

  const onPlay = () => {
    setPlaying(true);
  }

  const onPause = () => {
    setPlaying(false);
  }
  
  const onEnded = () => {
    if (playingIndex < files.length - 1) {
      playingIndex++;
    } else {
      playingIndex = 0;
    }
    const _playbackRate = playbackRate;
    setPlayed(0);
    setPlayingIndex(playingIndex);
    setForceDownload(true);
    setPlaying(true);
    setPlaybackRate(1.0);
    setTimeout(() => {
      setPlaybackRate(_playbackRate);
    }, 1000);
  }
  
  const onProgress = state => {
    const { played, playedSeconds } = state;
    setPlayed(played); 
    setPlayedSeconds(playedSeconds);
  }

  const onDuration = duration => {
    setDuration(duration);
  }
  
  const onSeekMouseDown = e => {
    setSeeking(true);
  }
  
  const onSeekChange = e => {
    setPlayed(parseFloat(e.target.value));
    setPlayedSeconds(e.target.value * duration);
  }
  
  const onSeekMouseUp = e => {
    setSeeking(false);
    audioPlayer.seekTo(parseFloat(e.target.value))
  }
  
  const onPlaybackRateDown = () => {
    setPlaybackRate(2.0);
  }

  const onPlaybackRateUp = () => {
    setPlaybackRate(1.0);
  }

  const onSkipPrevious = () => {
    if (playingIndex > 0) {
      playingIndex--;
    } else {
      playingIndex = files.length - 1;
    }
    setPlayed(0);
    setPlayingIndex(playingIndex);
    setForceDownload(true);
  }

  const onSkipNext = () => {
    if (playingIndex < files.length - 1) {
      playingIndex++;
    } else {
      playingIndex = 0;
    }
    setPlayed(0);
    setPlayingIndex(playingIndex);
    setForceDownload(true);
  }

  const addSelectedHit = () => {
    let tp;

    if(files.length) {
      const { timestamp } = files[playingIndex];
      tp = timestamp;
    }
    else {
      tp = props.ad.date
    }

    const foundTimestamp = parseInt(tp + playedSeconds);
    
    addHit(tp, foundTimestamp)
      .then(() => {
        setInspecting(false);
      });
  }

  const addHit = (timestamp, foundTimestamp) => {
    const indexAudioVersiones = props.audioVersiones.map(version => (version.id)).indexOf(audioId);
    const esVersion = indexAudioVersiones === 0 ? false : true;

    return FourierApi
      .addCampaignHit({
        eventId: props.ad.eventId,
        timestamp: foundTimestamp,
        audioId: audioId,
        esVersion: esVersion
      })
      .then(() => {
        const newFound = {
          ...found,
          [foundTimestamp]: timestamp
        };
        props.ad.found.push(foundTimestamp);
        const { onHitsChange } = props;
        onHitsChange && onHitsChange(props.ad.found);
        setFound(newFound);

        FourierApi .getCampaignGuideline({
          id: props.guideline.id,
          expand: 'eventos,ciudad'
        })
        .then(guideline => {
          if (guideline) {
            props.setGuideline(guideline);
          }
        });
      });
  }

  const handleHitDelete = timestamp => {
    setHitToDelete(timestamp);
  }

  const deleteHit = () => {
    const timestamp = parseInt(hitToDelete);
    setHitToDelete(null);
    FourierApi
      .removeCampaignHit({
        eventId: props.ad.eventId,
        timestamp
      })
      .then(() => {
        let newFound = { ...found };
        delete newFound[timestamp];
        setFound(newFound);
        props.ad.found = props.ad.found.filter(t => t !== timestamp);
        const { onHitsChange } = props;
        onHitsChange && onHitsChange(props.ad.found);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleFastForwardClick = () => {
    if (playbackRate === 1.0) {
      onPlaybackRateDown();
    }
    else {
      onPlaybackRateUp();
    }
  }

  const handleRedoSearch = () => {
    FourierApi
      .redoEventSearch({ id: props.ad.eventId })
      .then(() => {
        const { onStatusChange } = props;
        onStatusChange && onStatusChange('pending');
      });
  }

  const handleSetAsDefaultFound = () => {
    const { date } = props.ad;
    FourierApi
      .addCampaignHit({
        eventId: props.ad.eventId,
        timestamp: date
      })
      .then(() => {
        const { onHitsChange, onClose } = props;
        onHitsChange && onHitsChange([ ...props.ad.found, date ]);
        onClose && onClose();
      });
  }

  const handleSetAsNotFound = () => {
    FourierApi
      .addCampaignHit({
        eventId: props.ad.eventId,
        fecha: null
      })
      .then(() => {
        const { onHitsChange, onClose } = props;
        onHitsChange && onHitsChange([]);
        onClose && onClose();
      });
  }

  const handleSetIncidentReport = () =>{
    FourierApi
    .addIncidentReport({
      eventId: props.ad.eventId
    })
    .then(() => {
      const { onStatusChange } = props;
      onStatusChange && onStatusChange('incident');
    });
  }

  if (typeof props.ad === "undefined") {
    return null;
  }
  let d = moment.unix(props.ad.fid).tz(props.ad.timezone);
  let minPlayed = "00", secPlayed = "00";
  let padding = "00";
  if (seeking) {
    playedSeconds = played * duration;
  }
  if (playedSeconds > 0) {
    minPlayed = parseInt(playedSeconds / 60, 10);
    if (playingIndex > 0) {
      let minutes = 0;
      if (files[playingIndex] !== undefined) {
        minutes = moment.unix(files[playingIndex].timestamp).minutes();
      }
      minPlayed = minPlayed + minutes;
    }
    minPlayed = minPlayed + "";
    minPlayed = padding.substring(0, 2 - minPlayed.length) + minPlayed;
    secPlayed = parseInt(playedSeconds % 60, 10) + "";
    secPlayed = padding.substring(0, 2 - secPlayed.length) + secPlayed;
  }
  return (
    <div style={{ position: 'fixed', bottom: 60, right: 10, zIndex: 1 }}>
      <Card elevation={4} className={classes.card}>
        <div className={classes.details}>
          <CardContent
            className={classes.content}>
            <Typography type="body1">
              {d !== undefined && d.isValid ? d.format('dddd, D [de] MMMM, h:mm A') : ""}
            </Typography>
            <div type="subheading" color="secondary">
              {status !== null && (
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  className={classes.status}
                  type="subheading"
                  color="secondary">
                  <p>{ status }</p>
                  <IconButton
                    color="default"
                    aria-label="Play"
                    onClick={_download}>
                    <CloudDownloadIcon />
                  </IconButton>
                </Grid>
              )}
            </div>
            {
              url !== null &&
              <>
                <ReactPlayer
                  ref={element => {
                      setTimeout(() => {
                        audioPlayer = element;
                        if (audioPlayer !== null && startPlaying !== null && duration > 0) {
                          audioPlayer.seekTo(parseFloat(startPlaying / element.getDuration()));
                          setStartPlaying(null);
                        }
                      }, 100);
                    }}
                  width='100%'
                  height='0'
                  url={url}
                  playing={playing}
                  loop={loop}
                  playbackRate={playbackRate}
                  volume={volume}
                  muted={muted}
                  onPlay={onPlay}
                  onPause={onPause}
                  onEnded={onEnded}
                  onProgress={onProgress}
                  onDuration={onDuration}
                />
                <input
                  className={`${classes.slider} ${ inspecting? 'inspect' : '' }`}
                  type='range' 
                  min={0} 
                  max={1} 
                  step='any'
                  value={played}
                  onMouseDown={onSeekMouseDown}
                  onChange={onSeekChange}
                  onMouseUp={onSeekMouseUp}
                />
              </>
            }

            
            <Grid container justify="space-between" alignItems="center">
            {
              url !== null && <>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3}>
                      <IconButton
                        color="default"
                        aria-label="Back"
                        onClick={onSkipPrevious}>
                        <SkipPreviousIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                      {!playing && 
                        <IconButton
                          color="default"
                          aria-label="Play"
                          onClick={onPlay}
                          >
                          <PlayArrowIcon />
                      </IconButton>}
                      {playing &&
                        <IconButton
                          color="default"
                          aria-label="Play"
                          onClick={onPause}
                          >
                        <PauseIcon />
                      </IconButton>}
                      </Grid>
                    <Grid item xs={3}>
                        <IconButton
                          color="default"
                          aria-label="fast"
                          className={`${classes.fastForward} ${ playbackRate === 1.0? 'inactive' : '' }`}
                          onClick={handleFastForwardClick}>
                          <FastForwardIcon />
                        </IconButton>
                      </Grid>
                    <Grid item xs={3}>
                        <IconButton
                          color="default"
                          aria-label="Next"
                          onClick={onSkipNext} >
                          <SkipNextIcon />
                        </IconButton>
                      </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container justify="flex-end">
                    <span className={`${classes.timer} ${ inspecting? 'inspect' : ''}`}>
                      { playedSeconds > 0 ? (minPlayed + ":" + secPlayed) : null}
                    </span>
                  </Grid>
                </Grid>
              </>
            }
              <Grid item xs={2}>
                <InspectButton
                  inspecting={inspecting}
                  onClick={() => setInspecting(inspecting => !inspecting)}
                />
              </Grid>
            </Grid>
              <Grid container>
              {
                inspecting 
                ?
                  <FormControl style={{marginBottom: 10}} fullWidth variant="filled">
                    <InputLabel>Versiones de audio</InputLabel>
                    <Select
                      value={audioId}
                      onChange={event => setAudioId(event.target.value)}
                    >
                    {
                      props.audioVersiones && props.audioVersiones.length && props.audioVersiones.map(version => (
                        <MenuItem key={version.id} value={version.id}>
                          { `${version.nombre} - ${version.programacion}` }
                        </MenuItem>
                      ))
                    }
                    </Select>
                  </FormControl>
                : 
                  null 
              }
              </Grid>
              <Grid container>
              {
                inspecting?
                <Button
                  fullWidth
                  className={classes.addHitButton}
                  color="secondary"
                  variant="contained"
                  onClick={addSelectedHit}>
                  Agregar encontrado
                </Button>
                : null 
              }
              </Grid>
              <Grid container justify="center">
              { 
                expandPlayList?
                  <AudiosList
                    guideline={props.guideline}
                    files={files}
                    found={found}
                    ad={props.ad}
                    playingIndex={playingIndex}
                    playing={playing}
                    onPlayAudio={index => {
                      handleAudioPlay(index);
                    }}
                    onHitDelete={timestamp => {
                      handleHitDelete(timestamp);
                    }}
                    onCheckClicked={(startPlaying, index) => {
                      const isSelected = parseInt(index, 10) === parseInt(playingIndex, 10);
                      setPlaying(true);
                      setPlayingIndex(index);
                      setForceDownload(true);
                      setStartPlaying(startPlaying);
                      setPlayed(startPlaying);
                      setDuration(isSelected ? duration : 0);
                    }}
                  />
                  : null
              }
              <Grid item xs={12} style={{margin: 0, padding: 0}}>
                <Button
                  fullWidth
                  color="default"
                  onClick={() => {
                    setExpandPlayList(expandPlayList => !expandPlayList);
                  }}>
                  { expandPlayList ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
                </Button>
              </Grid>
              </Grid>


          </CardContent>
        </div>
        <div style={{position: 'absolute', right: 0, top: 26}}>
          <MenuButton
            status={props.ad.status}
            onRedoSearch={handleRedoSearch}
            onSetAsFound={handleSetAsDefaultFound}
            onSetAsNotFound={handleSetAsNotFound}
            onSetIncidentReport={handleSetIncidentReport}
            />
        </div>
        <div style={{position: 'absolute', right: 0, top: 0}}>
          <IconButton
            size="small"
            color="default"
            aria-label="Close"
            onClick={props.onClose} >
            <ClearIcon />
          </IconButton>
        </div>
      </Card>
      <DeleteDialog 
        open={!!hitToDelete}
        onAccept={() => deleteHit()}
        onCancel={() => setHitToDelete(null)}
        onClose={() => setHitToDelete(null)}
        />
    </div>
  );

}

export default DetailCard;