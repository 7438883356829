import React, { useReducer } from 'react';
import streamContext from './streamContext';
import streamReducer from './streamReducer';
import { 
  SET_BOXTYPE,
  SET_SOURCE,
  SET_CITY,
  SET_SOURCE_INDEX_PLAYING,
  SET_STREAMING,
  SET_LOADING_STREAM,
  SET_ERROR
} from '../../types';

const StreamState = ({ children }) => {
  const initialState = {
    boxType: 'all',
    city: null,
    stationsIds: [],
    source: null,
    indexPlaying: null,
    loading: false,
    streaming: false,
    error: false
  }

  const [ state, dispatch ] = useReducer(streamReducer, initialState);

  const setBoxType = boxType => {
    dispatch({
      type: SET_BOXTYPE,
      payload: boxType
    });
  }
  
  const setCity = city => {
    dispatch({
      type: SET_CITY,
      payload: city
    });
  }

  const setSource = source => {
    dispatch({
      type: SET_SOURCE,
      payload: source
    });
  }

  const setSourceIndexPlaying = id => {
    dispatch({
      type: SET_SOURCE_INDEX_PLAYING,
      payload: id
    });
  }

  const setStreaming = streaming => {
    dispatch({
      type: SET_STREAMING,
      payload: streaming
    });
  }

  const setLoadingStream = loading => {
    dispatch({
      type: SET_LOADING_STREAM,
      payload: loading
    });
  }

  const setError = error => {
    dispatch({
      type: SET_ERROR,
      payload: error
    });
  }

  return (
    <streamContext.Provider
      value={{
        boxType: state.boxType,
        city: state.city,
        source: state.source,
        stationsIds: state.stationsIds,
        indexPlaying: state.indexPlaying,
        loading: state.loading,
        streaming: state.streaming,
        error: state.error,
        setBoxType,
        setCity,
        setSource,
        setSourceIndexPlaying,
        setLoadingStream,
        setStreaming,
        setError
      }}>
      { children }
    </streamContext.Provider>
  )
}

export default StreamState;