export const SET_INDEX_PLAYING = 'SET_INDEX_PLAYING';
export const SET_DOWNLOADING = 'SET_DOWNLOADING';
export const SET_AUDIOS = 'SET_AUDIOS';
export const AUDIOS = 'AUDIOS';
export const SET_LOADING_AUDIOS = 'SET_LOADING_AUDIOS';
export const ADD_DOWNLOAD_AUDIO = 'ADD_DOWNLOAD_AUDIO';
export const REMOVE_DOWNLOAD_AUDIO = 'REMOVE_DOWNLOAD_AUDIO';
export const SET_DATE_SELECTED = 'SET_DATE_SELECTED';
export const CLEAR_DOWNLOAD_AUDIOS = 'CLEAR_DOWNLOAD_AUDIOS';
export const ADD_ALL_DOWNLOAD_AUDIOS = 'ADD_ALL_DOWNLOAD_AUDIOS';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_STATION = 'SET_STATION';

export const SET_BOXTYPE = 'SET_BOXTYPE';
export const SET_STATIONS_IDS = 'SET_STATIONS_IDS';
export const SET_CITY = 'SET_CITY';
export const SET_CITYNAME = 'SET_CITYNAME';
export const SET_SOURCE = 'SET_SOURCE';
export const SET_SOURCE_INDEX_PLAYING = 'SET_SOURCE_INDEX_PLAYING';
export const SET_LOADING_STREAM = 'SET_LOADING_STREAM';
export const SET_STREAMING = 'SET_STREAMING';
export const SET_ERROR = 'SET_ERROR';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_PERMISSIONS_ERROR = 'SET_PERMISSIONS_ERROR';

export const SET_GUIDELINE = 'SET_GUIDELINE';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID';
export const UPDATE = 'UPDATE';
export const ADD_SCHEDULE_RANGE = 'ADD_SCHEDULE_RANGE';
