import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';

// Producción
var config = {
  apiKey: "AIzaSyAH7eXARukgBCLFOfw4-Kl-NatHc-fOA68",
  authDomain: "fourier-6e14d.firebaseapp.com",
  databaseURL: "https://fourier-6e14d.firebaseio.com",
  projectId: "fourier-6e14d",
  storageBucket: "fourier-6e14d.appspot.com",
  messagingSenderId: "1032228035742"
};

/*/// Demo
var config = {
  apiKey: "AIzaSyCbSZG9RSDTwa---BLirgJmRgho5N16myU",
  authDomain: "fourier-demo.firebaseapp.com",
  databaseURL: "https://fourier-demo.firebaseio.com",
  projectId: "fourier-demo",
  storageBucket: "fourier-demo.appspot.com",
  messagingSenderId: "845845242329"
};//*/

firebase.initializeApp(config);
firebase.firestore();

export default firebase;