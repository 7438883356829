import React from 'react';
import { Grid } from '@material-ui/core';
import Container from './../../components/Container';
import StationSelector from './StationSelector/index.js';
import StationDisplay from './StationDisplay';
import StreamState from './../../context/stream/streamState';

const StationsStreamView = () => {
  return (
    <StreamState>
      <Container press>
        <Grid container spacing={2}>
          <StationSelector />
          <StationDisplay />
        </Grid>
      </Container>
    </StreamState>
  );
};

export default StationsStreamView;