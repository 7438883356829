import React from 'react';
import './styles.css';

const StreamAnimation = ({ text }) => {
  return (
    <div className="stream-animation wrapper">
      <div className="player circle">
        <div className="buffering circle animated"></div>
        <div className="station">
          <span className="text">{ text }</span>
        </div>
      </div>
    </div> 
  );
};

export default StreamAnimation;