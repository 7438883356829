import React from 'react';
import {
  FormControl, Select, MenuItem,
  InputLabel, Grid
} from '@material-ui/core';
import { 
  SOPORTE, OPERADOR, ADMIN, ROLES_NAMES
} from '../../../constants/roles';

const RoleSelector = ({ error, value, onChange, disabled }) => {
  return (
    <Grid item xs={12} sm={4} md={12}>
      <FormControl
        fullWidth
        error={error}
        disabled={disabled}
        variant="filled">
        <InputLabel>
          Rol
        </InputLabel>
        <Select
          value={value}
          name="rol"
          onChange={onChange}>
          <MenuItem value={OPERADOR}>
            { ROLES_NAMES[OPERADOR] }
          </MenuItem>
          <MenuItem value={ADMIN}>
            { ROLES_NAMES[ADMIN] }
          </MenuItem>
          <MenuItem value={SOPORTE}>
            { ROLES_NAMES[SOPORTE] }
          </MenuItem>
        </Select>
      </FormControl>
      </Grid>
  );
};

export default RoleSelector;