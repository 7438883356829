import React from 'react';
import { Check as CheckIcon } from '@material-ui/icons'
import {
  Grid, FormControl,
  FormHelperText,
  InputLabel, Input,
  CircularProgress,
  Button
} from '@material-ui/core'
import { withFirebase } from 'react-redux-firebase';

class DeviceCalibrationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      box: null,
      saved: false,
      error: null,
      calibration: {}
    }
  }

  componentDidMount() {
    this.setState({ calibration: this.props.calibrations || {
      threshold: 10,
      tolerance: 0.7,
      fallTolerance: 1

    }, isLoading: false });
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.calibrations) !== JSON.stringify(this.props.calibrations)) {
      this.setState({ calibration: this.props.calibrations || {}, isLoading: false });
    }
  }

  save() {
    const toupdate = {};
    const calibrations = this.state.calibration;
    for (var key in calibrations) {
      toupdate[key] = this.sanitize(key, calibrations[key]);
    }

    if (this.props.boxID && this.props.boxID.trim()
        && this.props.source && this.props.source.trim()) {
      this.props.firebase
        .update(`devices/${this.props.boxID}/calibrations/${this.props.source}`, toupdate)
        .then(() => {
          this.setState({
            saved: true
          })
          if (this.props.onClose) {
            setTimeout(this.props.onClose, 1000);
          }
        })
        .catch(err => {
          this.setState({
            error: err
          })
        });
    }

  }

  render() {
    return this.state.isLoading
         ? this.renderLoading()
         : this.renderForm();
  }

  handleChange(name, value) {
    if (this.state.calibration) {
      let { calibration } = this.state;
      calibration[name] = value;
      this.setState({ calibration });
    }
  };

  sanitize(name, value) {
    let parsed;
    if (name === 'tolerance') {
      parsed = parseFloat(value);
    }
    else {
      parsed = parseInt(value, 10);
    }
    switch (name) {
      case 'threshold':
      case 'fallTolerance':
      case 'hourlyOffset':
      case 'gain':
        return isNaN(parsed) ? null : parsed;
      case 'segmentSize':
        if (value === 'integer' || value === 'ceil')
          return value;
        return isNaN(parsed) ? null : parsed;
      case 'tolerance':
        if (isNaN(parsed)) return null;
        if (parsed > 1.0)  return 1.0;
        if (parsed < 0.0)  return 0.0;
        return parsed;
      default:
        return null;
    }
  }

  calibControl(props) {
    return (
      <FormControl fullWidth>
        <InputLabel>{ props.label }</InputLabel>
        <Input
          value={this.state.calibration
                  && this.state.calibration[props.name] !== null
                  ? this.state.calibration[props.name] : '' }
          onChange={(e) => this.handleChange(props.name, e.target.value)}
          />
        { props.help ? <FormHelperText>{ props.help }</FormHelperText> : null }
      </FormControl>
    )
  }

  renderForm() {
    return (
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <this.calibControl name="threshold"
              label="Umbral / threshold (TH)"
              help="Default: 10" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <this.calibControl
              name="tolerance"
              label="Tolerancia (TL)"
              help="De 0.0 a 1.0." />
          </Grid>
          <Grid item xs={12} sm={6}>
            <this.calibControl name="fallTolerance"
              label="Tolerancia a caidas (FT)"
              help="Default: 0" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <this.calibControl name="segmentSize"
              label="Tamaño de segmento (SS)"
              help="En segundos o 'integer' o 'ceil'. Default: 5" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <this.calibControl name="hourlyOffset"
              label="Desplazamiento por hora (HO)"
              help="En segundos, default -5" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <this.calibControl name="gain"
              label="Ganancia"
              // help="En segundos, default -5"
              />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {this.props.onClose
              ? <Button
                  onClick={this.props.onClose} variant="outlined"
                  color="secondary"
                  style={{ marginRight: 16 }}>
                  Cerrar
                </Button>
              : null}
            {
              this.state.saved? 
                <Button
                  variant="outlined" color="primary"
                  onClick={this.save.bind(this)}>
                  <CheckIcon style={{fontSize: 18, marginRight: 8}} />Guardado 
                </Button>
                :
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.save.bind(this)}>
                    Guardar
                </Button>
              }
            
          </Grid>
          {
            this.state.error
            ? <Grid item>
                { this.state.error.toString() }
              </Grid>
            : null
          }
        </Grid>
      </form>
    )
  }

  renderLoading() {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }
}

export default withFirebase(DeviceCalibrationForm);