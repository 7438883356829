import moment from 'moment-timezone';

export const getLastUpdate = datetime =>  {
  let lastUpdate = '';
  const m = moment(datetime); 
  if (m.isValid) {
    lastUpdate = m.locale('es').fromNow();
  }
  else {
    lastUpdate = datetime;
  }
  return lastUpdate;
}

export const getFormattedDate = datetime => {
  let lastUpdate = '';
  const m = moment(datetime); 
  if (m.isValid) {
    lastUpdate = m.format('DD/MM/YYYY hh:mm a');
  }
  else {
    lastUpdate = datetime;
  }
  return lastUpdate;
}