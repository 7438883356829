import React from 'react';
import { 
  Paper, 
  Button, 
  Table,
  Grid,
  TableBody, 
  TableRow,
  TableCell,
  TableFooter
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import Api from '../../utils/Api';
import './../../App.css';
import Container from './../../components/Container';
import Title from './../../components/Title';
import Loader from './../../components/Loader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import {
  LiveTv as LiveTvIcon
} from '@material-ui/icons';

const styles = theme => ({
  paper: {
    padding: '0',
  },
  mobileShow: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
  },
  mobileHide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  date: {
    color: '#888686',
    fontStyle: 'italic',
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
      marginTop: '10px'
    }
  },
  adapterHead: {
    textAlign: 'center',
    width: '20px'
  }
});

class DeviceChannelsView extends React.Component {
  constructor(props) {
    super(props);
    this.deviceID = props.match.params.id;
    this.state = {
      cities: [],
      device: null,
      snackbar: false,
      alertMessage: '',
      channels: null,
      channelsMap: [],
      channelsNames: {},
      extendMessage: null,
      messageIndex: {index: null, message: null, error: null}
    }
  }

  handleCloseSnackbar(event, reason) {
    this.setState({ snackbar: false });
  }

  handleChange(event, index) {
    const self = this;
    const chanid = event.target.value;
    index = index || event.target.getAttribute('adapter-index');
    const names = self.state.channelsNames;
    Api
      .putBoxChannel(this.deviceID, chanid, index)
      .then(res => {
        if (res.success) {
          const channels = [...this.state.channels];
          channels[index].channel = chanid;
          this.setState({ channels });
          const messageIndex = { ...this.state.messageIndex };
          messageIndex.index = parseInt(index, 10);
          messageIndex.message = 'Guardado correctamente';
          messageIndex.error = null;
          this.setState({ messageIndex });
        } else {
          self.setState({
            messageIndex: {
              index: parseInt(index, 10),
              message: `El canal "${names[res.occupied_channel]}" `
                    + `ya está siendo usado en el adaptador ${res.occupied_index}.`,
              error: true
            }
          })
        }
    })
    .catch(error => {
      console.error(error)
    })
  }

  showAlert(message) {
    this.setState({
      snackbar: true,
      alertMessage: message
    });
  }

  extendChannels() {
    Api.extendBoxChannels(this.deviceID).then(res => {
      if (res.success) {
        const channels = this.state.channels;
        channels.push({ status: 'idle' })
        this.setState({
          channels: channels,
          extendMessage: 'Extendido correctamente'
        })
      } else {
        this.setState({ extendMessage: `Error ${res.error}` })
      }
      setTimeout(() => {
        this.setState({ extendMessage: null })
      }, 3000);
    })
    .catch(error => {
      this.setState({ extendMessage: `Error ${error}` });
    })
  }

  getFormattedDate(dateinput) {
    if (!dateinput) return '- - -';
    let date = new Date(dateinput);
    return `${date.toDateString()} ${date.toLocaleTimeString()}`
  }

  getSourceLabel(channel) {
    const city = this.state.citiesMap[channel.city];
    return (
      (city ? `[${city.description}] ` : '')
      + (channel.frequency ? `${channel.frequency} - ` : '')
      + channel.description
    )
  }

  render() {
    if (!isLoaded(this.props.auth) || !this.state.channels) {
      return <Loader />;
    }
    const state = this.state;
    const { device } = this.state;
    const msgi = this.state.messageIndex;
    const thereare = state.channels && state.channels.length;
    return (
      <Container press>
        <Paper className={this.props.classes.paper}>
          <Title pre="Caja" text={device.description || this.deviceID} icon={LiveTvIcon}/>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  className={`${this.props.classes.adapterHead} ${this.props.classes.mobileHide}`}>
                    Adaptador
                  </TableCell>
                <TableCell>ID</TableCell>
                <TableCell
                  className={this.props.classes.mobileHide}>
                  <Grid
                    container
                    justify="center">
                    <span>Última actualización</span>
                  </Grid>
                </TableCell>
              </TableRow>
              {
                this.state.channels ? 
                  this.state.channels.map((row, index) => {
                    return (
                      <TableRow
                        row={row}
                        key={index}>
                        <TableCell
                          style={{ textAlign: 'center' }}
                          className={this.props.classes.mobileHide}>
                            { index }
                        </TableCell>
                        <TableCell>
                          <Grid container alignItems="center">
                            <span
                              style={{ flexGrow: 1 }}
                              className={this.props.classes.mobileShow}>
                              { index }
                            </span>
                            <Autocomplete
                              style={{ flexGrow: 14 }}
                              value={this.state.channelsMap.find(channel => channel.id === row.channel)}
                              onChange={(e, value) => {
                                if (value) {
                                  this.handleChange({ 
                                    target: { value: value.id } },
                                    index.toString() 
                                  )
                                }
                              }}
                              options={this.state.channelsMap}
                              getOptionLabel={channel => this.getSourceLabel(channel)}
                              renderInput={params => (
                                <TextField {...params} label="Estación" variant="outlined" fullWidth/>
                              )}
                              />
                          </Grid>
                          <span
                            className={`${this.props.classes.date} ${this.props.classes.mobileShow}`}>
                            { this.getFormattedDate(row.lastUpdate) }
                          </span>
                          {
                            msgi.index === index?
                              (
                                <div style={{ color: msgi.error ? 'red' : 'green', padding: '5px 8px' }}>
                                  { state.messageIndex.message }
                                </div>
                              )
                              : null
                          }
                        </TableCell>
                        <TableCell
                          className={`${this.props.classes.date} ${this.props.classes.mobileHide}`}>
                            <Grid container justify="center">
                              <span>{ this.getFormattedDate(row.lastUpdate) }</span>
                            </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  }) : null
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    textAlign: thereare ? 'left' : 'center',
                    padding: !thereare ? '30px 0' : null
                  }}>
                  {!thereare
                    ? (<h3>No se han asignado canales a esta caja</h3>): null}
                  <Button
                    variant="contained" color="primary"
                    onClick={this.extendChannels.bind(this)}
                    size={!thereare ? 'large' : 'medium'}>
                    {!thereare ? 'Comienza agregando uno' : 'Extender Adaptadores'}
                  </Button>
                  {state.extendMessage ? (
                    <Button>
                      {state.extendMessage}
                    </Button>
                  ) : null}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Container>
    )
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.cities && this.props.cities) {
      const cities = [];
      const citiesMap = {...this.props.cities};
      citiesMap['nocity'] = { description: 'Sin ciudad' };
      for (let key in citiesMap) {
        let city = citiesMap[key];
        city.id = key;
        city.sources = [];
        cities.push(city);
      }
      this.setState({
        cities: cities,
        citiesMap: citiesMap
      });
    }
  }

  async componentDidMount() {
    const self = this;
    if (this.props.auth) {
      Api.sessionTokenID = this.props.auth.stsTokenManager.accessToken;
      Api.getBoxChannels(this.deviceID).then(res => {
        const names = {};
        // console.log('channels ->', res);
        res.channels.forEach(item => {
          names[item.id] = item.description;
        })
        // Ordernar por frecuencia de canal
        if (res.channels) {
          let pa, pb;
          res.channels.sort((a, b) => {
            if (a.city > b.city) {
              return -1;
            } 
            else if (a.city < b.city) {
              return 1;
            } 
            else {
              pa = parseFloat(a.frequency);
              pb = parseFloat(b.frequency);
              if (pa === pb) {
                return 0;
              }
              else if (pa > pb) {
                return 1;
              } 
              else {
                return -1;
              }
            }
          })
        }
        self.setState({
          channelsMap: res.channels,
          channelsNames: names
        })
        Api.getBoxTunedChannels(this.deviceID).then(res => {
          if (res.success) {
            self.setState({
              channels: res.channels ? res.channels : [],
              device: res.box
            })
          } else {

          }
        })
      })
    }
  }
}

const enhance = compose(
  firebaseConnect(['cities']),
  connect((state) => {
    return ({
      cities: state.firebase.data.cities,
      auth: state.firebase.auth
  })})
);

export default enhance(withStyles(styles)(DeviceChannelsView));
