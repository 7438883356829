import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Typography
} from '@material-ui/core';
import {
  Radio as RadioIcon
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#a5a5a5',
    userSelect: 'none'
  },
}));

const Empty = ({ text }) => {
  if (text === undefined) {
    text = 'Selecciona una estación.'
  }
  const classes = useStyles();
  return (
    <span
      className={classes.root}>
      <RadioIcon />
      <Typography
        variant="subtitle1">
        { text } 
      </Typography>
    </span>
  )
}

export default Empty;