import React, { Component } from 'react';
import {
  Dialog, DialogTitle, List,
  DialogContent, Typography,
  DialogActions, Button, Divider
} from '@material-ui/core';
import SourceChannel from './SourceChannel';
import SourceRadio from './SourceRadio';
import SourceInternetRadio from './SourceInternetRadio';
import DeviceCalibrationForm from './../../../devices/DeviceCalibration';
import { withFirebase } from 'react-redux-firebase';
import {
  SOURCE_ID_PROP, SOURCES_PROP
} from './../../../../constants/boxType';

class StationsDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stationPlaying: null, // station/channel that the user is currently listening to (if they are)
      stationPlayingIndex: null,
      isAudioLoading: true, // indicates that a station/channel was selected to play but is still loading ,
      selectedStationCal: null // indicates that a station it's going to be configurated (calibration)
    };
    this.audio = null; // audio element to play the station when required
  }

  render() {
    const items = [];
    const mode = this.props.device ? this.props.device.boxType : null;
    const sourcesProp = SOURCES_PROP[mode] || 'radios';
    if(this.props.device[sourcesProp]) { items.push(...this.props.device[sourcesProp]);}
    const sourceIdProp = SOURCE_ID_PROP[mode];
    
    return (
      <>
        <Dialog
          open={this.props.open}
          fullWidth
          onClose={() => {
            this.stopStation(); // stops playing the station when the dialog is closed
            this.props.onClose && this.props.onClose();
          }}
          // onExit={() => { this.stopStation(); console.log('onexit'); }}
          >
          <DialogTitle>
            { mode === 'tv'? 'Canales de TV' : null }
            { mode === 'radio'? 'Estaciones de Radio' : null }
            { mode === 'radioInternet'? 'Radios de Internet' : null }  
          </DialogTitle>
          <List
            dense>
            {items && items.length ?
              items.map((item, index) => {
                const extraInfo = this.props.stations.find(st => st.clave === item[sourceIdProp]);
                const sourceProps = {
                  key: index,
                  index: index,
                  onPlay: e => this.playStation(e),
                  onStop: e => this.stopStation(e),
                  item,
                  extraInfo,
                  timeZone: this.props.device.timezone,
                  isLoading: this.state.stationPlayingIndex === index && this.state.isAudioLoading,
                  isPlaying: this.state.stationPlayingIndex === index
                }
                if (mode === 'tv') return (
                  <SourceChannel
                    {...sourceProps}
                    onOpenCalibration={() => this.setState({ selectedStationCal: item.channel })}
                    /> 
                );
                if (mode === 'radio') return (
                  <SourceRadio
                    {...sourceProps}
                    onOpenCalibration={() => this.setState({ selectedStationCal: item.station })}
                    />
                );
                if (mode === 'radioInternet') return (
                  <SourceInternetRadio
                    {...sourceProps}
                    onOpenCalibration={() => this.setState({ selectedStationCal: item.id })}
                    />
                ); 
              }
              )
              : (
                <DialogContent>
                  <Typography
                    variant="h6"
                    style={{textAlign: 'center'}}>
                    No existen {mode === 'tv' ? 'canales de TV ' : 'estaciones de radio '}
                    para la caja
                      "{
                        this.props.device ? 
                          this.props.device.description || this.props.device.id
                          : null
                      }".
                  </Typography>
                </DialogContent>
              )
            }
          </List>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                this.stopStation();
                this.props.onClose && this.props.onClose();
              }}>
              Regresar
            </Button>
          </DialogActions>
        </Dialog>
        {
          this.state.selectedStationCal?
            <Dialog
              open={Boolean(this.state.selectedStationCal)}
              scroll="body"
              onClose={() => this.setState({ selectedStationCal: null }) }>
              <DialogTitle>
                Calibrar { this.props.device.id } / { this.state.selectedStationCal }
              </DialogTitle>
              <DialogContent>
                <DeviceCalibrationForm
                  boxID={this.props.device.id}
                  calibrations={
                    this.props.device.calibrations?
                      this.props.device.calibrations[this.state.selectedStationCal]
                      : null
                  }
                  source={this.state.selectedStationCal}
                  onClose={() => this.setState({ selectedStationCal: null }) }/>
              </DialogContent>
            </Dialog>
            : null
        }
      </>
    )
  }

  stopStation() {
    if (this.state.stationPlaying) {
      const { id } = this.props.device;
      const index = this.state.stationPlayingIndex;
      if (!id) return;
      const mode = this.props.device.boxType;
      switch (mode) {
        case 'tv':
          this.props.firebase.update(`devices/${id}/channels/${index}`, { stream: false });
          break;
        case 'radio':
          this.props.firebase.update(`devices/${id}/radios/${index}`, { stream: false });
          break;
      }
      if (this.audio) {
        this.audio.pause();
        this.audio.src = null;
      }
    }
    this.setState({
      stationPlaying: null,
      stationPlayingIndex: null,
      isAudioLoading: true
    })
  }

  playStation({ station, stationIndex }) {
    console.log("prueba")
    this.stopStation();
    const mode = this.props.device.boxType;
    const baseURL = 'https://stream.audiovalid.com';
    let playURL = null;
    const { id } = this.props.device;
    switch (mode) {
      case 'tv':
        playURL = `${ baseURL }/tv-${ id }${ stationIndex }.mp3`;
        break;
      case 'radio':
        playURL = `${ baseURL }/${ id }${ stationIndex }`;
        break;
      case 'radioInternet':
        playURL = `${ baseURL }/net-${ id }${ stationIndex }`;
        break;
    }

    if (!this.audio) {
      this.audio = new window.Audio(playURL);
    }
    else {
      this.audio.src = playURL;
      this.audio.load();
    }
    
    this.audio.addEventListener('error', err => {
      setTimeout(() => this.audio.load(), 1000);
    })

    this.audio.addEventListener('canplay', () => {
      this.audio.play();
      this.setState({ isAudioLoading: false });
    })

    let updatePath;
    switch (mode) {
      case 'tv':
        updatePath = `devices/${ id }/channels/${ stationIndex }`;
        break;
      case 'radio':
        updatePath = `devices/${ id }/radios/${ stationIndex }`;
        break;
      case 'radioInternet':
        updatePath = `devices/${ id }/radiosInternet/${ stationIndex }`;
        break;
    }

    if (id.trim()) {
      this.props.firebase
        .update(updatePath, { stream: true })
        .then(() => {
          this.setState({
            stationPlaying: station,
            stationPlayingIndex: stationIndex
          })
        });
    }
  }
}

export default withFirebase(StationsDialog);