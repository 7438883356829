import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment-timezone'
import TextField from '@material-ui/core/TextField';
import FourierApi from './../../utils/FourierApi';
import { Tooltip } from '@material-ui/core';
import { getLastUpdate, getFormattedDate } from './../../utils/Date';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  empty: {
    color: 'gray',
    margin: theme.spacing(1,2)
  },
  container: {
    margin: '0 auto',
    backgroundColor: '#ffffff',
    minWidth: '540px',
  },
  mainDialog: {
    backgroundColor: '#eaeaea'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const sortRecords = records => {
  return records.sort((record, nextRecord) => {
    const epoch = moment(record.fecha).unix();
    const nextEpoch = moment(nextRecord.fecha).unix();
    if (epoch < nextEpoch){
      return 1;
    }
    if (epoch > nextEpoch){
      return -1;
    }
    return 0;
  });
}

export default function MonitorBitacoraView(props) {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const [ openAdd, setOpenAdd ] = useState(false);
  const [ description, setDescription ] = useState('');
  const [ records, setRecords ] = useState([]);
  const [ idRecordToDelete, setIdRecordToDelete ] = useState(null);

  useEffect(() => {
    let isMounted = true; 

    FourierApi
      .getBitacoraRecords({
        idCiudad: props.box.id
      })
      .then(bitacoras => {
        if(isMounted) {
          setRecords(sortRecords(bitacoras));
        }
      })
    
    return () => isMounted = false;
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
    setDescription('');
  }

  const addRecord = () => { 
    FourierApi
      .addBitacoraRecord({
        cityId: props.box.id,
        comment: description
      })
      .then(response => {
        const newRecord = {
          ...response.data,
          fecha: new Date().toJSON()
        };
        setRecords([ newRecord, ...records ]);
        setOpenAdd(false);
      });
  }

  const handleChangeDescription = event => {
    setDescription(event.target.value)
  };

  const handleRecordDelete = id => {
    setIdRecordToDelete(id);
  }

  const deleteRecord = () => { 
    if (idRecordToDelete) {
      FourierApi
        .removeBitacoraRecord({ id: idRecordToDelete })
        .finally(() => {
          setRecords(records.filter(record => record.id !== idRecordToDelete));
          setIdRecordToDelete(null);
        });
    }
  }

  return (
    <div>
      {
        props.box?
          <div>
            <div onClick={handleClickOpen}>
              Bitacora
            </div>
            <Dialog
              classes={{ paper: classes.mainDialog }}
              className={classes.mainDialog}
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    BITACORA CAJA: { props.box.description ? props.box.description : props.box.id} 
                  </Typography>
                  <Button autoFocus color="inherit" onClick={handleOpenAdd}>
                  <AddIcon />
                    Agregar bitacora
                  </Button>
                </Toolbar>
              </AppBar>
              <div
                className={classes.container}>
                {
                  records.length?
                    <List>
                      {
                        records
                          .map(record => {
                            return (
                              <div key={record.id}>
                                <ListItem >
                                  <ListItemText
                                    primary={record.comentario}
                                    secondary={
                                      <Tooltip
                                        title={getFormattedDate(record.fecha)}>
                                        <span>
                                          { getLastUpdate(record.fecha) }
                                        </span>
                                      </Tooltip>
                                    }
                                    />
                                  <IconButton
                                    onClick={() => handleRecordDelete(record.id)}>
                                    <DeleteIcon/>
                                  </IconButton>
                                </ListItem>
                                <Divider />
                              </div>
                            );
                          }) 
                      }
                    </List>
                    : 
                    <Typography
                      className={classes.empty}
                      variant="subtitle1">
                      No hay registros aún
                    </Typography>
                }
              </div>
            </Dialog>
            <Dialog
              open={Boolean(idRecordToDelete)}
              onClose={() => setIdRecordToDelete(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"¡Atención!"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Estas a punto de eliminar este registro.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIdRecordToDelete(null)} color="primary">
                  Cancelar
                </Button>
                <Button onClick={deleteRecord} color="primary" autoFocus>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
            
            <Dialog
              open={openAdd}
              onClose={() => setIdRecordToDelete(null)}
              aria-labelledby="max-width-dialog-title"
              maxWidth="sm"
              fullWidth
            >
            <DialogTitle id="alert-dialog-title">
              Agregar registro bitacora
            </DialogTitle>
            <DialogContent >
              <TextField
                id="standard-multiline-flexible"
                label="Decripción"
                multiline
                value={description}
                fullWidth
                onChange={handleChangeDescription}
                value={description}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAdd(false)} color="primary">
                Cancelar
              </Button>
              <Button onClick={addRecord} color="primary" autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
          </div>
        :
          null
      }
    </div>
  );
}