import React from 'react';
import {
  Fab, CircularProgress
} from '@material-ui/core';
import {
  Stop as StopIcon,
  PlayArrow as PlayArrowIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';  

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'default',
    marginRight: '8px',
    color: '#ffffff'
  }
}));

const SourcePlayButton = ({ isPlaying, isLoading, onStop, onPlay }) => {
  const classes = useStyles();
  return (
    <Fab
      size="medium"
      title="Escuchar"
      className={classes.root}
      aria-label="play"
      color={isPlaying ? 'secondary' : 'primary'}
      onClick={e => {
        e.stopPropagation();
        if (isPlaying) {
          onStop(e);
        } 
        else {
          onPlay(e);
        }
      }}
      >
    {
      isPlaying ? 
      (
        isLoading ?
        (
          <span>
            <StopIcon />
            <CircularProgress
              color='primary'
              size={54}
              style={{ position: 'absolute', top: -3, left: -3 }}/>
          </span>
        )
        : <><StopIcon /></>
      )
      : <PlayArrowIcon />
    }
  </Fab>
  );
};

export default SourcePlayButton;