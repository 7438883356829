import React from 'react';
import { 
  Button, AppBar, Toolbar,
  IconButton, Typography
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }
}));

const TopBar = ({ onClose, onSave, description }) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton 
          edge="start"
          color="inherit"
          onClick={onClose}  
          aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h6"
          className={classes.title}>
          Estado de monitoreo
        </Typography>
        <Typography
          className={classes.title}
          variant="subtitle1">
            Caja: { description }
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={onSave}
          >Guardar Cambios
        </Button>
      </Toolbar>
    </AppBar>
  );
}
 
export default TopBar;