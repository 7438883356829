import React, { useState } from 'react';
import {
  Chip, TextField, Grid,
  Typography, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Add as AddIcon,
  Spellcheck as SpellcheckIcon
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    border: '1px solid #d4d4d4',
    borderRadius: theme.spacing(1),
    backgroundColor: '#e8e8e8'
  },
  inputs: {
    fontFamily: 'roboto',
    padding: theme.spacing(1.5, 0)
  },
  input: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: '#868686',
    color: '#ffffff',
    '& svg': {
      color: '#c0c0c0',
      transition: 'all 0.2s ease'
    },
    '& svg:hover': {
      color: '#dbdada'
    },
    '&:focus': {
      backgroundColor: '#a4a2a2'
    }
  },
  title: {
    '& > *': {
      fontSize: '14px',
    },
    paddingBottom: theme.spacing(1),
    '& svg': {
      marginRight: theme.spacing(1),
      color: '#808080'
    },
    userSelect: 'none'
  },
  empty: {
    color: 'gray',
    fontSize: '14px'
  }
}));

function MultipleInput({ title, inputs=[], onChange, disabled }) {
  const classes = useStyles();

  const [ input, setInput ] = useState('');

  const handleDelete = index => {
    let newInputs = [ ...inputs ];
    newInputs.splice(index, 1);
    onChange && onChange(newInputs);
  }

  const handleAddInput = () => {
    if (input.trim()) {
      const newInputs = [ ...inputs, input ];
      onChange && onChange(newInputs);
      setInput('');
    }
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.root}>
      <Grid item>
        <Grid 
          container
          alignItems="center"
          className={classes.title}>
          <SpellcheckIcon/>
          <Typography
            variant="subtitle1">
            { title || 'Nombres alternos' }
          </Typography>
        </Grid>
      </Grid>
      {
        disabled?
          null
          :
          <Grid 
            item>
            <Grid 
              container
              alignItems="center">
              <TextField
                value={input}
                onChange={event => setInput(event.target.value)}
                placeholder="Agregar"
                />
              <IconButton
                onClick={handleAddInput}
                size="small">
                <AddIcon/>
              </IconButton>
            </Grid>
          </Grid>
      }
      {
        inputs.length?
          <div 
            className={classes.inputs}>
            {
              inputs.map((input, index) => {
                return (
                  <Chip
                    className={classes.input}
                    key={input}
                    label={input}
                    size="small"
                    onDelete={disabled? null : () => handleDelete(index)}/>
                )
              })
            }
          </div>
          :
          <Typography
            className={classes.empty}
            variant="subtitle1">
            No hay registros aún
          </Typography>
      }
    </Grid>
  )
}

export default MultipleInput;
