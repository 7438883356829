import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { filesize } from 'humanize';

const theme = {
  success: '#5cb85c',
  successDark: '#4ba74b',
  successLight: '#c9f8db',
  successMid: '#73e78c',
  error: '#e72f28',
  warning: 'gold',
  yellowGreen: '#9ACD32'
};

const ServiceItem = ({ name, icon: Icon, info, badge }) => {
  const { isUp, memoryUsage, version, counterErrors } = info;
  const badgeColor = (counterErrors > 0 ? "error" : "primary");
  const background = isUp ? (counterErrors ? theme.yellowGreen : theme.success) : theme.error;
  return (
    <ListItem
      dense={true}
      button
      >
      {
        badge?
          <Badge
            badgeContent={badge}
            color={badgeColor}> 
            <Avatar
              style={{ background }}>
                <Icon />
            </Avatar>
          </Badge>
        :
          <Avatar
            style={{ background }}>
            <Icon />
          </Avatar>
      }
      &nbsp;&nbsp;&nbsp;&nbsp;
      <ListItemText
        primary={name}
        secondary={version}
        />
      <ListItemSecondaryAction
        style={{paddingRight: '12px', textAlign: 'center'}}
        >
          {
            filesize(memoryUsage, 1024, 0)
              .split(' ')[0]
          }
          <br />
          {
            filesize(memoryUsage, 1024, 0)
              .split(' ')[1]
          }
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ServiceItem; 
