import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import useVerifyRole from './../hooks/useVerifyRole';
import useAllowed from './../hooks/useAllowed';
import NotFoundView from './../views/NotFoundView';
import { OPERADOR } from './../constants/roles';
import Loader from './../components/Loader';
import firebase from 'firebase/app';

const getCurrentToken = () => {
  return new Promise((resolve, reject) => {
     const unsubscribe = firebase.auth().onIdTokenChanged(async user => {
        unsubscribe();
        if(user) {
          const token = await user.getIdToken();
          resolve(token);
        }
        else {
          resolve(null);
        }
     }, reject);
  });
}

const RouteAuth = ({ component: Component, role, rules, ...rest }) => {
  if (role === undefined) { role = OPERADOR; } 
  if (rules === undefined) { rules = {}; } 

  useEffect(() => {
    let isSubscribed = true;

    if(isSubscribed) {setLoadingToken(true);}
    getCurrentToken()
      .then(_token => {
        if(isSubscribed) {setToken(_token);}
      })
      .catch((error) => console.log(error))
      .finally(() => {
        if(isSubscribed) {setLoadingToken(false);}
      });
    return () => isSubscribed = false
  }, [ Component ]);

  const [ token, setToken ] = useState(null);
  const [ loadingToken, setLoadingToken ] = useState(false);
  const verified = useVerifyRole(role, Component); // has the needed role
  const allowed = useAllowed(rules); // has the needed permissions

  if (loadingToken || verified === null || allowed === null) {
    return <Loader />;
  }

  let ComponentToRender;
  if (!rest.requireAuth || verified && allowed) {
    // component doesn't requiere to be logged in or user has the permissions
    ComponentToRender = props => <Component { ...props } />;
  }
  else if (token === null) {
    // user isn't logged in
    ComponentToRender = ({ location }) => (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
    );
  }
  else {
    // user is logged in but doesn't have permissions
    ComponentToRender = () => <NotFoundView />;
  }

  return (
    <Route 
      {...rest} 
      render={matchProps => {
        return <ComponentToRender { ...matchProps } />;
      }} 
    />
  );
}

export default RouteAuth; 