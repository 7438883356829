import React from 'react';
import { useFirebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  ListItem
} from '@material-ui/core';
import statusMap from './statusMap';

import SourceLoader from './SourceLoader';
import SourceHead from './SourceHead';
import SourceCalibrationButton from './SourceCalibrationButton';
import SourcePlayButton from './SourcePlayButton';

const SourceInternetRadio = (props) => {
  const { item, timeZone, isPlaying, isLoading,
    onStop, onPlay, index, onOpenCalibration, extraInfo={} } = props;

  useFirebaseConnect([`stations/${item.id}`]);
  const radio = useSelector(({ firebase: { data } }) => data.stations && data.stations[item.id]);
  useFirebaseConnect([`cities`]);
  const cities = useSelector(({ firebase: { data } }) => data.cities);

  if (!isLoaded(radio) || !isLoaded(cities)) {
    return <SourceLoader />;
  }

  if (isEmpty(radio)) {
    return <p>La estación { item.id } no fue encontrada</p>;
  }

  const { siglas: acronym, siglasAlternas: extraAcronyms } = extraInfo;

  let city = cities[radio.city]? cities[radio.city].description || '' : '';
  let status = item.description === 'idle'? 'idle' : item.status;
  status = statusMap[status]? status : 'error';
  const title = status === 'idle'? 'No asignado.' : `${radio.name} (${city} ${radio.frequency})`;
  const expireTime = item.time? moment(item.time).add(10, 'minutes') : null;
  const statusOK = (item.status && item.status === 'ok')
      || (item.status && item.status === 'record')
      || (expireTime && moment().unix() < expireTime.unix());

  const lastUpdate = moment(item.time);
  if (timeZone){
    lastUpdate.tz(timeZone);
  }

  return (
    <ListItem
      button
      title="Presiona para calibrar"
      onClick={onOpenCalibration}>
      <SourceHead
        status={status}
        title={title}
        acronym={acronym}
        extraAcronyms={extraAcronyms}
        statusOk={statusOK}
        lastUpdate={lastUpdate}
        clave={extraInfo.clave}
        />
        { statusOK ?
          <SourcePlayButton
            isPlaying={isPlaying}
            isLoading={isLoading}
            onStop={e => onStop(e)}
            onPlay={e => onPlay({
              target: e.target,
              station: item,
              stationIndex: index
            })}
            />
          : null}
    </ListItem>
  )
};

export default SourceInternetRadio;
