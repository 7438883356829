import React, { useEffect, useState, useContext } from 'react';
import 'typeface-roboto';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MeetingRoom } from '@material-ui/icons';
import { Grid, Box } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import METLogotipo from '../../METLogotipo.png';
import UserDetail from './UserDetail';
import { withRouter } from 'react-router';
import MobileHeader from './MobileDrawer';
import FourierApi from './../../utils/FourierApi';
import { SEE } from './../../constants/permissionsActions';
import LINKS from './links';
import permissionsContext from './../../context/permissions/permissionsContext';
import { useFirebase } from 'react-redux-firebase';
import { isEmpty } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textTransform: 'uppercase',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    height: '80px',
    '& > *': {
      height: '100%'
    },
    '& img': {
      width: '150px'
    }
  },
  mobileButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    }
  },
  userOptions: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  link: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    textDecoration: 'none',
    fontFamily: 'roboto',
    color: '#575555',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.07)'
    }
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.07)'
  },
  icon: {
    color: '#575555'
  }
}));

const getNavLinks = (links, classes) => {
  return links.map(link => {
    return (
      <NavLink
        exact={Boolean(link.to === '/')}
        key={link.to}
        to={link.to}
        activeClassName={classes.active}
        className={classes.link}>
        { link.text }
      </NavLink>
    )}
  )
}

const getRoleLinks = (links, role) => {
  return links.filter(link => link.role >= role);
}

const getAllowedLinks = (links, permissions) => {
  const filteredLinks = links.filter(link => {
    const { permission: key } = link;
    return !key || permissions[key] && permissions[key][SEE];
  });
  return filteredLinks;
}

const filterLinks = (links, role, permissions) => {
  let filteredLinks = getRoleLinks(links, role);
  filteredLinks = getAllowedLinks(filteredLinks, permissions);
  return filteredLinks;
}

const Header = ({ location }) => {
  const classes = useStyles();
  const { permissions } = useContext(permissionsContext);
  const [ links, setLinks ] = useState([]);
  const [ role, setRole ] = useState(null);
  const firebase = useFirebase();
  const auth = useSelector(state => state.firebase.auth);

  isEmpty(auth) && <Redirect to="/login"/>

  useEffect(() => {    
    let isMounted = true;

    if (permissions) {
      FourierApi
        .getProfile()
        .then(profile => {
          if(isMounted) {
            setRole(profile.rol);
            let links = filterLinks(LINKS, profile.rol, permissions);
            setLinks(links);
          }
        })
    }

    return () => isMounted = false;
  }, [ permissions ]);

  return (
    <Box className={classes.root} px={4} > 
      <Grid container>
        <Grid item xs={3} md={2} style={{ height:'100%' }}>
          <Grid container style={{ height:'100%' }} direction="row" justify="flex-start" alignItems="center">
            <Link to="/">
              <img src={METLogotipo}/>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={7} md={8} container direction="row" justify="flex-start" alignItems="center">
          { getNavLinks(links, classes) }
        </Grid>
        <Grid 
          className={classes.mobileButton}
          item
          xs={2}
          container
          justify="flex-end">
          <MobileHeader role={role} links={links}/>
        </Grid>
        <Grid 
          className={classes.userOptions}
          item xs={2}>
          <Grid 
            container 
            style={{ height: '100%' }} 
            direction="row" justify="flex-end" alignItems="center">
            <UserDetail
              role={role}
              active={location && location.pathname.includes('profile') 
                      || location.pathname.includes('admin')}
            />
            <Button>
              <Link 
                onClick={async () => {
                  await firebase.logout();
                }} 
                to="/login"
              >
                <MeetingRoom className={classes.icon}/>
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(Header);