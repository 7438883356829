import React from 'react';
import { 
  List, ListItem, ListItemText,
  IconButton, ListItemSecondaryAction
} from '@material-ui/core';
import {
  Clear as ClearIcon
} from '@material-ui/icons';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#dcdcdc'
  },
  deleteBtn: {
    padding: theme.spacing(0.75),
    '& svg': {
      fontSize: '16px',
      color: '#9a9a9a'
    }
  }
}));

const HitsList = ({ audiosName, hits, timezone, onSelect, onDelete }) => {
  const classes = useStyles();
  const handleClick = hit => {
    onSelect && onSelect(hit);
  }
  const handleDelete = hit => {
    onDelete && onDelete(hit);
  }
  return (
    <List
      className={classes.root}>
      {
        hits.map((hit, index) => {
          const datetime = moment(hit * 1000);
          if (timezone) {
            datetime.tz(timezone);
          }
          return (
            <ListItem
              key={hit}
              button
              onClick={() => handleClick(hit)}
              dense>
              <ListItemText
                primary={datetime.format('HH:mm:ss') + ' - ' + (audiosName.find(a => a.hit == hit) ? (audiosName.find(a => a.hit == hit)).nombre : '') }/>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => handleDelete(hit)}
                    className={classes.deleteBtn}>
                    <ClearIcon />  
                  </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
          );
        })
      }
    </List>
  );
}

export default HitsList;