import React, { useState, useContext, useEffect  } from 'react';
import {
  ListItem, ListItemIcon, ListItemText,
  Divider, Collapse
} from '@material-ui/core';
import moment from 'moment-timezone';
import CheckButton from './CheckButton';
import PlayButton from './../../../../components/PlayButton';
import { makeStyles } from '@material-ui/core/styles';
import HitsList from './HitsList';

const useStyles = makeStyles(theme => ({
  playButton: {
    '& svg': {
      cursor: 'default'
    }
  },
  iconItem: {
    marginRight: theme.spacing(1),
    minWidth: '0!important'
  }
}));

const getStartPlaying = timestamp => {
  let foundDate = moment.unix(timestamp);
  let minutes = foundDate.minutes();
  let seconds = foundDate.seconds();
  let startPlaying = ((minutes % 5) * 60) + seconds;
  return startPlaying;
}

const AudioItem = ({ file, hits, guideline, timezone, selected, playing, onClickPlay, onCheckClicked, onHitDelete }) => {
  const [audiosName, setAudiosName] = useState([]);

  useEffect(() => {
    if(hits && hits.length && guideline) {
      hits.forEach(h => {
        const event = guideline.eventos.find(e => e.encontradosEpoch.includes(parseInt(h)));
        
        if(event && event.extra && event.extra.audio && event.extra.audio[h]) {
          setAudiosName(audiosName => [...audiosName, {hit: h, nombre: event.extra.audio[h].nombre} ]);
        }
      });
    }
  }, [hits, guideline])

  const classes = useStyles();
  const [ hitsOpen, setHitsOpen ] = useState(false);
  let audioTime = moment.unix(file.timestamp).tz(timezone);
  let m = audioTime.minutes().toString().padStart(2,'0');

  return (
    <>
      <ListItem 
        onClick={() => setHitsOpen(open => !open)}
        dense
        button>
        <ListItemIcon
          className={classes.iconItem}>
          <PlayButton
            className={classes.playButton}
            playing={playing}
            onClick={onClickPlay}/>
        </ListItemIcon>
        <ListItemText
          primary={
            <span style={{ fontWeight: selected? 'bold' : 'normal' }}>
              { audioTime.hours() + ":" + m } 
            </span>
          }/>
          <CheckButton
            timezone={timezone}
            hits={hits}/>
        </ListItem>
        <Collapse in={hitsOpen} timeout="auto" unmountOnExit>
          <HitsList
            audiosName={audiosName}
            hits={hits}
            timezone={timezone}
            onSelect={timestamp => {
              const startPlaying = getStartPlaying(timestamp);
              onCheckClicked && onCheckClicked(startPlaying);
            }}
            onDelete={timestamp => {
              onHitDelete && onHitDelete(timestamp);
            }}
          />
        </Collapse>
      <Divider />
    </>
  );
}

export default AudioItem;