import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import DragHandleIcon from '@material-ui/icons/Reorder';
import WorkIcon from '@material-ui/icons/Work';
import { Link } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';

import {
  OPERADOR,
  SOPORTE,
  ADMIN
} from './../../constants/roles';

const useStyles = makeStyles({
  list: {
    width: '250px',
  },
  fullList: {
    width: 'auto',
  },
  button: {
    height: '100%',
    '& svg': {
      color: '#999999',
      fontSize: '50px',
    }
  }
});

const MobileDrawer = ({ links, role }) => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const firebase = useFirebase();

  const toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const SideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
      >
      <List>
        {
          links.map(link => {
            const Icon = link.icon;
            return (
              <Link onClick={() => setOpen(false)} to={link.to} key={link.to}>
                <ListItem>
                  <ListItemIcon><Icon /></ListItemIcon>
                  <ListItemText primary={link.text}/>
                </ListItem>
              </Link>
            )
          })
        }
      </List>
      <Divider />
      <List>
        <Link onClick={() => setOpen(false)} to={'/profile'}>
          <ListItem>
            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
            <ListItemText primary={"Mi Perfil"}/>
          </ListItem>
        </Link>
        {
          role <= ADMIN? 
            <Link onClick={() => setOpen(false)} to={'/admin'}>
              <ListItem>
                <ListItemIcon><WorkIcon /></ListItemIcon>
                <ListItemText primary={"Administración"}/>
              </ListItem>
            </Link>
          : null
        }
        <Link 
          to={"/login"}
          onClick={async () => {
            setOpen(false);
            await firebase.logout();
          }} 
        >
          <ListItem>
            <ListItemIcon><MeetingRoomIcon /></ListItemIcon>
            <ListItemText primary={"Cerrar sesión"}/>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      <Button 
        className={classes.button}
        onClick={toggleDrawer(true)}>
        <DragHandleIcon />
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}>
         <SideList /> 
      </SwipeableDrawer>
    </div>
  );
}

export default MobileDrawer;