import React, { useState, useEffect } from 'react';
import UserForm from './UserForm';
import Container from './../../components/Container';
import Loader from './../../components/Loader';
import FourierApi from './../../utils/FourierApi';
import useVerifyRole from './../../hooks/useVerifyRole';
import { ADMIN } from './../../constants/roles';
import Alert from './../../components/Alert';

const getOnlyIds = dependencies => {
  return dependencies.map(dep => dep.id);
}

const UserEditView = ({ match }) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ user, setUser ] = useState({});
  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const canAssignDependencies = useVerifyRole(ADMIN);

  useEffect(() => {
    let isMounted = true; 

    FourierApi
      .getUsers({
        id: match.params.id
      })
      .then(users => {
        if (isMounted && users.length) {
          const [ user ] = users;
          const dependencias = getOnlyIds(user.dependencias);
          user.dependencias = dependencias;
          setUser(user);
        }
      })
      .finally(() => {
        if(isMounted) setLoaded(true)
      });
    
    return () => isMounted = false;
  }, []);

  const handleOnChange = user => {
    setUser(user);
  }  

  const handleSubmit = event => {
    event.preventDefault();
    FourierApi
      .editUser({
        id: match.params.id,
        entityId: user.idEntidad,
        name: user.nombre,
        role: user.rol,
        dependencies: user.dependencias,
        email: user.correo
      })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  }

  if (!loaded) {
    return <Loader />;
  }

  const hiddenFields = ['clave'];
  if (!canAssignDependencies) {
    hiddenFields.push('dependencias');
  }

  return (
    <Container press>
      <UserForm
        title='Editar Usuario'
        user={user}
        hide={hiddenFields}
        onChange={handleOnChange}
        onSubmit={handleSubmit}/>
      <Alert
        open={success}
        onClose={() => setSuccess(false)}
        severity="success"
        message="La información del usuario se guardó de manera exitosa."/>
      <Alert
        open={error}
        onClose={() => setError(false)}
        severity="error"
        message="Ocurrió un error."/>
    </Container>
  );
};

export default UserEditView;