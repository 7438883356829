import React, { useState, useEffect } from 'react';
import UserForm from './UserForm';
import Container from './../../components/Container';
import FourierApi from './../../utils/FourierApi';
import Alert from './../../components/Alert';

const UserNewView = ({ history }) => {
  const [ user, setUser ] = useState({});
  const [ error, setError ] = useState(null);

  const handleOnChange = user => {
    setUser(user);
  }  

  const handleSubmit = event => {
    event.preventDefault();
    FourierApi
      .createUser({
        entityId: user.idEntidad,
        name: user.nombre,
        role: user.rol,
        dependencies: user.dependencias,
        email: user.correo,
        key: user.clave
      })
      .then(() => {
        history.push('/users');
      })
      .catch(error => {
        setError(error.response.data.message);
      });
  } 

  return (
    <Container press>
      <UserForm
        title='Crear Usuario'
        user={user}
        onChange={handleOnChange}
        onSubmit={handleSubmit}/>
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        severity="error"
        message={error || "Ocurrió un error."}/>
    </Container>
  );
};

export default UserNewView;