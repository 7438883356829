import React from 'react';
import background from './../bg-interior.png';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const containerStyle = {
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  minHeight: '100vh'
};

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#3E9BBC',
        dark: '#307b96'
      },
      secondary: {
        main: '#6CBE45',
        dark: '#5ea73c'
      }
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: '#ffffff',
        },
      }
    }
});

const AppContainer = ({ children }) => {
  return (
    <div style={containerStyle}>
      <ThemeProvider theme={theme}>
        { children }
      </ThemeProvider>
    </div>
  );
};

export default AppContainer;