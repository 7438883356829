import React from 'react';
import { Button } from '@material-ui/core';
import { Publish as PublishIcon } from '@material-ui/icons';

const FilesButton = ({ onLoad, accept, text }) => {
  text = text || 'Busca un archivo';
  return (
    <Button
      color="primary"
      variant="contained"
      component="label">
      <PublishIcon />
      { text }
      <input
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        onChange={(e) => { 
          e.stopPropagation();
          e.preventDefault();
          onLoad && onLoad(e.target.files);
        }} />   
    </Button>
  );
};

export default FilesButton;