import React, { useState, useEffect } from 'react';
import { Grid, Paper, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StationsList from './StationsList';
import Loader from './../../../components/Loader';
import FourierApi from './../../../utils/FourierApi';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(2,2,1,2),
    '& > *': {
      marginBottom: theme.spacing(2.5)
    }
  }
}));

const StationSelector = () => {
  const classes = useStyles();
  const [grupos, setGrupos] = useState([]);
  const [idGrupo, setIdGrupo] = useState('');
  const [loading, setLoading] = useState(true);
  const [grupo, setGrupo] = useState({});

  useEffect(() => {
    let mounted = true;
  
    const getData = async () => {
      try {
        const grupos = await FourierApi.getGrupos(true);
        if(mounted) setGrupos(grupos);
      } catch (error) {
        console.log(error);
      } finally {
        if(mounted)  setLoading(false);
      }
    }
    getData();

    return () => mounted = false; 
  }, []);

  return (
    <Grid item xs={3}>
      <Paper className={classes.root}>
       { 
        loading
        ?
          <Loader />
        :
          <>
          <FormControl fullWidth variant="filled">
            <InputLabel> Ciudades </InputLabel>
            <Select
              onChange={event => {
                setIdGrupo(event.target.value)
                setGrupo(grupos.find(g => g.id === event.target.value));
              }}
              value={idGrupo}>
              {
                grupos.sort((a,b) => a.nombre > b.nombre ? 1 : -1).map(grupo => (
                  <MenuItem key={grupo.id} value={grupo.id}>
                    { `${grupo.nombre}` }
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <StationsList grupo={grupo}/>
          </>
        }
      </Paper>
    </Grid>
  );
};

export default StationSelector;