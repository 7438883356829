import React, { useContext, useEffect, useState } from 'react';
import {
  List, ListItem, ListItemText,
  Divider, Typography, Tooltip
} from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import PlayButton from './../../../components/PlayButton';
import FourierApi from './../../../utils/FourierApi';
import StreamContext from './../../../context/stream/streamContext';
import {
  SOURCES_PROP,
  SOURCE_ID_PROP
} from './../../../constants/boxType';
import { sortSources } from './../../../utils/Sort';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '185px',
    overflowY: 'auto',
    borderTop: "1px solid #eae8e8",
    borderBottom: "1px solid #eae8e8",
    backgroundColor: "#f5f1f1"
  }, 
  list: {
    paddingTop: '0'
  },
  listItem: {
    paddingTop: '0',
    paddingBottom: '0',
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  empty: {
    color: '#848080',
    padding: theme.spacing(1,1,0,1),
    userSelect: 'none'
  }
}));

const StationsList = ({grupo}) => {
  const classes = useStyles();
  const firebase = useFirebase();
  const [ stations, setStations ] = useState([]);
  const [ filteredStations, setFilteredStations ] = useState([]);
  const [ devices, setDevices ] = useState([]);
  const { city, setSource, source } = useContext(StreamContext);

  useEffect(() => {
    let isMounted = true;

    firebase
      .database()
      .ref()
      .child('devices')
      .once('value')
      .then(devicesSnap => {
        if(isMounted) setDevices(devicesSnap.val());
      });
    
    return () => isMounted = false;
  }, []);

  useEffect(() => {
    let unmounted = false;
    FourierApi
      .getStations({
        pageSize: '0'
      })
      .then(stations => {
        if (!unmounted) {
          setStations(sortSources(stations));
          if (city) {
            setFilteredStations(
              stations.filter(station => city.estaciones.includes(station.id))
            );
          }
        }
      })
    return () => { unmounted = true; }
  }, []);

  const getMatchingStationsFromFirebase = city => {
    const { id, tipo } = city;
    const sourcesProp = SOURCES_PROP[tipo]; // radios or channels or etc...
    const sources = devices[id][sourcesProp];
    return sources;
  }

  const selectStations = city => {
    const firebaseStations = getMatchingStationsFromFirebase(city);
    const { tipo } = city;
    const sourceIdProp = SOURCE_ID_PROP[tipo];
    const filteredStations = stations
      .filter(station => city.estaciones.includes(station.id))
      .map(station => {
        const stationMatch = firebaseStations
          .find(source => source[sourceIdProp] === station.clave);
        return {
          ...station,
          status: stationMatch.status
        }
      });    
    return filteredStations;
  }

  useEffect(() => {
    let mounted = true;

    if(grupo.ciudades) {
      let _allSelectedStations = [];


      grupo.ciudades.forEach(c => {
        let estaciones = [];

        c.ciudadEstacion.forEach(ce => {
          if(ce.monitoreable) {
            estaciones.push(ce.idEstacion)
          }
        });

        c.estaciones = estaciones;
        let selectedStations = selectStations(c);
        selectedStations = selectedStations.sort((a,b) => a.frecuencia - b.frecuencia); 
        _allSelectedStations = [..._allSelectedStations, ...selectedStations.map(s => ({...s, idCiudad: c.id, ciudadTipo: c.tipo }))];
        
      });

      if(mounted) setFilteredStations(_allSelectedStations);
    }
    else {
      if(mounted) setFilteredStations([]);
    }

    return () => mounted = false;
  }, [ grupo ]);

  const handlePlaySource = (playing, source) => {
    setSource(playing? null : source); // if it's already playing then we stop it
  }

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        {
          filteredStations.length ?
            filteredStations.sort((a,b) => a.frecuencia > b.frecuencia ? 1 : -1).map((station, index) => {
              const playing = source && source.id === station.id;
              const isDown = station.status !== 'ok';
              return (
                <React.Fragment
                  key={index}>
                  <Tooltip
                    placement="right"
                    title={isDown && 'No disponible' || ''}>
                    <ListItem className={classes.listItem}>
                      <PlayButton
                        disabled={isDown}
                        playing={playing}
                        onClick={() => {
                          handlePlaySource(playing, station)
                        }}/>                    
                      <ListItemText
                        primary={`${station.frecuencia}  ${station.siglas || ''}` }/>
                    </ListItem>
                  </Tooltip>
                  <Divider />
                </React.Fragment>
              )
            })
          :
          <Typography
            className={classes.empty}
            variant="subtitle2">
            { Object.keys(grupo).length && !filteredStations.length ? 'Esta ciudad no tiene estaciones guardadas.' : 'Elige una ciudad'}
          </Typography>
        }
      </List>
    </div>
  );
};

export default StationsList;