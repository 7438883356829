import React, { useEffect, useState } from 'react';
import Container from './../../components/Container';
import Table from './../../components/Table';
import Loader from './../../components/Loader';
import FourierApi from './../../utils/FourierApi';
import moment from 'moment-timezone';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const header = [
  {
    prop: 'id',
	name: 'ID'
  },
  {
	prop: 'estado',
	name: 'Estado'
  },
  {
	prop: 'nombre',
	name: 'Nombre'
  },
  {
	prop: 'count',
	name: 'Count'
  },
  {
	prop: 'ultimo',
	name: 'Último'
  }
];

const useStyles = makeStyles(theme => ({
  datetime: {
	color: '#5f5f5f'
  },
  time: {
	// color: '#888888',
	marginLeft: theme.spacing(1),
	fontSize: '15px'
  }
}));

const DevicesRemainingFiles = () => {
	
  const classes = useStyles();
  const [ date, setDate ] = useState('');
  const [ devices, setDevices ] = useState(null);
    
  useEffect(() => {
		let isMounted = true;
		
		FourierApi
			.getDevicesRemainingFiles()
			.then(summary => {
				if(isMounted) {
					setDevices(summary.data);
					setDate(summary.end);
				}
			});
		return () => isMounted = false;
  }, []);

  if (devices === null) {
    return <Loader />;
  }
	
  return (
    <Container press>
	  <Typography
		className={classes.datetime}
		variant="subtitle1">
		<b>Última actualización:</b>
		<span className={classes.time}>
		  <span>{ moment(date).format('LL')}</span>&nbsp;&nbsp;
		  <span>{ moment(date).format('h:mm:ss a')}</span>
		</span>
	  </Typography>
	  <Table
	    data={devices}
		header={header}
		/>
	</Container>
  );
}

export default DevicesRemainingFiles;