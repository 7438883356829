import React, { useEffect, useState } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { 
  GraphicEq as GraphicEqIcon,
  Computer as ComputerIcon,
  DateRange as DateRangeIcon,
  PermIdentity as PermIdentityIcon,
  AccountBalance as AccountBalanceIcon,
  LocationOn as LocationOnIcon,
  Games as GamesIcon,
  LocationCity as LocationCityIcon,
  Group as GroupIcon,
  Dvr as DvrIcon,
  Storage as StorageIcon,
  EventNote as EventNoteIcon
} from '@material-ui/icons';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from './../components/Loader';
import FourierApi from './../utils/FourierApi';
import useAllowed from './../hooks/useAllowed';
import { SEE, CREATE } from './../constants/permissionsActions';
import {
  SOPORTE,
  ROLES_NAMES
} from './../constants/roles';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HistoryIcon from '@material-ui/icons/History';
import UpdateIcon from '@material-ui/icons/Update';
const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1100px',
    margin: '0 auto',
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    }
  },
  adminHeader: {
    color:"#6b6a6a",
    backgroundColor: '#f9f5f5',
    padding: theme.spacing(1, 2)
  }
}));

const AdminView = () => {
  const classes = useStyles();
  const auth = useSelector(state => state.firebase.auth);
  const [ role, setRole ] = useState(null);
  const canSeeEntities = useAllowed({ ENTIDAD: [ SEE ] });
  const canSeeDependencies = useAllowed({ DEPENDENCIA: [ SEE ] });
  const canSeeStates = useAllowed({ ESTADO: [ SEE ] });
  const canSeeMonitor = useAllowed({ MONITOR: [ SEE ] });
  const canSeeUsers = useAllowed({ USUARIO: [ SEE ] });
  const canSeeAudios = useAllowed({ AUDIOS: [ SEE ] });
  const canSeeStations = useAllowed({ ESTACION: [ SEE ] });
  const canSeeCities = useAllowed({ CIUDAD: [ SEE ] });
  const canSeeCampaigns = useAllowed({ CAMPANA: [ SEE ] });
  const canCreateCampaigns = useAllowed({ CAMPANA: [ CREATE ] });

  useEffect(() => {
    let isMounted = true;

    FourierApi
      .getProfile()
      .then(profile => {
        if(isMounted) {
          setRole(profile.rol);
        }
      });

    return () => isMounted = false;
  }, []);

  if (role === null) {
    return <Loader />;
  }

  return (
    <>
      <Typography
        className={classes.adminHeader}
        variant="subtitle1"
        component="h2">
        <b>{ ROLES_NAMES[role] }:</b> { auth.email }
      </Typography>
      <Grid 
        className={classes.root}
        container direction="row" justify="center">
        <Grid container item xs={12} md={8}>
          {
            canSeeEntities?
              <Module
                to={'/entities'}
                title={'Entidades'}
                icon={PermIdentityIcon}/>
              : null  
          }
           
          {
            canSeeDependencies?
              <Module
                to={'/dependencies'}
                title={'Dependencias'}
                icon={AccountBalanceIcon}/>
                : null  
          }
          {
            canSeeCities?
              <Module
                to={'/cities'}
                title={'Ciudades'}
                icon={LocationCityIcon}/> 
                : null
          }
          {
            canSeeStations?
              <Module
                to={'/stations'}
                title={'Estaciones'}
                icon={GamesIcon}/>
                : null
          }
          {
            role <= SOPORTE?
              <>
                {/* <Module
                  to={'/firebase-devices'}
                  title={'Ciudades (firebase)'}
                  icon={LocationCityIcon}/>  */}
                <Module
                  to={'/firebase-stations'}
                  title={'Estaciones (firebase)'}
                  icon={GamesIcon}/>
              </>
              : null
          }
          {
            canSeeStates?
              <Module
                to={'/states'}
                title={'Estados'}
                icon={LocationOnIcon}/>
                : null  
          }
          {
            canSeeMonitor?
              <Module
                to={'/monitor'}
                title={'Monitor'}
                icon={ComputerIcon}/>
                : null
          }
          {
            canSeeUsers?
                <Module
                  to={'/users'}
                  title={'Usuarios'}
                  icon={GroupIcon}/>
                  : null
            }
          {
            canSeeAudios?
              <Module
                to={'/audios'}
                title={'Audios'}
                icon={GraphicEqIcon}/>
                : null
          }
          {
            canSeeMonitor?
              <Module
                to={'/monitor-status'}
                title={'Estado bitácora'}
                icon={DvrIcon}/>
                : null
          }
          <Module
            to="/devices-remaining-files"
            title="Archivos por subir"
            icon={StorageIcon}/>
        {
            canSeeCampaigns?
              <Module
                to={'/schedule'}
                title={'Calendario'}
                icon={DateRangeIcon}/>
                : null
          }
          {
            canCreateCampaigns?
              <Module
                to={'/campaigns/create'}
                title={'Campaña Manual'}
                icon={EventNoteIcon}/>
                : null
          }
        <Module
          to={'/plantillas'}
          title={'Plantillas pauta'}
          icon={ListAltIcon}
        />
        <Module
          to={'/campaigns/create-plantilla'}
          title={'Campaña Plantilla'}
          icon={EventNoteIcon}
        />
        <Module
          to={'/groups'}
          title={'Grupos'}
          icon={GroupWorkIcon}
        />

        <Module
          to={'/playerAdmin'}
          title={'Historico admin'}
          icon={HistoryIcon}
        />

        <Module
          to={'/background'}
          title={'Segundo plano'}
          icon={UpdateIcon}
        />
        </Grid>
      </Grid>
    </>
  );
};

export default AdminView;

const useModuleStyles = makeStyles(theme => ({
  root: {
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    cursor: 'default'
  },
  module: {
    margin: theme.spacing(3,3,1,3),
    width: theme.spacing(15),
    height: theme.spacing(15), 
    transition: 'all 0.2s ease',
    backgroundColor: '#424242',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: '1.15rem',
    '&:hover': {
      cursor: 'pointer',
      margin: theme.spacing(2.375,2.375,0.375,2.375),
      width: theme.spacing(15.75),
      height: theme.spacing(15.75),
    },
    '& > svg': {
      fontSize: theme.spacing(6.25)
    },
    '&.super': {
      border: "5px solid #6fe1f7e6",
    }
  },
}));

const Module = ({ title, icon: Icon, developer, to }) => {
  const classes = useModuleStyles();
  return (
    <Link
      to={to}
      className={classes.root}>
      <Paper
        elevation={5}
        className={`${ classes.module } ${ developer? 'super' : '' } `}>
          <Icon />
      </Paper>
      <Typography
        variant="subtitle2">
        { title }
      </Typography>
    </Link>
  );
};