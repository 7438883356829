import React, { useState } from 'react'
import {
  Menu, MenuItem
} from '@material-ui/core';

function StatusFilterMenu({ onSelect }) {

  const [ anchorEl, setAnchorEl ] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = status => {
    handleClose();
    onSelect(status);
  }

  return (
    <>
      <span
        aria-controls="status-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        Transmisión
      </span>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => handleSelect('all')}>
          TODO
        </MenuItem>
        <MenuItem
          onClick={() => handleSelect('nt')}>
          NT
        </MenuItem>
        <MenuItem 
          onClick={() => handleSelect('tfh')}>
          TFH
        </MenuItem>
        <MenuItem 
          onClick={() => handleSelect('tc')}>
          TC
        </MenuItem>
      </Menu>
    </>
  );
}

export default StatusFilterMenu
