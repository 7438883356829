import React, { useState } from 'react';
import { 
  List, ListItem, ListItemText, Grid, Typography, 
  ListItemSecondaryAction, Checkbox, TextField
} from '@material-ui/core';
import {
  ExpandLess, ExpandMore
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SourcesList from './SourcesList';
import { sortSources } from './../../../utils/Sort';
import { isNull } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'roboto',
    height: 'calc(100vh - 365px)',
    overflowY: 'auto'
  },
  city: {
    fontWeight: 'bold',
    color: '#545454'
  },
  expand: {
    '& svg': {
      color: 'gray'
    }
  }
}));

const SourcesSelector = ({ sources=[], cities=[], selected={}, onChange, onSelectAll, onUnselectAll, selectAll, onChangeFilter }) => {
  const classes = useStyles();
  const [ openCities, setOpenCities ] = useState([]);
  const [ filtro, setFiltro ] = useState("");

  const handleCityClick = (open, id) => {
    if (open) {
      const newOpenCities = openCities.filter(city => city !== id);
      setOpenCities(newOpenCities);
    }
    else {
      setOpenCities([ ...openCities, id ]);
    }
  }

  const handleSourceChange = (city, source) => {
    onChange && onChange(city, source);
  }
 
  const handleSelectAll = (alreadySelected, city) => {
    if (alreadySelected) {
      onUnselectAll && onUnselectAll(city.id);
    }
    else {
      onSelectAll && onSelectAll(city.id);
    }	  
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            value={filtro}
            onChange={event => 
              { 
                onChangeFilter(event.target.value);
                setFiltro(event.target.value);
              }
            }
            variant="filled"
            label="Filtrar estaciones o canales"
          />
        </Grid>
      </Grid>
      <List className={classes.root}>
        {
          cities.map(city => {
            const open = openCities.includes(city.id);
            const sourcesSelected = selected[city.id] || [];
            const allSelected = city.estaciones.every(s => sourcesSelected.includes(s));
            let stations = [];
            
            city.estaciones.forEach(sourceId => {
                let _sources = sources.find(source => {
                  if(filtro === "") {
                    return source.id === sourceId;
                  }
                  let p = filtro.replace(/ /g, "").split(",")
                  return source.id === sourceId && p.includes(`${source.frecuencia}`);
                });
                if(_sources !== undefined)
                  stations.push(_sources);
              });

            if(stations.length === 0) {
              return null;
            }

            return (
              <React.Fragment key={city.id}>
                <ListItem
                  button
                  className={classes.city}
                  onClick={() => handleCityClick(open, city.id)}>
                  <ListItemText>
                    { city.nombre } ({ city.id })
                  </ListItemText>
                  <ListItemSecondaryAction 
                    className={classes.expand}>
                      {city.estaciones.length
                        ? <Checkbox
                            checked={selectAll ? true : Boolean(allSelected)}
                            onChange={() => handleSelectAll(allSelected, city)}/>
                        : null
                      }
                      { open
                        ? <ExpandLess />
                        : <ExpandMore />
                      }
                  </ListItemSecondaryAction>
                </ListItem>
                <SourcesList
                  open={open}
                  onChange={source => handleSourceChange(city.id, source)}
                  selected={sourcesSelected}
                  sources={sortSources(stations)}
                  selectAll={selectAll}
                />
              </React.Fragment>
          )})
        }
      </List>
    </>
  )
}

export default SourcesSelector;