import React, { useContext, useEffect } from "react";
import './styles.css';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import PermissionsContext from './context/permissions/permissionsContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Route from './components/RouteAuth';
import AppContainer from './components/AppContainer';
import FourierApi from './utils/FourierApi';
import { transformToReadablePermissions } from './utils/auth';
import { SOPORTE, ADMIN } from './constants/roles';
import {
  SEE, CREATE, EDIT 
} from './constants/permissionsActions';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import {
  HomeView, CampaignsView, ReportsView,
  StationsView, UsersView, LogInView,
  DevicesView, StationsEditView, 
  DeviceEditView, ScheduledView, DeviceChannelsView,
  DeviceIntenetRadiosView, UserProfileView,
  StationNewView, AdminView, UserNewView, UserEditView,
  CampaignNewView, DependencyView, ReportsNewView,
  MonitorView, DeviceRadiosView, StationsStreamView,
  DependenciesView, EntitiesView, EntityNewView,
  DependencyNewView, PlayerView, StateEditView,
  NewStateView, NewStationView, CitiesView,
  AuthView, New_StationsView, EditStationView,
  StatesView, EditCityView, AudiosView, CampaignView,
  DependencyEditView, EntityEditView, CampaignReport,
  UserPermissionsView, NotFoundView, UserView,
  CityView, StationView, DevicesStatusView,
  CampaignStationReport, GuidelineNewView, 
  DevicesRemainingFilesView, CampaignCreate,
  CityStations, AudioEditView, AudioVersions,
  RecentUploadView, CampaignCreate2, PlantillasView,
  PlantillasNewView, CampaingCreatePlantilla, PlayerAudioView,
  MonitorView2, GruposView, PlayerAdmin, BackgroundView,
  ReportWebView
} from './views';

const bodyStyles = {
  margin: 0,
  padding: 0
};

const fetchPermissions = () => {
  return new Promise((resolve, reject) => {
    FourierApi
    .getProfile()
    .then(profile => {
      FourierApi
        .getPermissions()
        .then(options => {
          let readablePermissions = 
            transformToReadablePermissions(options, profile.permiso);
          if (readablePermissions === null) {
            readablePermissions = {};
            console.error('Formato incorrecto de permisos');
          }
          resolve(readablePermissions);
        })
        .catch(error => reject(error));
    })
    .catch(error => reject(error));
  });
}

export default () => {
  const { setPermissions, permissions } = useContext(PermissionsContext);
  const auth = useSelector(state => state.firebase.auth);

  useEffect(() => {
    let isSubscribed = true;

    if(isLoaded(auth)) {
      if(isEmpty(auth)) {
        if(isSubscribed) {setPermissions({});}
      }
      else {
        fetchPermissions()
          .then(permissions => {
            if(isSubscribed) {setPermissions(permissions);}
          })
          .catch(error => console.log(error));
      }
    }

    return () => isSubscribed = false;
  }, [ auth ]);

  if (!isLoaded(auth) || permissions === null) {
    return null;
  } 

  return (
    <AppContainer>
      <Router>
        {!isEmpty(auth) && <Header />}
       {/*  { isEmpty(auth) && <Redirect to="/login"/>} */}
        <div style={bodyStyles}>
          <Switch>
            <Route exact path="/login" component={LogInView} />

            <Route exact path="/" component={() => <Redirect to="/schedule" />} requireAuth/>

            <Route
              rules={{ CAMPANA: [ SEE ] }}
              exact path="/campaigns"
              component={CampaignsView} requireAuth />

            <Route
              rules={{ CAMPANA: [ CREATE ] }}
              exact path="/campaigns/new"
              component={CampaignNewView} requireAuth />

            <Route
              rules={{ CAMPANA: [ CREATE ] }}
              exact path="/campaigns/create"
              component={CampaignCreate2} requireAuth />
            <Route
              rules={{ CAMPANA: [ CREATE ] }}
              exact path="/campaigns/create-plantilla"
              component={CampaingCreatePlantilla} requireAuth />

            <Route
              rules={{ CAMPANA: [ CREATE ] }}
              exact path="/campaigns/create2"
              component={CampaignCreate} requireAuth />

            <Route
              rules={{ CAMPANA: [ SEE ] }}
              exact path="/campaigns/:id"
              component={CampaignView} requireAuth />

            <Route
              rules={{ CAMPANA: [ SEE ] }}
              exact path="/campaigns/report/:id"
              component={CampaignReport} requireAuth />     

            <Route
              rules={{ CAMPANA: [ EDIT ] }}
              exact path="/campaigns/replacement/:id"
              component={GuidelineNewView} requireAuth />       

            <Route
              rules={{ USUARIO: [ SEE ] }}
              exact path="/users" 
              component={UsersView} requireAuth />

            <Route
              rules={{ USUARIO: [ CREATE ] }}
              exact path="/users/new"
              component={UserNewView} requireAuth />

            <Route
              rules={{ USUARIO: [ SEE ] }}
              exact path="/users/:id" 
              component={UserView} requireAuth />
            
            <Route
              rules={{ USUARIO: [ EDIT ] }}
              exact path="/users/edit/:id"
              component={UserEditView} requireAuth />
            
            <Route
              rules={{ USUARIO: [ EDIT ] }}
              exact path="/users/permissions/:id"
              component={UserPermissionsView} requireAuth />

            {/* reports */}
            <Route
              // rules={{ REPORTE: [ SEE ] }}
              exact path="/reports" 
              component={CampaignStationReport} requireAuth />
            <Route
              // rules={{ REPORTE: [ SEE ] }}
              exact path="/reports/web" 
              component={ReportWebView} requireAuth />

            <Route
              rules={{ REPORTE: [ CREATE ] }}
              exact path="/reports/new"
              component={ReportsNewView} requireAuth />

            <Route
              rules={{ TRANSMISION: [ SEE ] }}
              exact path="/stations-stream"
              component={StationsStreamView} requireAuth />

            <Route
              exact path="/profile"
              component={UserProfileView} requireAuth />

            {/* states */}
            <Route
              rules={{ ESTADO: [ SEE ] }}
              exact path="/states"
              component={StatesView} requireAuth />
            
            <Route
              rules={{ ESTADO: [ CREATE ] }}
              exact path="/states/new"
              component={NewStateView} requireAuth /> 

            <Route
              rules={{ ESTADO: [ EDIT ] }}
              exact path="/states/edit/:id"
              component={StateEditView} requireAuth /> 

            {/* cities */}
            <Route 
              rules={{ CIUDAD: [ SEE ] }}
              exact path="/cities"
              component={CitiesView} requireAuth />

            <Route 
              rules={{ CIUDAD: [ SEE ] }}
              exact path="/cities/:id"
              component={CityView} requireAuth />

            <Route
              rules={{ CIUDAD: [ EDIT ] }}
              exact path="/cities/edit/:id"
              component={EditCityView} requireAuth />

            <Route
              rules={{ CIUDAD: [ EDIT ] }}
              exact path="/cities/:id/stations"
              component={CityStations} requireAuth />

            {/* stations */}
            <Route
              rules={{ ESTACION: [ SEE ] }}
              exact path="/stations"
              component={New_StationsView} requireAuth />

            <Route
              rules={{ ESTACION: [ CREATE ] }}
              exact path="/stations/new"
              component={NewStationView} requireAuth />

            <Route
              rules={{ ESTACION: [ SEE ] }}
              exact path="/stations/:id"
              component={StationView} requireAuth />

            <Route
              rules={{ ESTACION: [ EDIT ] }}
              exact path="/stations/edit/:id"
              component={EditStationView} requireAuth />

            {/* dependencies */}
            <Route
              rules={{ DEPENDENCIA: [ SEE ] }}
              exact path="/dependencies"
              component={DependenciesView} requireAuth />

            <Route
              rules={{ DEPENDENCIA: [ CREATE ] }}
              exact path="/dependencies/new"
              component={DependencyNewView} requireAuth />

            <Route
              rules={{ DEPENDENCIA: [ SEE ] }}
              exact path="/dependencies/:id"
              component={DependencyView} requireAuth />  

            <Route
              rules={{ DEPENDENCIA: [ EDIT ] }}
              exact path="/dependencies/edit/:id"
              component={DependencyEditView} requireAuth />

            {/* entities */}
            <Route
              role={ADMIN} rules={{ ENTIDAD: [ SEE ] }}
              exact path="/entities"
              component={EntitiesView} requireAuth />  

            <Route
              role={ADMIN} rules={{ ENTIDAD: [ CREATE ] }}
              exact path="/entities/new"
              component={EntityNewView} requireAuth />

            <Route
             role={ADMIN} rules={{ ENTIDAD: [ EDIT ] }}
             exact path="/entities/edit/:id"
             component={EntityEditView} requireAuth />

            {/* others */}
            <Route
              rules={{ REPRODUCTOR: [ SEE ] }}
              exact path="/player"
              component={PlayerView} requireAuth />

            <Route
              roles={SOPORTE}
              exact path="/auth"
              component={AuthView} requireAuth />

            <Route
              role={ADMIN} 
              exact path="/admin"
              component={AdminView} requireAuth/>
            
            <Route
              role={ADMIN} 
              exact path="/recentUpload"
              component={RecentUploadView} requireAuth/>

            <Route
              rules={{ AUDIOS: [ EDIT ] }}
              exact path="/audios/edit/:id"
              component={AudioEditView} requireAuth /> 
              
            <Route
              rules={{ AUDIOS: [ EDIT ] }}
              exact path="/audios/versions/:id"
              component={AudioVersions} requireAuth />    

            <Route
              rules={{ AUDIOS: [ SEE ] }}
              exact path="/audios"
              component={AudiosView} requireAuth />  

            <Route
              rules={{ MONITOR: [ SEE ] }}
              exact path="/monitor"
              component={MonitorView2}
              requireAuth />

            <Route
              exact path="/monitor-status"
              component={DevicesStatusView}
              requireAuth />  

            {/* developer view for devices */}
            <Route
              role={SOPORTE}
              exact path="/firebase-devices"
              component={DevicesView} requireAuth />
            
            <Route
              role={SOPORTE}
              exact path="/firebase-devices/edit/:id"
              component={DeviceEditView} requireAuth />
            
            <Route
              role={SOPORTE}
              exact path="/firebase-devices/:id/channels"
              component={DeviceChannelsView} requireAuth />

            <Route
              role={SOPORTE}
              exact path="/firebase-devices/:id/internet-radios"
              component={DeviceIntenetRadiosView} requireAuth />

            <Route
              role={SOPORTE}
              exact path="/firebase-devices/:id/radios"
              component={DeviceRadiosView} requireAuth />
			
			      <Route
              role={SOPORTE}
              exact path="/devices-remaining-files"
              component={DevicesRemainingFilesView} requireAuth />
	
            {/* developer view for stations */}
            <Route
              role={SOPORTE}
              exact path="/firebase-stations"
              component={StationsView} requireAuth />
            
            <Route
              role={SOPORTE}
              exact path="/firebase-stations/edit/:id"
              component={StationsEditView} requireAuth />
            
            <Route
              role={SOPORTE}
              exact path="/firebase-stations/new"
              component={StationNewView} requireAuth />

            <Route
              exact path="/404"
              component={NotFoundView}
            />

            <Route
              exact path="/schedule"
              component={ScheduledView}
              requireAuth />
            
            <Route
              exact path="/plantillas"
              component={PlantillasView}
              requireAuth 
            />
            <Route
              exact path="/plantillas/new/:id"
              component={PlantillasNewView}
              requireAuth 
            />

            <Route
              exact path="/player/audio"
              component={PlayerAudioView}
            />
            <Route
              exact path="/groups"
              component={GruposView}
            />
            <Route
              exact path="/monitor2"
              component={MonitorView2}
            />

            <Route
              role={ADMIN}
              exact path="/playerAdmin"
              component={PlayerAdmin}
            />

            <Route
              role={ADMIN}
              exact path="/background"
              component={BackgroundView}
            />

            {/* 404 */}
            <Route component={NotFoundView}/>
          </Switch>
        </div>
        <Footer />
      </Router>
    </AppContainer>
  )
}
