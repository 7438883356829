import React from 'react';
import {
  FormControl, Select, MenuItem,
  InputLabel, Grid
} from '@material-ui/core';

const EntitySelector = ({ entities, error, value, onChange, disabled }) => {
  return (
    <Grid item xs={12} sm={4} md={12}>
      <FormControl
        fullWidth
        disabled={disabled}
        error={error}
        variant="filled">
        <InputLabel>
          Entidad
        </InputLabel>
        <Select
        value={value}
        name="idEntidad"
        onChange={onChange}>
        <MenuItem value=""></MenuItem>
        { 
          entities.map(entity => (
            <MenuItem
              key={entity.id}
              value={entity.id}>
              { entity.nombre }
            </MenuItem>
          ))
        }
        </Select>
      </FormControl>
    </Grid>
  );
};

export default EntitySelector;