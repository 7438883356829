import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useFirebase } from 'react-redux-firebase';
import { Typography, Select, MenuItem,
  InputLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { HighlightOff as HighlightOffIcon } from '@material-ui/icons';
import Container from './../../components/Container';

const STATION_TYPES = {
  TV: 'tv',
  RADIO: 'radio',
  INTERNET: 'radioInternet'
}

const useStyles = makeStyles(theme => ({
  root: {
  },
  title: {
    padding: theme.spacing(2,4)
  },
  form: {
    padding: theme.spacing(4),
    borderTop: '1px solid #898989'
  },
  input: {
    width:'92%', margin:'0 auto', marginBottom:'20px'
  },
  select: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '200px'
  },
  error: {
    backgroundColor: red[500],
    color: 'white',
    padding: theme.spacing(2),
    margin: theme.spacing(0,0,2,0),
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.35)',
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      opacity: '0.5',
      transition: 'all 0.2s ease'
    },
    '& svg:hover': {
      opacity: '0.75',
      cursor: 'pointer'
    }
  },
  errorMessage: {
    marginBottom: 0,
    fontFamily: 'roboto'
  },
  invalidString: {
    marginLeft: '16px'
  },
  generateIdButton: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      marginBottom: '0'
    }
  }
}))

const StationNewView = (props) => {

  const classes = useStyles();  

  const [ error, setError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ validSignalStr, setValidSignalStr] = useState(true);

  // radio and tv
  const [ id, setId ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ frequency, setFrequency ] = useState('');
  const [ location, setLocation ] = useState('');

  // tv 
  const [ herz, setHerz ] = useState('');
  const [ audioID, setAudioID ] = useState('');
  const [ videoID, setVideoID ] = useState('');
  const [ serviceID, setServiceID ] = useState('');
  const [ city, setCity ] = useState('');
  const [ signal, setSignal ] = useState('');
  const [ modulation, setModulation ] = useState('8VSB');

  const [ snackbar, setSnackbar ] = useState(false);
  const [ alertMessage, setAlertMessage ] = useState('');
  const [ stationType, setStationType ] = useState(STATION_TYPES.RADIO);

  const [ url, setUrl ] = useState('');
  const [ format, setFormat ] = useState('');
  const [ protocol, setProtocol ] = useState('');

  const firebase = useFirebase();

  const showAlert = message => {
    setSnackbar(true);
    setAlertMessage(message);
  }

  const idIsValid = (id) => {
    return id && !id.includes('/') && !id.includes('.');
  }

  const allFieldsAreFilled = (type) => {
    return (
      type === STATION_TYPES.RADIO &&
        id.trim() && frequency && location && description ||
      type === STATION_TYPES.TV &&
        id.trim() && frequency && city && description && audioID &&
        city && herz && modulation && serviceID && signal && videoID ||
      type === STATION_TYPES.INTERNET &&
        id.trim() && frequency && location && description && url.trim()
    );
  }

  const saveData = (e) => {
    e.preventDefault();
    if (!idIsValid(id)) {
      setError(true);
      setErrorMessage('El id ingresado contiene carácteres no válidos.');
      return;
    }

    if (!allFieldsAreFilled(stationType)) {
      setError(true);
      setErrorMessage('Todos los campos requeridos (*) deben ser llenados.');
      return;
    }

    let station;
    if (stationType === STATION_TYPES.RADIO) {
      station = { 
        frequency: parseFloat(frequency),
        city: location,  // TODO: use same city in radio and tv
        name: description 
      };
    }
    else if (stationType === STATION_TYPES.TV) {
      station = { 
        audioID,
        city,
        description,
        frequency,
        herz: parseInt(herz),
        isTV: true,
        modulation,
        serviceID,
        signal,
        videoID
      };
    }
    else if (stationType === STATION_TYPES.INTERNET) {
      station = { 
        frequency: parseFloat(frequency),
        city: location, 
        name: description,
        internet: {
          url
        }
      };
      if (format) {
        station.internet.format = format;
      }
      if (protocol) {
        station.internet.protocol = protocol;
      }
    }

    firebase
      .set(`stations/${id}`, station)
      .then(() => {
        showAlert("Se guardó la estación.");
        props.history.push('/firebase-stations');
      });
  }

  const generateRadioId = () => {
    if (!location || !frequency) {
      setError(true);
      setErrorMessage('Para generar el id debes ingresar primero la ciudad y la frecuencia');
      return;
    }
    const id = `${location.toUpperCase()}${frequency.replace(/\./g,'_')}`
    setId(id);
  }

  const generateTvId = () => {
    if (!city || !frequency) {
      setError(true);
      setErrorMessage('Para generar el id debes ingresar primero la ciudad y la frecuencia');
      return;
    }
    const id = `TV_${city.toUpperCase()}_${frequency.replace(/\./g,'_')}`
    setId(id);
  }

  const cleanSignalStringFields = () => {
    setHerz('');
    setVideoID('');
    setAudioID('');
    setServiceID('');
  }

  const updateStringValues = str => {
    if (!str.trim()) {
      setValidSignalStr(true);
      cleanSignalStringFields();
      return;
    }
    const sections = str.split(';');
    if (sections.length !== 3) {
      setValidSignalStr(false);
      cleanSignalStringFields();
      return;
    }
    if (sections[0].includes(':')) {
      setValidSignalStr(false);
      cleanSignalStringFields();
      return;    
    }
    const secondSectionPieces = sections[1].split(':');
    if (secondSectionPieces.length !== 7) {
      setValidSignalStr(false);
      cleanSignalStringFields();
      return;    
    }
    const thirdSectionPieces = sections[2].split(':');
    if (thirdSectionPieces.length !== 7) {
      setValidSignalStr(false);
      cleanSignalStringFields();
      return;    
    }
    setValidSignalStr(true);
    setHerz(parseInt(secondSectionPieces[1])*1000);
    setVideoID(parseInt(secondSectionPieces[5]));
    setAudioID(parseInt(thirdSectionPieces[0]));
    setServiceID(parseInt(thirdSectionPieces[3]));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  }

  return (
    <Container press>
    <Paper className={classes.root}> 
      <Typography
        className={classes.title}
        variant="h4"
        color="primary">
        Nueva Estación
      </Typography>
      <form
        className={classes.form}
        onSubmit={saveData}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl
              className={classes.select}>
              <InputLabel id="select-station-type">Tipo</InputLabel>
              <Select
                labelId="select-station-type"
                value={stationType}
                onChange={e => setStationType(e.target.value)}
                >
                <MenuItem value={STATION_TYPES.RADIO}>Radio</MenuItem>
                <MenuItem value={STATION_TYPES.TV}>TV</MenuItem>
                <MenuItem value={STATION_TYPES.INTERNET}>Internet</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {
            error?
            <Grid item xs={12}>
              <div className={classes.error}>
                <label className={classes.errorMessage}>{errorMessage}</label>
                <HighlightOffIcon
                  onClick={() => setError(false)}/>
              </div>
            </Grid>
            : null
          }
          {
            stationType === STATION_TYPES.TV?
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Cadena de señal"
                  error={!validSignalStr}
                  helperText={
                    validSignalStr?
                      '' 
                      : 'Cadena Inválida'}
                  variant="filled"
                  style={{ marginLeft: '16px', marginBottom: '15px', width: '97.4%'}}
                  onChange={e => updateStringValues(e.target.value) }
                  />
             </Grid>
             : null
          }
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              className={classes.input}
              label="ID único *"
              variant="filled"
              style={{ marginLeft: 16 }}
              helperText={'Por ejemplo HMO98_5, CUL100_2'}
              value={id}
              onChange={e => setId(e.target.value)} />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              className={classes.input}
              label="Descripción *"
              variant="filled"
              style={{ marginLeft: 16 }}
              helperText={'Descripción'}
              value={description}
              onChange={e => setDescription(e.target.value)} />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              className={classes.input}
              label="Frecuencia *"
              variant="filled"
              type="number"
              style={{ marginLeft: 16 }}
              helperText={'Por ejemplo: 95.1 o 100.2'}
              value={frequency}
              onChange={e => setFrequency(e.target.value)}
              />
          </Grid>
          {
            stationType === STATION_TYPES.RADIO
              || stationType === STATION_TYPES.INTERNET ?
            <Grid item md={4} sm={6} xs={12}>
              <TextField 
                className={classes.input}
                label="Ubicación *"
                variant="filled"
                style={{ marginLeft: 16 }}
                helperText={'Puede ser la ciudad o la zona o algún otro distintivo'}
                value={location}
                onChange={e => setLocation(e.target.value)}
                />
            </Grid>
            : null
          }
          {
            stationType === STATION_TYPES.TV?
            <>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Ciudad *"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText={'Ciudad'}
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Id de Audio *"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText='Id de Audio'
                  value={audioID}
                  onChange={e => setAudioID(e.target.value)}
                  />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Herz *"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText={'Herz'}
                  value={herz}
                  onChange={e => setHerz(e.target.value)}
                  />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Modulación *"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText={'Modulación'}
                  value={modulation}
                  onChange={e => setModulation(e.target.value)}
                  />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Id de servicio *"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText={'Id de servicio'}
                  value={serviceID}
                  onChange={e => setServiceID(e.target.value)}
                  />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Señal *"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText={'Señal'}
                  value={signal}
                  onChange={e => setSignal(e.target.value)}
                  />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Id de video *"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText={'Id de video'}
                  value={videoID}
                  onChange={e => setVideoID(e.target.value)}
                  />
              </Grid>
              <Button
                color="primary"
                variant="contained"
                style={{ height: '50px'}}
                onClick={() => generateTvId()}
                >
                  Generar Id
              </Button>
            </>
            : null
          }
          {
            stationType === STATION_TYPES.INTERNET?
            <>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="URL *"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText={'Url'}
                  value={url}
                  onChange={e => setUrl(e.target.value)}
                  />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Formato"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText='MP3 por defecto'
                  value={format}
                  onChange={e => setFormat(e.target.value)}
                  />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField 
                  className={classes.input}
                  label="Protocolo"
                  variant="filled"
                  style={{ marginLeft: 16 }}
                  helperText={'HTTP por defecto'}
                  value={protocol}
                  onChange={e => setProtocol(e.target.value)}
                  />
              </Grid>
            </>
            : null
          }
          <Grid item xs={12} container justify="flex-end">
            <Grid 
              className={classes.generateIdButton}
              item
              xs={12}
              sm={3}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={
                  [STATION_TYPES.INTERNET, STATION_TYPES.RADIO].includes(stationType)? 
                    generateRadioId
                    : generateTvId
                }>
                Generar Id
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button 
                fullWidth
                type="submit"
                variant="contained"
                color="primary">
                  Guardar estación
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={() => handleCloseSnackbar()}
      message={alertMessage}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
    </Snackbar>
  </Container>
  );

}

export default StationNewView;