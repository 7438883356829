import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  PlayCircleFilled as PlayCircleFilledIcon,
  PauseCircleFilled as PauseCircleFilledIcon
} from '@material-ui/icons';

import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  icon: {
    color: '#b83ebc',
    cursor: 'pointer',
    fontSize: '30px',
    '&.disabled': {
      opacity: '0.5',
      color: '#848080',
      cursor: 'default'
    }
  },
  loader: {
    width: "30px!important",
    height: "30px!important",
    marginLeft: "-30px"
  }
}));

const PlayButton = ({ onClick, playing, style, loading, disabled, className = '', ...rest }) => {
  const classes = useStyles();
  const Icon = playing ? PauseCircleFilledIcon : PlayCircleFilledIcon;

  const handleClick = (event) => {
    if (disabled) {
      return;
    }
    onClick && onClick(event);
  }
  
  return (
    <span
      {...rest}
      style={style}
      className={`${classes.root} ${ className }`}>
      <Icon 
        onClick={handleClick}
        className={`${classes.icon} ${disabled? 'disabled' : ''}`}
        />
      {
        loading?
          <CircularProgress
            thickness={5}
            className={classes.loader}/>
          : null
      }
    </span>
  );
};

export default PlayButton;