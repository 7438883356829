import firebase from '../firebase';

const baseUrl = "https://api.fourier.audio/v2/";

const getCurrentToken = () => {
  return new Promise((resolve, reject) => {
     const unsubscribe = firebase.auth().onIdTokenChanged(async user => {
        unsubscribe();
        if(user) {
          const token = await user.getIdToken();
          resolve(token);
        }
     }, reject);
  });
}

const getHeaders = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});

const getHeadersWithoutToken = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
});

const HttpService = {
  get: async (url = "", auth = true) => {
    let token
    
    if (auth) token = await getCurrentToken();

    const response = await fetch(baseUrl + url, {
      method: "GET",
      headers: auth ? getHeaders(token) : getHeadersWithoutToken(),
    });

    return response.json();
  },
  post: async (url = "", data = {}, auth = true) => {
    let token;

    if (auth) token = await getCurrentToken();
    
    const response = await fetch(baseUrl + url, {
      method: "POST",
      headers: auth ? getHeaders(token) : getHeadersWithoutToken(),
      body: JSON.stringify(data),
    });

    return response.json();
  },
};

export default HttpService;
