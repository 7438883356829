import React from 'react';
import {
  Tooltip, Avatar,
  ListItemText
} from '@material-ui/core';
import statusMap from './statusMap';
import 'moment/locale/es';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    '& span': {
      color: '#a7a7a7',
      paddingLeft: theme.spacing(1)
    }
  }
}));


const SourceHead = ({ title, status, statusOk, lastUpdate, acronym, extraAcronyms, clave }) => {
  const Icon = statusMap[status].icon;
  const classes = useStyles();
  const getAcronymsString = () => {
    let str = '';
    if (acronym) {
      str += acronym 
    }
    if (extraAcronyms && extraAcronyms.length) {
      extraAcronyms.forEach(acr => {
        str += ', ' + acr
      });
    }
    return str;
  }

  return (
    <>
      <Tooltip title={statusMap[status].title}>
        <Avatar style={{ marginRight: '15px', background: statusMap[status].color }} >
          <Icon />
        </Avatar>
      </Tooltip>
      <ListItemText
        primary={
          <span
            className={classes.title}>
            { title } - [{ clave }]
            <span>
              { 
                getAcronymsString()
              }
            </span>
          </span>
        }
        secondary={statusOk && lastUpdate? lastUpdate.locale('es').fromNow() : null}
        />
    </>
  );
};

export default SourceHead;