import React, { useReducer } from 'react';
import permissionsContext from './permissionsContext';
import permissionsReducer from './permissionsReducer';
import {
  SET_PERMISSIONS,
  SET_PERMISSIONS_ERROR
} from '../../types';

const PermissionsState = ({ children }) => {
  const initialState = {
    permissions: null,
    permissionsError: false
  }

  const [ state, dispatch ] = useReducer(permissionsReducer, initialState);

  const setPermissions = permissions => {
    dispatch({
      type: SET_PERMISSIONS,
      payload: permissions
    });
  }

  const setPermissionsError = error => {
    dispatch({
      type: SET_PERMISSIONS_ERROR,
      payload: error
    });
  }

  return (
    <permissionsContext.Provider
      value={{
        permissions: state.permissions,
        permissionsError: state.permissionsError,
        setPermissions,
        setPermissionsError
      }}>
      { children }
    </permissionsContext.Provider>
  )
}

export default PermissionsState;