import React, { useState } from 'react';
import moment from 'moment';
import Table from '../../../components/Table';
import FourierApi from '../../../utils/FourierApi';
import PlayButton from '../../../components/PlayButton';
import EvidencePlayer from './NewEvidencePlayer';
import StatusFilterMenu from './StatusFilterMenu';
import Alert from './../../../components/Alert';
import { makeStyles } from '@material-ui/core/styles';
import StatusIcon from './../../monitor/MonitorStatusReport/StatusIcon';

const getStatus = event => {
  let status = '';
  if (event.nt) {
    status = 'NT';
  }
  else if (event.tc) {
    status = 'TC';
  }
  else if (event.tfh) {
    status = 'TFH';
  }
  return status;
}

const useStyles = makeStyles(theme => ({
  version: {
    display: 'block',
    color: 'gray'
  },
  status: {
    paddingLeft: '10px'
  }
}));

const getEpochDates = event => ({ 
  transmitidoEpoch: moment.tz(event.transmitido, event.timezone).unix(),
  fechaEpoch: moment.tz(event.fecha + ' ' + event.horario, event.timezone).unix()
});

const Report = ({ report, manual, source, onFilterSelect, ...rest }) => {

  const classes = useStyles();
  const [ playing, setPlaying ] = useState(false);
  const [ idPlaying, setIdPlaying ] = useState(null);
  const [ idLoading, setIdLoading ] = useState(null);
  const [ timezone, setTimezone ] = useState();
  const [ cityId, setCityId ] = useState(null);
  const [ date, setDate ] = useState(null);
  const [ blockDate, setBlockDate ] = useState(null);
  const [ error, setError ] = useState(false);
  const [ filteredReport, setFilteredReport ] = useState(report);
  const [ sourceKey, setSourceKey ] = useState(null);

  const fetchSource = id => {
    return new Promise((resolve, reject) => {
      FourierApi
        .getStations({ id })
        .then(sources => {
          if (sources.length) {
            resolve(sources[0]);
          }
          else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
      });
   }
    
  const showError = () => {
    setError(true);
    setIdLoading(null);
    setIdPlaying(null);
  }

  const reset = () => {
    setCityId(null);
    setDate(null);
    setBlockDate(null);
    setIdLoading(null);
    setPlaying(false);
    setIdPlaying(null);
    setSourceKey(null);
  }

  const handlePlayEvidence = event => {
    if (playing) {
      reset();
      if (idPlaying === event.id || idLoading === event.id) {
        return;
      }
    }
    setIdLoading(event.id);
    const { ciudad, idCiudad, timezone } = event;
    const { transmitidoEpoch, fechaEpoch } = getEpochDates(event);
    setCityId(idCiudad);
    setDate(transmitidoEpoch);
    setBlockDate(fechaEpoch);
    setTimezone(timezone);
    if (ciudad && transmitidoEpoch) {
      if (source) {
        setSourceKey(source.clave);
        handlePlayerStart(event.id);
      }
      else {
        const { idEstacion } = event;
        fetchSource(idEstacion)
          .then(source => { 
            setSourceKey(source.clave);
            handlePlayerStart(event.id);
          })
          .catch(() => { 
            showError();
          })
      }
    }
  }

  const handlePlayerStart = id => {
    setIdPlaying(id);
    setIdLoading(null);
    setPlaying(true);
  }

  const handlePlayerClose = () => {
    setPlaying(false);
    setIdPlaying(null);
  }

  const handleStatusFilter = status => {
    onFilterSelect && onFilterSelect(status);
    if (!manual) {
      switch (status) {
        case 'all':
          setFilteredReport(report);
          break;
        case 'nt':
          setFilteredReport(report.filter(row => row.nt));
          break;
        case 'tfh':
          setFilteredReport(report.filter(row => row.tfh));
          break;
        case 'tc':
          setFilteredReport(report.filter(row => row.tc));
          break;  
      }
    }
  }

  return (
    <>
      <Table
        header={[]}
        size="small"
        data={filteredReport}
        extraRows={[
          {
            prop: 'custom-fecha',
            name: 'Fecha',
            cell: row => row.fecha
          },
          {
            prop: 'custom-horario',
            name: 'Horario Pautado',
            cell: row => {
              let date = moment(row.fecha + ' ' + row.horario);
              return date.format("hh:mm:ss a");
            }
          },
          {
            prop: 'custom-clave',
            name: 'Clave',
            cell: row => row.clave
          },
          {
            prop: 'custom-dependencia',
            name: 'Dependencia',
            cell: row => row.dependencia
          },
          {
            prop: 'custom-estado',
            name: 'Estado',
            cell: row => row.estado
          },
          {
            prop: 'custom-ciudad',
            name: 'Ciudad',
            cell: row => row.ciudad
          },
          {
            prop: 'custom-estacion',
            name: 'Estacion',
            cell: row => row.estacion
          },
          {
            prop: 'custom-frecuencia',
            name: 'Frecuencia',
            cell: row => row.frecuencia
          },
          {
            prop: 'custom-calidad',
            name: 'Calidad',
            cell: row => <StatusIcon status={row.calidad}/>
          },
          {
            prop: 'custom-campana',
            name: 'Campaña',
            sortable: false,
            cell: row => (
              <span>
                { row.campana }
                <span className={classes.version}>
                  { row.version }
                </span>
              </span>
            )
          },
          {
            prop: 'custom-status',
            sortable: false,
            name: (
              <StatusFilterMenu
                onSelect={handleStatusFilter}/>
            ),
            cell: row => (
              <span
                className={classes.status}>
                { getStatus(row) }
              </span>
            )
          },
          {
            prop: 'custom-transmitido',
            name: 'Horario Transmisión',
            cell: row => {
              if(row.transmitido === null || row.transmitido === "")
                return "";
              let date = moment(row.transmitido);
              return date.format('YYYY-MM-DD hh:mm:ss a');
            }
          },
          {
            prop: 'custom-play',
            name: '',
            cell: row => (
              <PlayButton
                loading={idLoading === row.id}
                playing={idPlaying === row.id}
                onClick={() => handlePlayEvidence(row)}
                disabled={row.nt}/>
            )
          },
		    
        ].filter(row => 
          !(row.prop === 'custom-dependencia' && manual) 
          && !(row.prop === 'custom-estado' && !manual)
          && !(row.prop === 'custom-estacion' && !manual)
          && !(row.prop === 'custom-frecuencia' && !manual)
          && !(row.prop === 'custom-ciudad' && !manual)
          && !(row.prop === 'custom-calidad' && !manual)
        )
		}
        { ...rest }
        />
      <EvidencePlayer
        playing={playing}
        sourceKey={sourceKey}
        cityId={cityId}
        transmitedDate={date}
        timezone={timezone}
        date={blockDate}
        eventId={idPlaying}
        timezone={timezone}
        onClose={handlePlayerClose}
        />
      <Alert
        open={error}
        onClose={() => setError(false)}
        severity="error"
        message="Ocurrió un error."/>
    </>
  );
};

export default Report;