import React, { useState } from 'react';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { green, brown, red, purple } from '@material-ui/core/colors';

const MenuButton = ({ status, onRedoSearch, onSetAsFound, onSetAsNotFound,onSetIncidentReport }) => {

  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchAgain = () => {
    handleClose();
    onRedoSearch && onRedoSearch();
  }

  const handleSetAsFound = () => {
    handleClose();
    onSetAsFound && onSetAsFound();
  }

  const handleSetAsNotFound = () => {
    handleClose();
    onSetAsNotFound && onSetAsNotFound();
  }

  const handleSetIncidentReport = () =>{
    handleClose();
    onSetIncidentReport &&  onSetIncidentReport();
  }

  return (
    <> 
      <IconButton
        onClick={handleClick}
        size="small">
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {
          status === 'done'?
            <MenuItem onClick={handleSearchAgain}>
              <FiberManualRecordIcon style={{ color: purple[500] }} /> Volver a buscar
            </MenuItem>
            : null
        }
        <MenuItem onClick={handleSetAsFound}>
        <FiberManualRecordIcon style={{ color: green[500] }} /> Marcar como encontrado
        </MenuItem>
        <MenuItem onClick={handleSetAsNotFound}>
        <FiberManualRecordIcon style={{ color: red[500] }}  /> Marcar como no encontrado
        </MenuItem>
        {/* cambiar el onclick de Reporte de incidente */}
        <MenuItem onClick={handleSetIncidentReport}>
        <FiberManualRecordIcon style={{ color: brown[500] }}  />  Reporte de incidente
        </MenuItem>
      </Menu>
    </>
  );
}

export default MenuButton;