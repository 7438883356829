import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import Container from './../../components/Container';
import Loader from './../../components/Loader';
import FourierApi from './../../utils/FourierApi';
import Message from './../../components/Message';
import httpService from '../../services/httpService';

const useStyles = makeStyles(theme => ({
  root: {
  },
  title: {
    padding: theme.spacing(2,4)
  },
  form: {
    padding: theme.spacing(4),
    borderTop: '1px solid #898989'
  },
  input: {
    width:'92%', margin:'0 auto', marginBottom:'20px'
  }
}))

const urlProfile = "usuario?uid=";
const roles = {
  1: "SOPORTE", 
  2: "ADMIN",
  3: "OPERADOR"
};

const UserProfileView = () => {
  const classes = useStyles();
  const auth = useSelector(state => state.firebase.auth);
  const [ fullName, setFullName ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ role, setRole ] = useState('');
  const [ position, setPosition ] = useState('');
  const [ email, setEmail ] = useState(auth.email);
  const [ password, setPassword ] = useState(''); 
  const [ loading, setLoading ] = useState(true);
  const [ message, setMessage ] = useState('');
  const [ uploading, setUploading ] = useState(false);

  useEffect(() => {
    if (!isLoaded(auth)) return; 

    const getData = async () => {
      try {
       const response = await httpService.get(urlProfile + auth.uid);

       if(response.resultado[0]) {
        const user = response.resultado[0];
        setFullName(user.nombre);
        setRole(roles[user.rol]);
        
       }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [ auth ]);

  if (loading) {
    return <Loader />;
  }
  
  const handleSubmit = e => {
    e.preventDefault();
    setUploading(true);
    FourierApi
      .editProfile({
        name: fullName
      })
      .then(() => {
        setMessage('Guardado exitosamente.');
        setTimeout(() => setMessage(''), 3400);
      })
      .catch(error => console.error(error))
      .finally(() => {
        setUploading(false);
      });
  }

  return (
    <Container press>
      <Message message={message} onClose={() => setMessage('')}/>
      <Loader hide={!uploading} fixed/>
      <Paper className={classes.root}>
        <Typography className={classes.title} variant="h4" color="primary">
          Perfil
        </Typography>
        <form 
          onSubmit={handleSubmit}
          className={classes.form}>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                className={classes.input} 
                label="Nombre completo" 
                variant="filled" 
                value={fullName}
                onChange={e => setFullName(e.target.value)}/>
            </Grid>
            {/* <Grid item xs={12} sm={6} lg={4}>
              <TextField 
                className={classes.input}
                label="Área"//"Empresa/Dependencia" 
                variant="filled" 
                value={company}
                onChange={e => setCompany(e.target.value)}/>
            </Grid> */}
            <Grid item xs={12} sm={6} lg={4}>
              <TextField 
                className={classes.input}
                label="Rol" 
                variant="filled"
                value={role}
                onChange={e => setRole(e.target.value)} />
            </Grid>
           {/*  <Grid item xs={12} sm={6} lg={4}>
              <TextField
                className={classes.input}
                label="Puesto" 
                variant="filled"
                value={position}
                onChange={e => setPosition(e.target.value)} />
            </Grid> */}
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                className={classes.input}
                label="Correo electrónico" 
                variant="filled"
                value={email}
                onChange={e => setEmail(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField 
                className={classes.input}
                label="Contraseña" 
                variant="filled" 
                value={password}
                onChange={e => setPassword(e.target.value)} />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="flex-end">
            <Grid container item xs={12} sm={4} lg={4} direction="row" justify="flex-end">
              <Button style={{marginRight: '8%'}} 
                type="submit" 
                fullWidth
                variant="contained" 
                color="secondary">
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default UserProfileView;