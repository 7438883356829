import React, { useState, useEffect } from 'react';
import Container from './../../../components/Container';
import Alert from './../../../components/Alert';
import Loader from './../../../components/Loader';
import FourierApi from './../../../utils/FourierApi';
import { Card, Grid, TextField, Button, IconButton, List, ListItem, ListItemText, 
  ListItemSecondaryAction, Checkbox, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons'
import AudioSelector from './AudioSelector';
import SourcesSelector from './SourcesSelector';
import DatesInput from './DatesInput';
import BoxtypeFilter from './BoxtypeFilter';
import { makeStyles } from '@material-ui/core/styles';
import { sortCities, sortSources } from './../../../utils/Sort';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2),
  },
  root: {
    maxHeight: 'calc(100vh - 215px)'
  },
  button: {
    height: '35px'
  }
}));

const CampaignCreate = () => {
  const classes = useStyles();
  const [ audios, setAudios ] = useState([]);
  const [ cities, setCities ] = useState(null);
  const [ filteredCities, setFilteredCities ] = useState(null);
  const [ sources, setSources ] = useState(null);
  const [ datetimes, setDateTimes ] = useState([]);
  const [ spots, setSpots ] = useState([]);
  const [ folio, setFolio ] = useState('');
  const [ audioId, setAudioId ] = useState('');
  const [ citiesSources, setCitiesSources ] = useState({});
  const [ success, setSuccess ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  const [ audiosComplete, setAudiosComplete] = useState([]);
  const [ allAudios, setAllAudios] = useState(false);
  const [ messageError, setMessageError] = useState('');
  const [ selectAll, setSelectAll ] = useState(false);
  const [ filter, setFilter ] = useState('');

  useEffect(() => {
    let isMounted = true;

    Promise.all([
      FourierApi
        .getAudios({
          pageSize: '0'
        }),
      FourierApi
        .getStations({
          pageSize: '0'
        }),
      FourierApi
        .getCities({
          expand: 'estaciones'
        })
    ])
    .then(([ audios, sources, cities ]) => {
      if(isMounted) {
        setAudios(audios.slice(0,100));
        setAudiosComplete(audios);
        setSources(sortSources(sources));
        setCities(sortCities(cities));
        setFilteredCities(cities);
        setLoaded(true);
      }

      return () => isMounted = false;
    });
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  const handleFilter = types => {
    if(!types.length) {
      setSelectAll(false);
      setCitiesSources({});
    }

    const newFilteredCities = cities.filter(city => {
      return types.includes(city.tipo);
    });
	  let newCitiesSources = {};
    Object.keys(citiesSources).forEach(c => {
      const city = cities.find(city => city.id === c);
      if (types.includes(city.tipo)) {
      newCitiesSources[c] = citiesSources[c];
      }
    });
    setCitiesSources(newCitiesSources);
    setFilteredCities(newFilteredCities);
  }

  const handleSelectAll = city => {
    const newCitiesSources = {
      ...citiesSources,
      [city]: cities.find(c => c.id === city).estaciones
    };
    setCitiesSources(newCitiesSources);
  }
  
  const handleUnselectAll = city => {
    let newCitiesSources = {};
    Object.keys(citiesSources).forEach(c => {
      if (c != city) {
        newCitiesSources[c] =  citiesSources[c];
      }
    });
    setCitiesSources(newCitiesSources);
  }

  const addDateTime = (date, time) => {
    if (!date || !time) {
      setMessageError('Favor de seleccionar una fecha y hora.');
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
      
      return;
    }
    setDateTimes([ ...datetimes, date + ' ' + time ]);
  }

  const deleteDateTime = (index) => {
    let a = [];
    for(let i = 0, l = datetimes.length; i < l; i++) {
      if(i !== index) {
        a.push(datetimes[i]);
      }
    }
    setDateTimes(a);
  }

  /* const transformToArray = obj => {
    return Object
      .keys(obj)
      .map(city => ({
        id: city,
        estacion: obj[city]  
      }));
  } */

  const handleCitySourceChange = (city, source) => {
    if (!citiesSources[city]) {
      citiesSources[city] = [];
    }
    
    let newCitiesSources = {};

    if (citiesSources[city].includes(source)) {
      const newSources = citiesSources[city].filter(s => s !== source);	

      Object.keys(citiesSources).forEach(c => {
        if (c != city) {
          newCitiesSources[c] = citiesSources[c];
        }
      });
      
      if (newSources.length !== 0) {
        newCitiesSources[city] = newSources;
	    }
    }
    else {
      newCitiesSources = {
        ...citiesSources,
        [city]: [ ...citiesSources[city], source ]
      }
    }
	
    setCitiesSources(newCitiesSources);
  }

  const handleSubmit = event => {
    event.preventDefault();
    FourierApi
      .createRTCCampaign({
        order: folio,
        spots
      })
      .then((response) => {
        // console.log(response);
        setSuccess(true);
        setSpots([]);
        setFolio('');
        setAudioId('');
        setCitiesSources({});
        setDateTimes([]);
      })
      .catch((error) => {
        setError(true)
        setMessageError('Folio ya existente');
        // console.info(error);
      })
      
  }

  const addSpot = () => {
    setSpots([...spots, {ciudad: citiesSources, fecha: datetimes, audio: audioId}]);
    setCitiesSources([]);
    setDateTimes([]);
  }

  const deleteSpot = (index) => {
    let a = [];
    for(let i = 0, l = spots.length; i < l; i++) {
      if(i !== index)
        a.push(spots[i]);
    }
    setSpots(a);
  }

  const onChangeSelectAll = () => {
    let _citySources = {};

    if(selectAll) {
      setCitiesSources({});
    }
    else {
      if(filter) {
        filteredCities.forEach(city => {
          let _sourceIds = [];

          city.estaciones.forEach(estacion => {
            const source = sources.find(source => source.id == estacion);

            if(source) {
              if(filter.includes(",")) {
                const filters = filter.split(",");

                filters.forEach(_filter => {
                  if(source.frecuencia && source.frecuencia.toString().includes(_filter)) {
                    _sourceIds.push(source.id);
                  }
                });
              }
              else {
                if(source.frecuencia && source.frecuencia.toString().includes(filter)) {
                  _sourceIds.push(source.id);
                }
              }
            }
          });
          
          if(_sourceIds.length) {
            _citySources[city.id] = _sourceIds;
          }
        });
      }
      else {
        cities.forEach(city => {
          if(city.estaciones.length) {
            _citySources[city.id] = city.estaciones;
          }
        });
      }

      setCitiesSources(_citySources);
    }
 
    setSelectAll(!selectAll);
  }

  //const citiesSourceLength = Object.keys(citiesSources).length;
  const canSubmit = folio.trim() && audioId && spots.length;

  return (
    <Container press>
      <Card
        onSubmit={handleSubmit}
        component="form"
        className={classes.card}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={5} md={5}>
                <TextField
                  fullWidth
                  value={folio}
                  onChange={event => setFolio(event.target.value)}
                  variant="filled"
                  label="Folio"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <AudioSelector
                  id={audioId}
                  onChange={id => setAudioId(id)} 
                  audios={allAudios ? audiosComplete : audios} 
                />
              </Grid>
              <Grid item xs={1} md={1}>
                <Typography 
                  variant="subtitle1">
                  Todos
                </Typography>
                <Checkbox 
                  checked={allAudios}
                  onChange={() => setAllAudios(!allAudios)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <List>
            {
              spots.map((pauta, index) => {
                let ciudad = 0;
                let estaciones = 0;
                for(let i in pauta.ciudad) {
                  ciudad++;
                  estaciones += pauta.ciudad[i].length;
                }

                const fecha = pauta.fecha.join(", ");

                return (
                  <ListItem key={`spot-${index}`}>
                    <ListItemText 
                      primary={`Ciudades: ${ciudad}, Estaciones: ${estaciones}`} 
                      secondary={'Fecha: ' + fecha.toString() + ' - Audio: ' + (audios.find(audio => audio.id == pauta.audio)).clave} 
                    />
                    <Typography></Typography>
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => deleteSpot(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            }
            </List>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={5} md={5}>
                <BoxtypeFilter
                  onChange={handleFilter}
                />
                <SourcesSelector
                  selected={citiesSources}
                  sources={sources}
                  onChange={handleCitySourceChange}
                  cities={filteredCities}
                  onSelectAll={handleSelectAll}
                  onUnselectAll={handleUnselectAll}
                  selectAll={selectAll}
                  onChangeFilter={value => {
                      setFilter(value);

                      if(selectAll) {
                        setSelectAll(false);
                        setCitiesSources({});
                      }
                    }
                  }
                />
              </Grid>
              <Grid item xs={1} md={1}>
                <Typography 
                  variant="subtitle1">
                  Todos
                </Typography>
                <Checkbox 
                  checked={selectAll}
                  onChange={() => onChangeSelectAll()}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid alignItems="flex-end" container direction="column">
                  <DatesInput
                    datetimes={datetimes}
                    onAdded={addDateTime}
                    onDelete={deleteDateTime}
                  />
                </Grid>
                <Grid container alignContent="flex-end">
                  <Grid item xs={12} md={4}>
                    <Button
                      disabled={!Object.keys(citiesSources).length || datetimes.length === 0 || !audioId}
                      className={classes.button}
                      color="secondary"
                      variant="contained"
                      onClick={addSpot}
                    >
                      Agregar Pauta
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      disabled={!canSubmit}
                      className={classes.button}
                      type="submit"
                      color="secondary"
                      variant="contained">
                      Crear campaña
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Alert
        open={success}
        message="La campaña se guardó correctamente"
        onClose={() => setSuccess(false)}
        severity="success"
      />
      <Alert
        open={error}
        message={messageError}
        onClose={() => setError(false)}
        severity="error"
      />
    </Container>
  );
}

export default CampaignCreate;