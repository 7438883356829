import React from 'react';
import {
  Settings as SettingsIcon
} from '@material-ui/icons'; 
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    marginRight: '10px',
    color: '#928e8e'
  }
}));

const SourceCalibrationButton = ({ onOpenCalibration }) => {
  const classes = useStyles();
  return (
    <SettingsIcon
      className={classes.root}
      onClick={onOpenCalibration} />
  );
};

export default SourceCalibrationButton;