import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Dialog, List, Slide
} from '@material-ui/core';
import BackgroundImg from '../../../bg-interior.png';
import StatusRow from './StatusRow';
import FourierApi from '../../../utils/FourierApi';
import TopBar from './TopBar';
import Loader from './../../../components/Loader';
import MessageEmpty from './../../../components/MessageEmpty';
import Alert from './../../../components/Alert';

const useStyles = makeStyles(theme => ({
  dialog: {
    backgroundColor: '#9e9a9a',
    background: `url(${BackgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center'
  },
  list: {
    borderRadius: '0 0 4px 4px',
    boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.25)',
    backgroundColor: '#ffffff',
    maxWidth: '760px',
    margin: '0 auto'
  },
  empty: {
    marginTop: theme.spacing(2)
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MonitorStatusReport = ({ id, onClose, open }) => {
  const classes = useStyles();
  const [ sources, setSources ] = useState([]);
  const [ statuses, setStatuses ] = useState(null);
  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ modifiedStatusIds, setModifiedStatusIds ] = useState([]);
  const [ description, setDescription ] = useState('');
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    let isMounted = true;

      Promise.all([
        FourierApi
          .getCities({ 
            id,
            expand: 'estacion'
          }),
        FourierApi
          .getCityStationsStatus({ id})
      ])
      .then(([ cities, statuses ]) => {
        if(isMounted) {
          if (cities && cities.length) {
            setSources(cities[0].estacion);
            setDescription(cities[0].nombre || cities[0].id);
          }
          setStatuses(statuses);
          setLoaded(true);
        }
      });
    
    return () => isMounted = false;
  }, []);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleDataSave = () => {
    const updateRequests = modifiedStatusIds.map(sourceId => {
      const status = statuses.find(s => s.idEstacion == sourceId);
      return FourierApi
        .updateCityStationStatus({
          cityId: id,
          stationId: sourceId,
          status: status.calidad,
          comment: status.comentario
        })
    });
    Promise.all(updateRequests)
      .then(statuses => {
        setSuccess(true);
        console.log({ statuses });
        updateSavedStatuses(statuses);
      })
      .catch(() => {
        setError(true);
      });
  }

  const updateSavedStatuses = savedStatuses => {
    const savedIds = savedStatuses.map(st => st.data.idEstacion);
    const newStatuses = statuses.filter(st => !savedIds.includes(st.idEstacion));
    savedStatuses.forEach(status => {
      newStatuses.push(status.data);
    });
    setStatuses(newStatuses);
    setModifiedStatusIds([]);
  }

  const handleStatusChange = status => {
    if (!modifiedStatusIds.includes(status.idEstacion)) {
      setModifiedStatusIds([ ...modifiedStatusIds, status.idEstacion ]);
    }
    const newStatuses = statuses.filter(st => st.idEstacion !== status.idEstacion);
    setStatuses([
      ...newStatuses,
      status
    ]);
  }

  if (!loaded) {
    return <Loader />
  }

  return (
    <Dialog 
      fullScreen
      open={open} 
      onClose={handleClose} 
      TransitionComponent={Transition}
      PaperProps={{
        className: classes.dialog
      }}>
      <TopBar
        onClose={handleClose}
        onSave={handleDataSave}
        description={description}
        />
      {
        sources.length?
          <List
            className={classes.list}>
            {
              sources.map(source => {
                return (
                  <StatusRow
                    key={source.id}
                    source={source}
                    onChange={handleStatusChange}
                    status={statuses.find(status => status.idEstacion === source.id)}
                    />
                );
              })
            }
          </List>
          : 
          <MessageEmpty
            className={classes.empty}
            text="La caja no tiene estaciones/canales"/>
      }
      <Alert
        open={success}
        severity="success"
        message="Guardado exitosamente"
        onClose={() => setSuccess(false)}/>
      <Alert
        open={error}
        severity="error"
        message="Ocurrió un error."
        onClose={() => setError(false)}/>  
    </Dialog>
  );
}
 
export default MonitorStatusReport;