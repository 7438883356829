import { normalizeString } from './String';
import moment from 'moment-timezone';

const sortByNameOrId = objects => {
  return objects.sort((object, nextObject) => {
    if (normalizeString(object.nombre || object.id) 
      < normalizeString(nextObject.nombre || nextObject.id)) {
      return -1;
    }
    if (normalizeString(object.nombre || object.id) 
      > normalizeString(nextObject.nombre || nextObject.id)) {
      return 1;
    }
    return 0;
  });
}

const orderByCreationDesc = objects => {
  return objects.sort((object, nextObject) => {
    if (moment(object.creacion).unix() > moment(nextObject.creacion).unix()) {
      return -1;
    }
    if (moment(object.creacion).unix() < moment(nextObject.creacion).unix()) {
      return 1;
    }
    return 0;
  });
}

export const sortSources = sources => {
  return sources.sort((source, nextSource) => {
    if (`${source.estado} ${source.frecuencia}` < `${nextSource.estado} ${nextSource.frecuencia}`){
      return -1;
    }
    if (`${source.estado} ${source.frecuencia}` > `${nextSource.estado} ${nextSource.frecuencia}`){
      return 1;
    }
    return 0;
  });
}

export const sortGuidelinesWithCities = guidelines => {
  return guidelines
    .sort((guideline, nextGuideline) => {
      const { ciudad: city } = guideline;
      const { ciudad: nextCity } = nextGuideline;
      if (normalizeString(city.nombre || city.id) 
        < normalizeString(nextCity.nombre || nextCity.id)) {
        return -1;
      }
      if (normalizeString(city.nombre || city.id) 
        > normalizeString(nextCity.nombre || nextCity.id)) {
        return 1;
      }
      return 0;
    });
}

export const sortCities = sortByNameOrId;
export const sortStates = sortByNameOrId;
export const sortCampaigns = orderByCreationDesc;