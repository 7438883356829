import React from 'react';
import {
  Check as CheckIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    color: 'grey'
  }
}));

function CheckButton({ hits=[], timezone, onClick }) {
  const classes = useStyles();
  if (!hits.length) {
    return null;
  }
  return (
    <CheckIcon className={classes.icon}/>
  );
}

export default CheckButton;
