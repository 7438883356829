import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  instances: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    border: "none",
    borderRadius: "30px",
    padding: "6px 14px",
    cursor: "pointer",
    color: "#676060",
    width: 'fit-content'
  }
}));

const JoinedInstances = ({ instances, numbered }) => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  return (
    <div
      className={classes.root}>
      {
        open?
          <span
            className={classes.instances}>
            {
              instances.map((instance, index) => (
                <div key={instance} >
                  { numbered ? <b>{ index + 1 }.</b> : null }
                  { instance }
                </div>
              ))
            }
            <button
             onClick={() => setOpen(false)}
             className={classes.button}>
              Cerrar
            </button>
          </span>
        : <button
            onClick={() => setOpen(true)}
            className={classes.button}>
              Ver ({instances.length})
          </button>
      }
    </div>
  );
}

export default JoinedInstances;