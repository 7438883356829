import React from 'react';
import { 
  Button, Dialog, DialogActions,
  DialogContent, DialogContentText, 
  DialogTitle
} from '@material-ui/core';

const DeleteDialog = ({ open, onClose, onCancel, onAccept }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description">
      <DialogTitle id="delete-dialog-title">
        ¿Seguro que desea eliminar este registro?
      </DialogTitle>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={onAccept} color="primary" autoFocus>
          Aceptar
        </Button>
      </DialogActions>
  </Dialog>
  );
};

export default DeleteDialog;