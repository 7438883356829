import {
  SET_PERMISSIONS,
  SET_PERMISSIONS_ERROR
} from './../../types';
  
export default (state, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      }
    case SET_PERMISSIONS_ERROR:
      return {
        ...state,
        permissionsError: action.payload
      }
    default: 
      return state;
  }
}