import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '33px 15px 60px 15px',
    position: 'relative',
    '&.pressed': {
      maxWidth: '1100px',
      margin: '0 auto'
    },
    '&.noPaddingBottom': {
      paddingBottom: '0'
    }
  }
}));

const Container = ({ className: customClassName, children, press, noPaddingBottom }) => {
  const classes = useStyles();
  let className = classes.root;
  if (press) {
    className += ' pressed';
  }
  if (noPaddingBottom) {
    className += ' noPaddingBottom';
  }
  if (customClassName) {
    className += ' ' + customClassName;
  }
  return (
    <div className={className}>
      { children }
    </div>
  );
};

export default Container;