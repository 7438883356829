import {
  Warning as WarningIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  Error as ErrorIcon,
  SignalWifiOff as NoSignalIcon,
  SignalWifi2Bar as TuningIcon,
} from '@material-ui/icons';

export const theme = {
  success: '#5cb85c',
  successDark: '#4ba74b',
  successLight: '#c9f8db',
  successMid: '#73e78c',
  error: '#e72f28',
  warning: 'gold',
  yellowGreen: '#9ACD32'
}

const statusMap = {
  down: {
    icon: WarningIcon,
    color: theme.warning,
    title: "Caída"
  },
  idle: {
    icon: CloseIcon,
    color: null,
    title: "Sin asignar"
  },
  ok: {
    icon: DoneIcon,
    color: theme.success,
    title: "Grabando"
  },
  record: {
    icon: DoneIcon,
    color: theme.success,
    title: "Grabando"
  },
  error: {
    icon: ErrorIcon,
    color: theme.error,
    title: "Error"
  },
  no_signal: {
    icon: NoSignalIcon,
    color: theme.error,
    title: "Sin señal"
  },
  tuning: {
    icon: TuningIcon,
    color: theme.yellowGreen,
    title: "Sintonizando"
  }
}

export default statusMap;