import {
  SEE,
  CREATE,
  EDIT,
  DELETE
} from './../constants/permissionsActions';

const ACTIONS = [ SEE, CREATE, EDIT, DELETE ];

export const transformToBitsPermissions = (OPTIONS, permissions) => {
  const permissionBits = new Array(permissions.length);
  Object.keys(permissions).forEach(key => {
    const permission = permissions[key];
    const bits = getActionsBits(permission);
    const index = OPTIONS[key];
    permissionBits[index] = bits;
  });
  return permissionBits;
}

export const transformToReadablePermissions = (OPTIONS, permissionBits) => {
  const permissions = {};
  const names = getOrderedPermissionsNames(OPTIONS);
  permissionBits.forEach((permission, index) => {
    const name = names[index];
    permissions[name] = {};
    ACTIONS.forEach(action => {
      permissions[name][action] = hasPermission(permission, action);
    });
  });
  return permissions;
}

const getActionsBits = permission => {
  let bits = 0;
  ACTIONS.forEach((action, index) => {
    if (permission[action]) {
      bits +=  2 ** index;
    }
  });
  return bits;
}

const getOrderedPermissionsNames = OPTIONS => {
  const names = new Array(OPTIONS.length);
  Object.keys(OPTIONS).forEach(key => {
    const index = OPTIONS[key];
    names[index] = key;
  });
  return names;
}

const hasPermission = (permission, action) => {
  const index = ACTIONS.indexOf(action);
  const bit = 2 ** index;
  return (permission & bit) === bit; 
} 
