import React, { useEffect } from 'react';
import { useFirebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  ListItem
} from '@material-ui/core';
import statusMap from './statusMap';
import SourceCalibrationButton from './SourceCalibrationButton';
import SourceLoader from './SourceLoader';
import SourceHead from './SourceHead';
import SourcePlayButton from './SourcePlayButton';

const SourceRadio = (props) => {
  const { item, timeZone, isPlaying, isLoading,
    onStop, onPlay, index, onOpenCalibration, extraInfo={} } = props;

  useFirebaseConnect([`stations/${item.station}`]);
  const radio = useSelector(({ firebase: { data } }) => data.stations && data.stations[item.station]);
  useFirebaseConnect([`cities`]);
  const cities = useSelector(({ firebase: { data } }) => data.cities);

  console.log(item)

  if (!isLoaded(radio) || !isLoaded(cities)) {
    return <SourceLoader />;
  }

  const { siglas: acronym, siglasAlternas: extraAcronyms } = extraInfo;

  let status;
  let title;
  let city;
  let expireTime;
  let statusOk;
  let lastUpdate;
  if (!item.station || item.station === 'idle') {
    status = 'idle';
    title = 'No asignado.';
    statusOk = false;
  }
  else {
    city = cities[radio.city]? cities[radio.city].description || '' : '';
    status = status || (item.description === 'idle'? 'idle' : item.status || 'error');
    status = statusMap[status]? status : 'error';
    title = status === 'idle'? 'No asignado.' : `${radio.name} (${city} ${radio.frequency})`;
    expireTime = item.time? moment(item.time).add(10, 'minutes') : null;
    statusOk = (item.status && item.status === 'ok')
                || (item.status && item.status === 'record')
                || (expireTime && moment().unix() < expireTime.unix());
  
    lastUpdate = moment(item.time * 1000);
    if (timeZone){
      lastUpdate.tz(timeZone);
    }
  }

  return (
    <ListItem
      button
      title={statusOk? "Presiona para calibrar" : null}
      onClick={() => onOpenCalibration()}>
      <SourceHead
        status={status}
        title={title}
        acronym={acronym}
        extraAcronyms={extraAcronyms}
        statusOk={statusOk}
        lastUpdate={lastUpdate}
        clave={extraInfo.clave}
        />
      {
      statusOk ?
        <SourcePlayButton
          isPlaying={isPlaying}
          isLoading={isLoading}
          onStop={e => onStop(e)}
          onPlay={e => onPlay({
            target: e.target,
            station: item,
            stationIndex: index
          })}
          />
      : null  
    }
    </ListItem>
  )
};

export default SourceRadio;
