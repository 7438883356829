export default [
  'America/Tijuana',
  'America/Chihuahua',
  'America/Hermosillo',
  'America/Mazatlan',
  'America/Ojinaga',
  'America/Bahia_Banderas',
  'America/Matamoros',
  'America/Merida',
  'America/Mexico_City',
  'America/Monterrey',
  'America/Cancun'
];