import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, Grid, Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FourierApi from './../../../utils/FourierApi';
import Loader from './../../../components/Loader';
import DependenciesSelector from './DependenciesSelector';
import RoleSelector from './RoleSelector';
import EntitySelector from './EntitySelector';
import NameTextField from './NameTextField';
import EmailTextField from './EmailTextField';
import KeyTextField from './KeyTextField';

const useStyles = makeStyles(theme => ({
  root: {
  },
  title: {
    padding: theme.spacing(2,4)
  },
  form: {
    padding: theme.spacing(4),
    borderTop: '1px solid #898989'
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

const REQUIRED_FIELDS = ['nombre', 'idEntidad', 'rol', 'correo', 'clave'];

const isValidPassword = password => {
  return password.length >= 6;
}

const isValidEmail = email => {
  const emailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegularExpression.test(email);
}

const UserForm = ({ title, user, onChange, onSubmit, hide = [], disabled }) => {

  const classes = useStyles();
  const [ entities, setEntities ] = useState([]);
  const [ dependencies, setDependencies ] = useState(null);
  const [ invalidFields, setInvalidFields ] = useState([]);
  const [ entitiesLoaded, setEntitiesLoaded ] = useState(false);

  let { nombre, idEntidad, dependencias: dependenciasSeleccionadas, 
    correo, clave, rol } = user;

  nombre = nombre || '';
  idEntidad = idEntidad || '';
  rol = rol || '';
  correo = correo || '';
  clave = clave || '';
  dependenciasSeleccionadas = dependenciasSeleccionadas || [];

  useEffect(() => {
    let isMounted = true;

    FourierApi
      .getEntities({
        expand: 'dependencias'
      })
      .then(entities => {
        if(isMounted) {
          setEntities(entities);
          setEntitiesLoaded(true);
        }
      })

    return () => isMounted = false;
  }, []);

  useEffect(() => {
    if (entitiesLoaded) {
      if (idEntidad) {
        const entity = entities.find(entity => entity.id === idEntidad);
        setDependencies(entity.dependencias);
      }
      else {
        setDependencies(null);
      }
    }
  }, [ idEntidad, entitiesLoaded ]);

  const handleChange = event => {
    const { name, value } = event.target;
    const dependencias = (name === 'idEntidad'? [] : dependenciasSeleccionadas);
    onChange && onChange({ ...user, [name]: value, dependencias });
  }

  const fieldIsMissing = fieldId => {
    const field = user[fieldId];
    return !hide.includes(fieldId) && (field === undefined 
      || typeof field === 'string' && field.trim() === '');
  }

  const handlePreSubmit = () => {
    const invalidFields = [];
    REQUIRED_FIELDS
      .forEach(fieldId => {
        if (fieldIsMissing(fieldId)) {
          invalidFields.push(fieldId);
        }
      });
    if (!hide.includes('correo') && !isValidEmail(correo)) {
      invalidFields.push('correo');
    }
    if (!hide.includes('clave') && !isValidPassword(clave)) {
      invalidFields.push('clave');
    }    
    setInvalidFields(invalidFields);
  }

  const handleSubmit = event => {
    event.preventDefault();
    if (invalidFields.length === 0) {
      onSubmit && onSubmit(event);
    }
  }

  const handleSelectedDependenciesChange = selected => {
    onChange && onChange({ ...user, dependencias: selected });
  }

  if (!entitiesLoaded) {
    return <Loader />;
  }

  return (
    <Paper className={classes.root}>
      <Typography 
        className={classes.title}
        component="h2" 
        variant="h4" 
        color="primary">
        { title === undefined? 'Editar Usuario' : title }
      </Typography>
      <form 
        onSubmit={handleSubmit}
        className={classes.form}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="space-between">
          <Grid item xs={12} md={6} container spacing={2}>
            {
              hide.includes('nombre')?
                null
                : <NameTextField
                    value={nombre}
                    disabled={disabled}
                    onChange={handleChange}
                    error={invalidFields.includes('nombre')}
                    />
            }
            {
              hide.includes('correo')?
                null
                : <EmailTextField
                    value={correo}
                    disabled={disabled}
                    onChange={handleChange}
                    error={invalidFields.includes('correo')}
                    />
            }
            {
              hide.includes('clave')?
                null
                : <KeyTextField
                    value={clave}
                    disabled={disabled}
                    onChange={handleChange}
                    error={invalidFields.includes('clave')}
                    />
            }
            {
              hide.includes('idEntidad')?
                null
                : <EntitySelector
                    value={idEntidad}
                    disabled={disabled}
                    entities={entities}
                    error={invalidFields.includes('idEntidad')}
                    onChange={handleChange}/> 
            }
            {
              hide.includes('rol')?
                null
                : <RoleSelector
                    value={rol}
                    disabled={disabled}
                    error={invalidFields.includes('rol')}
                    onChange={handleChange}
                    />            
            }
          </Grid>
          <Grid item xs={12} md={6}>
            {
              hide.includes('dependencias')?
                null
                : <DependenciesSelector
                    dependencies={dependencies}
                    disabled={disabled}
                    selectedDependencies={dependenciasSeleccionadas}
                    onChange={handleSelectedDependenciesChange} />
            }
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          {
            disabled?
              null
              : <Button
                  className={classes.button}
                  onClick={handlePreSubmit}
                  type="submit" 
                  variant="contained" 
                  color="secondary">
                  Actualizar
                </Button>
          }
        </Grid>
      </form>
    </Paper>
  );
};

export default UserForm;