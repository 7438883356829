import { Grid, Checkbox, Box, Typography } from '@material-ui/core';
import React from 'react';

const CheckAllAudios = ({onChange}) => {
  return (
    <Grid item md={2} xs={3}>
      <div style={{paddingRight: 1, paddingLeft: 1}}>
        <Box display="flex" justifyContent="center">
          <Checkbox
            onChange={event => onChange(event.target.checked)}
            color="primary"
          />
          <Typography align="center">Listar todos los audios</Typography>
        </Box>
      </div>
    </Grid>
  )
}

export default CheckAllAudios;
