import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  head: {
    padding: theme.spacing(2, 0),
    borderBottom: '1px solid #898989'
  },
  label: {
    color: '#696666',
    fontSize: '27px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px'
    }
  },
  icon: {
    marginRight: '14px',
    fontSize: '33px',
    marginBottom: '-5px'
  },
  title: {
    marginLeft: '-20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px'
    }
  }
}));

const Title = ({ icon: Icon, text, pre }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.head}>
      <Typography
        align="center"
        color="primary"
        variant="h4"
        className={classes.title}
        >
        {
          Icon? 
            <Icon
              className={classes.icon} />
            : null
        }
        {
          pre? 
            <span className={classes.label}>
              { pre }: &nbsp;
            </span>
            : null
        }
         { text }
      </Typography>
    </div>
  );
};

export default Title;