import { Request } from './Request';
import Constants from '../constant';
import firebase from './../firebase';

class Api {

  constructor(baseUrl) {
    if(baseUrl === undefined) {
      throw Error("URL Base not found");
    }
    this.baseUrl = baseUrl;
    this.request = new Request();
    this.sessionTokenID = null;
  }

  _validate(box, params) {
    if (!box) {
      return false;
    }
    if (!params.client) {
      return false;
    }
    if (!params.ad) {
      return false;
    }
    if (!params.station) {
      return false;
    }
    return true;
  }

  getSchedules(client = null, box = null, ad = null, station = null, year = null, month = null) {
    const url = this.baseUrl + '/schedule';
    const now = new Date();
    if (year === null) {
      year = now.getFullYear()
    }
    if (month === null) {
      month = now.getMonth()
    }
    month++;
    const params = {
      client: client,
      ad: ad,
      station: station,
      year: year,
      month: month
    };
    const header = {
      'Authorization': box
    };

    if(!this._validate(box, params))
      return Promise.reject("some parameters not found.");

    return this.request.get(url, params, header);
  }

  sendSchedules(client = null, box = null, ad = null, station = null, items = []) {
    const url = this.baseUrl + '/schedule';
    const params = {
      client: client,
      ad: ad,
      station: station,
      items: items
    };
    const header = {
      'Authorization': box
    };

    if(items.length === 0)
      return Promise.reject("some parameters not found.");

    if(!this._validate(box, params))
      return Promise.reject("some parameters not found.");

    return this.request.post(url, params, header);
  }

  getFiles(box = null, station = null, date = null) {
    const url = this.baseUrl + '/files';
    if(station === null || date === null)
      return Promise.reject("some parameters not found.");
    const params = {
      station: station,
      date: date
    };
    const header = {
      'Authorization': box
    };
    return this.request.get(url, params, header);
  }

  redoComparison(box = null, itemID = null) {
    if(box === null || itemID === null)
      return Promise.reject("some parameters not found.");

    const url = this.baseUrl + '/schedule-item/' + itemID + '/reset-status';
    const params = {};
    const header = {
      'Authorization': box
    };

    return this.request.patch(url, params, header);
  }

  createBoxDefault() {
    const url = `${this.baseUrl}/device`
    return this.request.post(url, {},
      {'X-Firebase-Token': this.sessionTokenID})
  }

  createWebCampaign(fields) {
    const url = `${this.baseUrl}/trackely/campaign`
    const params = {
      'campaign': fields
    }
    return this.request.post(url, params,
      {'X-Firebase-Token': this.sessionTokenID})
  }

  createWebAd(fields) {
    const url = `${this.baseUrl}/trackely/ad`
    const params = {
      'ad': fields
    }
    return this.request.post(url, params,
      {'X-Firebase-Token': this.sessionTokenID})
  }

  getWebCampaigns() {
    const url = `${this.baseUrl}/trackely/campaign`
    return this.request.get(url, {},
      {'X-Firebase-Token': this.sessionTokenID})
  }

  getWebCampaign(cid) {
    const url = `${this.baseUrl}/trackely/campaign`
    return this.request.get(url, {campaign_id: cid},
      {'X-Firebase-Token': this.sessionTokenID})
  }

  getWebAd(ad_id, options={}) {
    const url = `${this.baseUrl}/trackely/ad`
    options.ad_id = ad_id
    return this.request.get(url,
      options,
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  getWebAds(params) {
    const url = `${this.baseUrl}/trackely/ad`
    return this.request.get(url, params,
      {'X-Firebase-Token': this.sessionTokenID})
  }

  uploadWebAdImage(adId, image, onProgress) {
    const url = `${this.baseUrl}/trackely/ad`
              + `?ad_id=${adId}&action=upload_image`
    const form = new FormData()
    form.append('file', image)

    return this.request.futch(url,
      {
        method: 'PUT',
        body: form,
        headers: {
          'X-Firebase-Token': this.sessionTokenID
        }
      },
      onProgress
    )
  }

  getAdHTMLTag(adId, options) {
    const url = `${this.baseUrl}/trackely/ad`
    const params = {ad_id: adId, action: 'tag'}
    if (options && options.prevent_count) {
      params.prevent_count = 1
    }
    return this.request.get(url, params,
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  modifyAd(adId, params) {
    const url = `${this.baseUrl}/trackely/ad?ad_id=${adId}`
    return this.request.request(url, {
      method: 'PUT',
      body: JSON.stringify({
        ad: params
      }),
      headers: {
        'X-Firebase-Token': this.sessionTokenID
      }
    })
  }

  modifyCampaign(campaignId, params) {
    const url = `${this.baseUrl}/trackely/campaign?campaign_id=${campaignId}`
    return this.request.request(url, {
      method: 'PUT',
      body: JSON.stringify({
        campaign: params
      }),
      headers: {
        'X-Firebase-Token': this.sessionTokenID
      }
    })
  }

  getHistory(params) {
    const url = `${this.baseUrl}/trackely/history`
    return this.request.get(url, params,
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  getStats(params) {
    const url = `${this.baseUrl}/trackely/stat`
    if (params) {
      if (params.ad_id) {
        params.ad_id = params.ad_id.join(",")
      }
      if (params.campaign_id) {
        params.campaign_id = params.campaign_id.join(",")
      }
    }
    return this.request.get(url, params,
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  getBoxTunedChannels(boxID) {
    const url = `${this.baseUrl}/device/${boxID}/channel`;
    return this.request.get(url,
      {action: 'tuned'},
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  getBoxTunedInternetRadios(boxID) {
    const url = `${this.baseUrl}/device/${boxID}/internet-radio`
    return this.request.get(url,
      {action: 'tuned'},
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  getBoxChannels(boxID) {
    const url = `${this.baseUrl}/device/${boxID}/channel`
    return this.request.get(url, {},
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  getBoxInternetRadios(boxID) {
    const url = `${this.baseUrl}/device/${boxID}/internet-radio`
    return this.request.get(url, {},
      {'X-Firebase-Token': this.sessionTokenID}
    ) 
  }

  putBoxChannel(boxID, channelID, index) {
    const url = `${this.baseUrl}/device/${boxID}/channel?action=change`
    return this.request.request(url, {
      method: 'PUT',
      params: {action: 'change'},
      body: JSON.stringify({
        channel_id: channelID,
        index: index
      }),
      headers: {
        'X-Firebase-Token': this.sessionTokenID
      }
    })
  }

  putBoxInternetRadio(boxID, sourceID, index) {
    const url = `${this.baseUrl}/device/${boxID}/internet-radio?action=change`
    return this.request.request(url, {
      method: 'PUT',
      params: {action: 'change'},
      body: JSON.stringify({
        source_id: sourceID,
        index: index
      }),
      headers: {
        'X-Firebase-Token': this.sessionTokenID
      }
    })
  }

  getTaxonomy(params) {
    const url = `${this.baseUrl}/trackely/taxonomy`
    return this.request.get(url, params,
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  createTaxonomy(fields) {
    const url = `${this.baseUrl}/trackely/taxonomy`
    return this.request.request(url, {
      method: 'POST',
      params: {},
      body: JSON.stringify({
        taxonomy: fields
      }),
      headers: {
        'X-Firebase-Token': this.sessionTokenID
      }
    })
  }

  extendBoxChannels(boxID) {
    const url = `${this.baseUrl}/device/${boxID}/channel`
    return this.request.post(url, {},
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

  extendBoxInternetRadios(boxID) {
    const url = `${this.baseUrl}/device/${boxID}/internet-radio`
    return this.request.post(url, {},
      {'X-Firebase-Token': this.sessionTokenID}
    )
  }

}
 
export default new Api(Constants.apiUrl);