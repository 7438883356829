import React from 'react';
import { useFirebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  ListItem, Tooltip, Avatar,
  ListItemText
} from '@material-ui/core';
import statusMap from './statusMap';

import SourceLoader from './SourceLoader';
import SourcePlayButton from './SourcePlayButton';
import SourceHead from './SourceHead';
import SourceCalibrationButton from './SourceCalibrationButton';

const SourceChannel = (props) => {
  const { item, timeZone, isPlaying, isLoading,
    onStop, onPlay, index, onOpenCalibration, extraInfo={} } = props;

  useFirebaseConnect([`stations/${item.channel}`]);
  const channel = useSelector(({ firebase: { data } }) => data.stations && data.stations[item.channel]);
  useFirebaseConnect([`cities`]);
  const cities = useSelector(({ firebase: { data } }) => data.cities);
  
  if (!isLoaded(channel) || !isLoaded(cities)) {
    return <SourceLoader />;
  }

  const { siglas: acronym, siglasAlternas: extraAcronyms } = extraInfo;

  let status;
  let city;
  let title;
  let expireTime;
  let statusOk;
  let lastUpdate;
  if (!item.status || item.status === 'idle') {
    status = 'idle';
    statusOk = false;
    title = 'No asignado.'
  }
  else {
    city = cities[channel.city]? cities[channel.city].description || '' : '';
    status = status || (item.description === 'idle'? 'idle' : item.status || 'error');
    title = status === 'idle'?
      'No asignado.' : `${channel.description} (${city} ${channel.frequency})`;
    expireTime = item.lastUpdate? moment(item.lastUpdate).add(10, 'minutes') : null;
    statusOk = (item.status && item.status === 'ok')
                || (item.status && item.status === 'record')
                || (expireTime && moment().unix() < expireTime.unix());
  
    lastUpdate = moment(item.lastUpdate);
    if (timeZone){
      lastUpdate.tz(timeZone);
    }
  }

  return (
    <ListItem
      button
      title="Presiona para calibrar"
      onClick={onOpenCalibration}>
      <SourceHead
        status={status}
        title={title}
        acronym={acronym}
        extraAcronyms={extraAcronyms}
        statusOk={statusOk}
        lastUpdate={lastUpdate}
        clave={extraInfo.clave}
        />
      {
        statusOk ?
          <SourcePlayButton
            isPlaying={isPlaying}
            isLoading={isLoading}
            onStop={e => onStop(e)}
            onPlay={e => onPlay({
              target: e.target,
              station: item,
              stationIndex: index
            })}
            />
            : null
      }
    </ListItem>
  )
};

export default SourceChannel;
