import React, { useState } from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from './../../components/Container';
import Title from './../../components/Title';
import Loader from './../../components/Loader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useFirebaseConnect, isLoaded, useFirebase } from 'react-redux-firebase';
import { Typography } from '@material-ui/core';
import {
  Radio as RadioIcon
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'roboto'
  },
  rowsContainer: {
    padding: '20px 80px'
  },
  row: {
    padding: '20px 0',
  },
  date: {
    color: '#888686',
    fontStyle: 'italic',
    fontSize: '14px'
  },
  station: {
    display: 'flex',
    alignItems: 'center'
  },
  index: {
    marginRight: '15px'
  },
  saved: {
    color: 'green',
    paddingTop: '5px' 
  },
  error: {
    color: 'red',
    paddingTop: '5px' 
  },
  notAssigned: {
    fontSize: '18px',
    margin: '10px auto',
    backgroundColor: 'rgba(0,0,0,0.06)',
    borderRadius: '30px',
    padding: '15px 63px',
    width: 'fit-content',
    userSelect: 'none'
  }
}));

const getFormattedDate = dateinput => {
  if (!dateinput) return '- - -';
  let date = new Date(dateinput * 1000);
  return `${date.toDateString()} ${date.toLocaleTimeString()}`
}

const stationTypeIsRadio = station => {
  return !station.isTV;
}

const getSourceLabel = (source, cities) => {
  const city = cities[source.city];
  return (
    (city ? `[${city.description}] ` : '')
    + (source.frequency ? `${source.frequency} - ` : '')
    + source.name
  )
}

const DeviceRadiosView = ({ match }) => {
  const classes = useStyles();
  const firebase = useFirebase();
  const [ savedIndex, setSavedIndex ] = useState(null);
  const [ errorIndex, setErrorIndex ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');
  useFirebaseConnect([`devices/${match.params.id}`, 'stations', 'cities']);
  let stations = useSelector(
      ({ firebase: { ordered } }) => {
        if (!ordered.stations) return ordered.stations;
        return ordered.stations
          .map(station => ({ ...station.value, id: station.key }))
          .filter(station => stationTypeIsRadio(station))
      }
    );
  const cities = useSelector(({ firebase: { data } }) => data.cities);
  const device = useSelector(({ firebase: { data } }) => data.devices && data.devices[match.params.id]);
  
  if (!isLoaded(device) || !isLoaded(stations) || !isLoaded(cities)) {
    return <Loader />;
  }

  const tunnedStations = device.radios || [];
  
  const extendRadios = () => {
    if (device && match.params.id.trim() && tunnedStations) {
      firebase.update(`devices/${match.params.id}/radios/${tunnedStations.length}`, { status: 'idle' });
    }
  }

  const handleChange = (value, index) => {
    if (tunnedStations.find(tunned => !tunned || tunned.station === value.id)) {
      setErrorIndex(index);
      setErrorMessage(`La estación ${ value.name } ya está siendo grabada.`);
      setTimeout(() => {
        setErrorIndex(null);
      }, 5000);
      return;
    }

    if (device && match.params.id.trim() && typeof(index) === 'number') {
      const newStation = {
        station: value.id,
        status: 'ok',
        time: parseInt((+ (new Date())) / 1000)
      };
      firebase
        .set(`devices/${match.params.id}/radios/${index}`, newStation)
        .then(() => {
          setSavedIndex(index);
          setTimeout(() => {
            setSavedIndex(null);
          }, 3000);
        })
      // console.log(`devices/${match.params.id}/radios/${index}`, newStation);
    }    
  }

  return (
    <Container press>
      <Paper
        className={classes.root}>
        <Title
          pre="Caja"
          text={device.description || match.params.id}
          icon={RadioIcon}/>
        <Grid 
          className={classes.rowsContainer}
          container>
            {
              tunnedStations.length?
                tunnedStations.map((tunnedStation, index) => (
                  <Grid
                    item
                    xs={12}
                    key={tunnedStation.station || index}
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.row}>
                      <Grid
                        className={classes.station}>
                        <span
                          className={classes.index}>
                          { index }
                        </span>
                        <span>
                          <Autocomplete
                            value={stations.find(station => station.id === tunnedStation.station)}
                            onChange={(e, value) => {
                              if (value) {
                                handleChange(value, index)
                              }
                            }}
                            options={stations}
                            getOptionLabel={station => getSourceLabel(station, cities)}
                            style={{ width: 400 }}
                            renderInput={params => (
                              <TextField {...params} label="Estación" variant="outlined" fullWidth/>
                            )}
                            />
                            { savedIndex === index? <SavedMessage classes={classes}/> : null }
                            { errorIndex === index? <ErrorMessage classes={classes} message={errorMessage} /> : null }
                        </span>
                        </Grid>
                        <span
                          className={classes.date}>
                          { getFormattedDate(tunnedStation.time) }
                        </span>
                    </Grid>
                ))
                :
                <Grid
                  item
                  xs={12}>
                   <Typography
                    className={classes.notAssigned}
                    align="center"
                    variant="h5">
                    No se han asignado estaciones a esta caja
                  </Typography>
                </Grid>
            }
          <Button
            variant="contained"
            color="primary"
            onClick={() => extendRadios()}>
            Agregar estación
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
};

const SavedMessage = ({ classes }) => {
  return (
    <div
      className={classes.saved}>
      Guardado correctamente
    </div>
  );
}

const ErrorMessage = ({ classes, message }) => {
  return (
    <div
      className={classes.error}>
      { message }
    </div>
  );
}

export default DeviceRadiosView;