import axios from 'axios';
import { API_URL } from './../constants/urls';
import firebase from 'firebase/app';

const url = API_URL;

let esc = encodeURIComponent;
const paramsToQuery = params => (
  Object.keys(params)
    .map(key => esc(key) + '=' + esc(params[key]))
    .join('&')
);

const getHeaders = token => ({
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Authorization': `Bearer ${ token }`,
})

const getCurrentToken = () => {
  return new Promise((resolve, reject) => {
     const unsubscribe = firebase.auth().onIdTokenChanged(async user => {
        unsubscribe();
        if(user) {
          const token = await user.getIdToken();
          resolve(token);
        }
     }, reject);
  });
}

const FourierApi = {
  async getEntities(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/entidad${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async createEntity(fields) {
    const token = await getCurrentToken();
    const { name, cities } = fields;

    return axios.post(`${url}/v1/entidad/crear`, {
        nombre: name,
        ciudades: cities
      },
      {
        headers: getHeaders(token)
      });
  },
  async editEntity(fields) {
    const token = await getCurrentToken();
    const { id, name, cities } = fields;

    return axios.post(`${url}/v1/entidad/editar?${ paramsToQuery({ id }) }`, {
      nombre: name,
      ciudades: cities
    },
    {
      headers: getHeaders(token)
    });
  },
  async getDependencies(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/dependencia${ params ? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async createDependency(fields) {
    const token = await getCurrentToken();
    const { name, entityId, cities } = fields;

    return axios.post(`${url}/v1/dependencia/crear`, {
        nombre: name,
        idEntidad: parseInt(entityId),
        ciudades: cities
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async editDependency(fields) {
    const token = await getCurrentToken();
    const { id, name, entityId, cities, alternateNames } = fields;

    return axios.post(`${url}/v1/dependencia/editar?${ paramsToQuery({ id }) }`, {
      nombre: name,
      idEntidad: entityId,
      ciudades: cities,
      nombresAlternos: alternateNames
    },
    {
      headers: getHeaders(token)
    });
  },
  async getCampaigns(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/campana${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async getCampaignGuideline(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/campana/pauta${params ? `?${ paramsToQuery(params) }` : ''}`, { headers: getHeaders(token) })
      .then(result => result.data)
  },
  async createCampaign(fields) {
    const token = await getCurrentToken();
    const { state, station, order, time, stationId, guidelines } = fields;
    
    return axios.post(`${url}/v1/campana/crear`, {
        estado: state,
        siglas: station,
        orden: order,
        tipo: time,
        idEstacion: stationId,
        pautas: guidelines
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async updateReporte(idPauta) {
    const token = await getCurrentToken();

    return axios.post(`${url}/v1/pauta/cambiar-reporte`, {id: idPauta}, {headers: getHeaders(token)});
  },
  async getGuidelines(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/pauta${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async getStates(params) {
    const token = await getCurrentToken();

    params = { ...params, pageSize: '0' };
    return axios.get(`${url}/v1/estado${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async createState(fields) {
    const token = await getCurrentToken();
    const { id, alternateNames } = fields;

    return axios.post(`${url}/v1/estado/crear`, {
        id,
        nombresAlternos: alternateNames 
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async editState(fields) {
    const token = await getCurrentToken();
    const { id, alternateNames } = fields;

    return axios.post(`${url}/v1/estado/editar?${ paramsToQuery({ id }) }`, { 
        nombresAlternos: alternateNames
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async getStations(params) { 
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/estacion${ params? `?${ paramsToQuery(params) }` : '' }`, {
        headers: getHeaders(token)
      })
      .then(result => result.data);
  },
  async createStation(fields) {
    const token = await getCurrentToken();
    const { state, code, acronym, frequency, name } = fields;

    return axios.post(`${url}/v1/estacion/crear`, { 
        estado: state,
        clave: code,
        siglas: acronym,
        frecuencia: frequency,
        nombre: name
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async editStation(fields) {
    const token = await getCurrentToken();
    const { id, code, state, acronym, alternateAcronyms, frequency, name, cadena, 
      descripcion, concesionario, monitoreable, licencia } = fields;

    return axios.post(`${url}/v1/estacion/editar?${ paramsToQuery({ id }) }`, { 
        clave: code,
        estado: state,
        siglas: acronym,
        frecuencia: frequency,
        nombre: name,
        cadena: cadena,
        siglasAlternas: alternateAcronyms,
        descripcion: descripcion,
        concesionario: concesionario,
        monitoreable: monitoreable,
        licencia: licencia
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async getCities(params) {  
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/ciudad?pageSize=0${ params? `&${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data); 
  },

  async getCitiesList(params) {  
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/ciudad/lista?${ params? `&${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data); 
  },
  async editCity(fields) {
    const token = await getCurrentToken();
    let { id, timeZone, state, stations, name, rtunnel_port, alternateNames, type, schedule, activo, idGrupo } = fields;   
    console.log(idGrupo);
    return axios.post(`${url}/v1/ciudad/editar?${ paramsToQuery({ id }) }`, {
        idEstado: state,
        zonaHoraria: timeZone,
        estaciones: stations,
        nombresAlternos: alternateNames,
        nombre: name,
        rtunnel_port, 
        tipo: type,
        schedule,
        activo: activo,
        idGrupo: idGrupo
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async setCityStations(fields) {
    const { id, stations } = fields;
    const token = await getCurrentToken();

    return axios.post(`${url}/v1/ciudad/asignar-estaciones?${ paramsToQuery({ id }) }`, {
        estaciones: stations
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async updateCityStationStatus(fields) {
    const { cityId: id, stationId, status, comment } = fields;
    const token = await getCurrentToken();

    return axios.post(`${url}/v1/ciudad/calidad-estacion?${ paramsToQuery({ id }) }`, {
        estacion: stationId,
        calidad: status,
        comentario: comment
      },
      {
        headers: getHeaders(token)
      }
    );
  }, 
  async getCityStationsStatus(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/ciudad/calidad?${ params? `&${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data); 
  },
  async editAudio(fields) {
    const token = await getCurrentToken();
    const { id, name, programacion, clave, idEntidad, area, duracion, path } = fields;
    let data = {
      nombre: name,
      programacion: programacion,
      clave: clave,
      idEntidad: idEntidad, 
      area: area,
      duracion: duracion,
    }

    if(path) {
      data.ruta = path;
    }
    
    return axios.post(`${url}/v1/audio/editar?${ paramsToQuery({ id }) }`,data,
    {
      headers: getHeaders(token)
    });
  },
  async editAudioVersion(fields) {
    const token = await getCurrentToken();
    const { id, programacion, clave, nombre} = fields;

    let _audioName = nombre;

    if(_audioName.includes(".mp3")) {
      _audioName = _audioName.substring(0, length -4);
    }

    return axios.post(`${url}/v1/audio-version/editar?${ paramsToQuery({ id }) }`, {
      programacion: programacion,
      clave: clave,
      nombre: _audioName
    },
    {
      headers: getHeaders(token)
    });
  },
  async createAudio(fields) {
    const token = await getCurrentToken();
    const { key, dependency, name, path, date, duration, dependencyId, programacion, area, idEntidad } = fields;
    
    return axios.post(`${url}/v1/audio/crear`, {
      clave: key,
      dependencia: dependency,
      idDependencia: dependencyId,
      nombre: name,
      ruta: path,
      fecha: date,
      duracion: duration,
      area: area,
      idEntidad: idEntidad,
      programacion: programacion
    },
    {
      headers: getHeaders(token)
    });
  },
  async createAudioVersion(fields) {
    const token = await getCurrentToken();
    const { id, name, path, programacion, clave } = fields;

    return axios.post(`${url}/v1/audio/version?${ paramsToQuery({ id }) }`, {
      nombre: name,
      programacion: programacion,
      clave: clave,
      ruta: path
    },
    {
      headers: getHeaders(token)
    });
  },
  async deleteAudioVersion(fields) {
    const token = await getCurrentToken();
    const { id } = fields;

    return axios.post(`${url}/v1/audio-version/eliminar?${ paramsToQuery({ id }) }`, {},
    {
      headers: getHeaders(token)
    });
  },
  async getAudios(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/audio${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async getAudiosList(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/audio/lista${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async deleteAudio(id) {
    const token = await getCurrentToken();

    return axios.post(`${url}/v1/audio/eliminar`, {
        id: id
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async getFiles(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/archivo${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async getUsers(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/usuario${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async getProfile(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/perfil${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async editProfile(fields) {
    let { name } = fields;
    const token = await getCurrentToken();

    return axios.post(`${url}/v1/perfil/editar`, {
        nombre: name
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async getPermissions(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/permiso${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async editUser(fields) {
    const token = await getCurrentToken();
    let { id, entityId, email, dependencies, name, role, permissions, key } = fields;
    
    return axios.post(`${url}/v1/usuario/editar?${ paramsToQuery({ id }) }`, {
        idEntidad: entityId,
        nombre: name,
        rol: role,
        permiso: permissions,
        dependencias: dependencies,
        correo: email,
        clave: key
      },
      {
        headers: getHeaders(token)
      }
    );
  },
  async createUser(fields) {
    const token = await getCurrentToken();
    const { entityId, email, dependencies, name, role, permissions, key } = fields;

    return axios.post(`${url}/v1/usuario/crear`, {
        idEntidad: entityId,
        nombre: name,
        rol: role,
        permiso: permissions,
        dependencias: dependencies,
        correo: email,
        clave: key
    },
    {
      headers: getHeaders(token)
    });
  },
  async getStationReport(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/reporte/estacion${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async getCampaignReport(params) {
    const token = await getCurrentToken();
    const { id, only } = params;
    
    return axios.get(`${url}/v1/reporte/${ id }${ only? `?only=${ only }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async replaceCampaignGuideline(fields) {
    const token = await getCurrentToken();
    const { id, audioId, date } = fields;

    return axios.post(`${url}/v1/campana/sustitucion`, {
      campana: id,
      audio: audioId,
      fecha: date
    },
    {
      headers: getHeaders(token)
    });
  },
  async updateGuidelineComment(fields, type) {
    const { guidelineId: idPauta, _comment } = fields;
    const token = await getCurrentToken();
    const data = type === 'interno' ? {comentarioInterno: _comment} : {comentario: _comment}

    return axios.post(`${url}/v1/campana/actualizar-comentario?${ paramsToQuery({ idPauta }) }`, data,
    {
      headers: getHeaders(token)
    });
  },
  async getDevicesRemainingFiles() {
    const token = await getCurrentToken();

    return axios.get(`${ url }/v1/info/por-caja`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async getDeviceInfo(params) {
    const token = await getCurrentToken();

	  return axios.get(`${url}/v1/info/archivo${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async generateCity(nombre) {
    const token = await getCurrentToken();

    return axios.post(`${url}/v1/ciudad/generar`, 
      {
        nombre: nombre
      },
      {
        headers: getHeaders(token)
      });
  },
  async campanaDescarga(id) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/campana/descarga?id=${ id }`, 
      {
        headers: getHeaders(token)
      });
  },
  async addCampaignHit(fields) {
    const token = await getCurrentToken();
    const { eventId, timestamp, audioId, esVersion } = fields;

    return axios.post(`${url}/v1/campana/agregar-encontrado`, {
      id: eventId,
      fecha: timestamp,
      audioId: audioId,
      esVersion: esVersion
    },
    {
      headers: getHeaders(token)
    });
  },
  async removeCampaignHit(fields) {
    const token = await getCurrentToken();
    const { eventId, timestamp } = fields;

    return axios.post(`${url}/v1/campana/eliminar-encontrado`, {
      id: eventId,
      fecha: timestamp
    },
    {
      headers: getHeaders(token)
    });
  },
  async addEventsToCampaign(fields) {
    const token = await getCurrentToken();
    const { guidelineId: idPauta, dates } = fields;

    return axios.post(`${url}/v1/campana/agregar-eventos?${ paramsToQuery({ idPauta }) }`, {
      fechas: dates
    },
    {
      headers: getHeaders(token)
    });
  },
  async createRTCCampaign(fields) {
    const token = await getCurrentToken();
    const { order, pautas } = fields;

    return axios.post(`${url}/v1/campana/crear-manual`, {
      folio: order,
      pautas: pautas
    },
    {
      headers: getHeaders(token)
    });
  },
  async redoEventSearch(fields) {
    const token = await getCurrentToken();
    const { id } = fields;

    return axios.post(`${url}/v1/calendario/reiniciar-estado`, {
      id
    },
    {
      headers: getHeaders(token)
    });
  },
  async getBitacoraRecords(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/bitacora${ params? `?${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },
  async addBitacoraRecord(fields) {
    const token = await getCurrentToken();
    const { cityId: id, comment } = fields;
    
    return axios.post(`${url}/v1/ciudad/bitacora?${ paramsToQuery({ id }) }`, {
      comentario: comment
    },
    {
      headers: getHeaders(token)
    });
  },
  async removeBitacoraRecord(fields) {
    const token = await getCurrentToken();
    const { id } = fields;

    return axios.post(`${url}/v1/bitacora/eliminar?${ paramsToQuery({ id }) }`, {
    },
    {
      headers: getHeaders(token)
    });
  },

  async addIncidentReport(fields) {
    const token = await getCurrentToken();
    const { eventId } = fields;
    
    return axios.post(`${url}/v1/campana/reporte-incidente`, {
      id: eventId
    },
    {
      headers: getHeaders(token)
    });
  },
  async getLinksExcel(fields) {
    const token = await getCurrentToken();
    
    return axios.get(`${url}/v1/excel?html=1&fecha=2020/09/29&json=1`,
    {
      headers: getHeaders(token)
    })
    .then(result => result.data);
  },

  async addPlantilla(fields) {
    const token = await getCurrentToken();
    
    return axios.post(`${url}/v1/plantilla/guardar`, fields,
    {
      headers: getHeaders(token)
    });
  },

  async getPlantillas(id) {
    const token = await getCurrentToken();
    
    return axios.get(`${url}/v1/plantilla?id=${id}&pageSize=-1`,
    {
      headers: getHeaders(token)
    });
  },

  async addCampaignPlantilla(fields) {
    const token = await getCurrentToken();
    
    return axios.post(`${url}/v1/campana/crear-desde-plantilla`, fields,
    {
      headers: getHeaders(token)
    });
  },

  async getGrupos(expand) {
    const token = await getCurrentToken();
    
    if(expand) {
      return axios.get(`${url}/v1/grupo?expand=ciudades.ciudadEstacion.estacion`,
      {
        headers: getHeaders(token)
      }).then(result => result.data);
     
    }
    else {
      return axios.get(`${url}/v1/grupo`,
      {
        headers: getHeaders(token)
      }).then(result => result.data);
    }
  },

  async addGrupo(fields) {
    const token = await getCurrentToken();
    
    return axios.post(`${url}/v1/grupo/crear`, fields,
    {
      headers: getHeaders(token)
    });
  },

  async updateGrupo(fields) {
    const token = await getCurrentToken();
    
    return axios.post(`${url}/v1/grupo/editar?id=${fields.id}`, fields,
    {
      headers: getHeaders(token)
    });
  },

  async deleteGrupo(id) {
    const token = await getCurrentToken();

    return axios.post(`${url}/v1/grupo/eliminar?id=${id}`, {
    },
    {
      headers: getHeaders(token)
    });
  },
  async updateStationMonitoreable(fields) {
    const token = await getCurrentToken();
    
    return axios.post(`${url}/v1/ciudad/cambiar-monitoreable`, fields,
    {
      headers: getHeaders(token)
    });
  },
  async getFile(id) {
    return axios.get(`${url}/v1/reproductor?id=${id}&expand=ciudad,estacion`,{})
      .then(result => result.data);
  },
  async getPlantillaAudios(id) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/plantilla-audios?plantilla=${id}`, {
      headers: getHeaders(token)
    }).then(result => result.data);
  },
  async getTiempos() {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/tiempos`, {
      headers: getHeaders(token)
    }).then(result => result.data);
  },
  async getBackgrouds() {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/segundo-plano`, {
      headers: getHeaders(token)
    }).then(result => result.data);
  },
  async getBackgroudsLista(params) {
    const token = await getCurrentToken();

    return axios.get(`${url}/v1/segundo-plano/lista?${ params? `&${ paramsToQuery(params) }` : '' }`, {
      headers: getHeaders(token)
    }).then(result => result.data);
  },
  async getReport(ids) {
    const token = await getCurrentToken();

    return axios.get(`${url}/na/reporte/url?id=${ids}`, {
      headers: getHeaders(token)
    }).then(result => result);
  },
  async getReportDataWeb(ids) {
    const token = await getCurrentToken();

    return axios.get(`${url}/na/reporte?id=${ids}`, {
      headers: getHeaders(token)
    }).then(result => result.data);
  },
  async getReportWeb(data) {
    const token = await getCurrentToken();

    return axios.get(`${url}/na/reporte/web?ciudad=${data.idCiudad}&frecuencia=${data.frecuencia}&fecha=${data.fecha}`, {
      headers: getHeaders(token)
    }).then(result => result.data);
  }
}

export default FourierApi;