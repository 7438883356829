import React, { useState, useContext, useEffect } from 'react';
import { Grid, Paper, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Empty from './Empty';
import SourceInfo from './SourceInfo';
import Loader from './../../../components/Loader';
import StreamContext from './../../../context/stream/streamContext';
import { STREAM_URL } from './../../../constants/urls';
import StreamPlayer from './StreamPlayer';
import { useSelector } from 'react-redux';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import {
  TV, 
  RADIO,
  RADIO_INTERNET,
  SOURCES_PROP,
  SOURCE_ID_PROP
} from './../../../constants/boxType';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    height: '355px'
  },
  player: {
    width: '0!important',
    height: '0!important'
  }
}));

const StationDisplay = () => {
  const classes = useStyles();
  const { city, source, setSourceIndexPlaying, setStreaming,
            streaming, loading, setLoadingStream, error,
            setError } = useContext(StreamContext);
  const [ url, setUrl ] = useState('');
  const [ currentSource, setCurrentSource ] = useState(null);
  const firebase = useFirebase();
  useFirebaseConnect(['devices', 'stations']);
  const devices = useSelector(state => state.firebase.data.devices);  
  const stations = useSelector(state => state.firebase.data.stations);  
  const [idCiudad, setIdCiudad] = useState('');
  const [tipo, setTipo] = useState('');

  useEffect(() => {
    stopCurrentStream(idCiudad, tipo);

    if (source) {
      updateStreamOnFirebase(source.idCiudad, source.ciudadTipo)
        .then(() => {
          setIdCiudad(source.idCiudad);
          setTipo(source.ciudadTipo);
          const url = getStreamUrl(source.idCiudad, source.ciudadTipo);
          setUrl(url);
          setLoadingStream(true);
          const sourceIndex = getSourceIndex(source.idCiudad, source.ciudadTipo);
          setSourceIndexPlaying(sourceIndex);
          setCurrentSource({ 
            cityId: source.idCiudad,
            sourceIndex,
            type: source.ciudadTipo
          });
        });
    }
  }, [ source ]);

  const getFirebaseSourcePath = (cityId, sourceIndex, type) => {
    if (!cityId || typeof sourceIndex !== 'number' || sourceIndex < 0) {
      throw Error('Invalid City');
    }
    const sourcesProp = SOURCES_PROP[type];
    return `devices/${ cityId }/${ sourcesProp }/${ sourceIndex }`
  }

  const stopCurrentStream = (id, tipo) => {
    if (currentSource) {
      const { sourceIndex } = currentSource;
      if (typeof sourceIndex === 'number' && sourceIndex >= 0) {
        const path = getFirebaseSourcePath(id, sourceIndex, tipo);
        firebase
          .update(path, {
            stream: false
          })
          .then(() => {
            setUrl('');
            setCurrentSource(null);
            setStreaming(false);
          })
      }
    }
  }

  const updateStreamOnFirebase = (id, tipo) => {
    return new Promise((resolve, reject) => {
      const sourceIndex = getSourceIndex(id, tipo);
      if (source && sourceIndex >= 0) {
        const path = getFirebaseSourcePath(id, sourceIndex, tipo);
        firebase
          .update(path, {
            stream: true
          })
          .then(() => resolve())
          .catch(() => reject());
      }
    });
  }

  const getStreamUrl = (idCiudad, tipo) => {
    console.log("getStreamUrl", idCiudad, tipo)
    let url = searchStreamUrl(idCiudad, tipo);
    if (!url) {
      url = generateStreamUrl(idCiudad, tipo);
    }
    return url;
  }

  const searchStreamUrl = (id, tipo) => {
    let url;
    const stream = devices[id].stream;
    if (stream) {
      url = stream[source.clave];
    }
    return url;
  }

  const generateStreamUrl = (id, tipo) => {
    const sourceIndex = getSourceIndex(id, tipo);
    let url = null;
    switch (tipo) {
      case RADIO:
        url = `${ STREAM_URL }/${ id }${ sourceIndex }`;
        break;
      case TV:
        url = `${ STREAM_URL }/tv-${ id }${ sourceIndex }.mp3`
        break;
      case RADIO_INTERNET:
        url = `${ STREAM_URL }/net-${ id }${ sourceIndex }.mp3`
        break;
    }
    return url;
  }

  const getSourceIndex = (id, tipo) => {
    const device = devices[id];
    const sourcesProp = SOURCES_PROP[tipo];
    const sourceProp = SOURCE_ID_PROP[tipo];
    const sources = device[sourcesProp];
    const index = 
      sources.map(source => source[sourceProp])
      .indexOf(source.clave);
    return index;
  }

  return (
    <Grid container item xs={9} direction="column" alignItems="stretch">
      <Paper className={classes.root}>
        {
          loading && source?
            <Loader/>
            : streaming && source?
              <SourceInfo city={city} source={source} />
              : <Empty/>
        }
      </Paper>
      <StreamPlayer url={url} />
      <Snackbar open={Boolean(error)} onClose={() => setError(false)}>
        <Alert
          elevation={6}
          variant="filled" 
          onClose={() => setError(false)}
          severity="error">
          La estación no puede transmitirse por el momento.
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default StationDisplay;