import React, { useState, useEffect } from 'react'
import DetailCard from './../../ScheduleView/EvidencePlayer/DetailCard';

function NewEvidencePlayer({ cityId, date, eventId, sourceKey,
  transmitedDate, timezone, onClose, playing }) {

  const [ hide, setHide ] = useState(false);

  useEffect(() => {
    let isMounted = true;

    setHide(true);
    setTimeout(() => {
      if(isMounted) {
        setHide(false);
      }
    }, 100);
    
    return () => isMounted = false;
  }, [ date ]);

  if (!playing || hide) {
    return null;
  }

  const ad = {
    city: cityId,
    eventId: eventId,
    fid: date, 
    date, 
    found: [ transmitedDate ], 
    station: sourceKey, 
    status: 'done', 
    timezone
  };

  return (
    <DetailCard
      ad={ad}
      onClose={onClose}
    />
  );
}

export default NewEvidencePlayer