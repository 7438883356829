import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button, DialogContent,
  Typography } from '@material-ui/core';

export default function ConfirmDialog ({open, onClose, onAccept, estacionesIds = [], stationsAndChannels}) {
  return (
    <div>
      <Dialog open={open}>
      <DialogTitle>¿Terminar campaña?</DialogTitle>
      <DialogContent>
        {
          estacionesIds.map(_estacion => { 
            const station = stationsAndChannels.find(s => s.id == _estacion);

            return (
            <div key={_estacion}>
              <Typography >
                { station && station.estado ? station.estado  : "Sin estado" }] 
                [{ station && station.frecuencia ? station.frecuencia : "Sin frecuencia" }]
                [{ station && station.siglas ? station.siglas : "Sin siglas"}]
                { " " + (station && station.nombre ? station.nombre : "Sin nombre") } 
                { " " + (station && station.descripcion ? station.descripcion : 'Sin descripción') }
                [{ " " + (station && station.cadena  ? station.cadena : 'Sin cadena') }]
              </Typography> 
              <hr />
            </div>
          )})
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => onAccept()} color="primary">
          Aceptar
        </Button>
      </DialogActions>
      </Dialog>
    </div>
  )
}
