.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.flex {
  flex: 1;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.paper {
  padding: 16px;
  margin: 24px 16px 0 16px;
}

.marginTop {
  margin-top: 16px;
}

table.tarifas input::-webkit-outer-spin-button,
table.tarifas input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table.tarifas input[type=number] {
  -moz-appearance:textfield;
}

table.tarifas .MuiInput-underline:hover:not(.Mui-disabled):before {
  border: 3px solid #4db8f18f!important;
  height: 80%;
  border-radius: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3bdbd; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9c9696; 
}