import React, { useState, useEffect } from 'react';
import Loader from '../../../components/Loader';
import Container from '../../../components/Container';
import AbsoluteFab from '../../../components/AbsoluteFab';
import FourierApi from '../../../utils/FourierApi';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MANUAL } from './../../../constants/campaignTypes';
import Report from '../Report';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(20),
  },
}));

const CampaignReport = ({ match }) => {
  const classes = useStyles();
  const [ report, setReport ] = useState([]);
  const [ source, setSource ] = useState(null);
  const [ loaded, setLoaded ] = useState(false);
  const [ isManual, setIsManual ] = useState(false);
  const [ timezone, setTimezone ] = useState(null);
  const [ filteredBy, setFilteredBy ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  useEffect(() => {
    let isMounted = true;

    const fetchSource = report => {
      const { estacion: siglas, estado } = report;
      return new Promise((resolve, reject) => {
        FourierApi
          .getStations({ 
            siglas,
            estado
          })
          .then(sources => {
            if (sources.length) {
              resolve(sources[0]);
            }
            else {
              reject();
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
    FourierApi
      .getCampaignReport({
        id: match.params.id
      })
      .then(report => {
        setReport(report);
        setTimezone(report.ciudad && report.ciudad.zonaHoraria || null);
        if (report.tipo === MANUAL) {
          setIsManual(true);
          setSource(null);
          setLoaded(true);
        }
        else {
          fetchSource(report)
            .then(source => {
              if(isMounted) {
                setSource(source)
              }
            })
            .catch(() => {
              if(isMounted) {
                setSource(null);
              }
            })
            .finally(() => { 
              if(isMounted) {
                setLoaded(true)
              }    
            });
        }
    }); 

    return () => isMounted = false; 
  }, []);

  const handleStatusSelect = status => {
    if (status === 'all') {
      setFilteredBy(null);
    }
    else {
      setFilteredBy(status)
    }
    if (isManual) {
      setLoaded(false);
      FourierApi
      .getCampaignReport({
        id: match.params.id,
        only: status
      })
      .then(report => {
        setReport(report);
        setLoaded(true);
      }); 
    }
  }

  if (!loaded) {
    return <Loader />;
  }

  const downloadRar  = async () => {
    setLoading(true);

    try {
      const response = await FourierApi.campanaDescarga(match.params.id);
      const config = { responseType: 'blob' };
      const zip = new JSZip();
      const reports = response.data.map(data => ({...data, cadena: data.cadena ? data.cadena : 'Sin cadena'}))
      const length = reports.length;

      for (let index = 0; index < length; index++) {
        const blob = await axios.get(reports[index].url, config);

        zip.file('Reporte_Semanal_' + reports[0].fecha + '/' + reports[index].idEstado + '/' + reports[index].ciudad + '/'
          + reports[index].cadena + '/' + reports[index].frecuencia + '/' +  reports[index].fecha + '/'
          + reports[index].cadena + '_' + reports[index].siglas + '_'+ reports[index].fecha + '_'
          + '.xlsx', blob.data);
      }
      
      zip.generateAsync({ type: "blob" })
        .then(content => {
          saveAs(content, 'Reporte_Semanal_' + reports[0].fecha + '.zip');
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.log(error);    
    }
  }

  return (
    <Container className={classes.root}>
      {/* <DownloadButton
        baseUrl={API_URL + '/v1/reporte/archivo/' + match.params.id}
        manual={isManual}
        status={filteredBy}
      /> */}
      <AbsoluteFab
        disabled={loading}
        color="primary"
        title="Descargar"
        onClick={downloadRar}>
        { loading ?  <CircularProgress /> : <GetAppIcon /> }
      </AbsoluteFab>
      <Report
        className={classes.report}
        autocomplete
		    manual={isManual}
        placeholder={'Busca por clave de audio.'}
        filter={['clave']}        
        report={report.eventos}
        source={source}
        timezone={timezone}
        onFilterSelect={handleStatusSelect} 
      />
    </Container>
  );
};

export default CampaignReport;