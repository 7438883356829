import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: "200px",
    width: "70%",
    margin: "0 auto",
    textAlign: "center",
    fontFamily: "roboto",
    backgroundColor: "#bbb4b4",
    padding: "19px 10px",
    borderRadius: "33px",
    userSelect: "none",
    color: "#ffffff",
    transition: "all 0.2s ease",
    opacity: "0.85",
    '&:hover': {
      opacity: "1"
    }
  }
}));

const MessageEmpty = ({ text, className: customClassName }) => {
  const classes = useStyles();
  let className = classes.root;
  if (customClassName) {
    className += ' ' + customClassName;
  }
  return (
    <div
      className={className}>
      { text }
    </div>
  );
};

export default MessageEmpty;