import React, { useState } from 'react';
import { Tabs, Tab, AppBar, Badge} from '@material-ui/core'

import {
  Storage as StorageIcon,
  Radio as RadioIcon,
  LiveTv as LiveTvIcon,
  Cloud as CloudIcon,
  SdStorage as SdStorageIcon,
  SettingsEthernet as SettingsEthernetIcon
} from '@material-ui/icons'

const MonitorTabs = ({ diskWarnedCounter, tunnelWarnedCounter, onChange }) => {

  const [ selectedTab, setSelectedTab ] = useState(0);

  return (
    <AppBar position="static">
      <Tabs
        value={selectedTab}
        onChange={(e, selectedTab) => {
          setSelectedTab(selectedTab);
          onChange && onChange(e, selectedTab);
        }}
        >
        <Tab label="Todas" icon={<StorageIcon />} />
        <Tab label="Radio FM" icon={<RadioIcon />} />
        <Tab label="Televisión" icon={<LiveTvIcon />} />
        <Tab label="Internet" icon={<CloudIcon />} />
        <Tab 
          icon={
            <Badge
              color="error"
              badgeContent={diskWarnedCounter}>
              <SdStorageIcon />
            </Badge>
          } 
          />
        <Tab
          icon={
            <Badge
              color="error"
              badgeContent={tunnelWarnedCounter}>
              <SettingsEthernetIcon />
            </Badge>
          }
          />
      </Tabs>
    </AppBar>
  )
};

export default MonitorTabs;