import React from 'react';
import { 
  Paper,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  FormControlLabel,
  TableFooter,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase, firebaseConnect, isLoaded } from 'react-redux-firebase';
import Api from '../../utils/Api';
import moment from 'moment-timezone';
import '../../App.css';
import Container from './../../components/Container';
import Title from './../../components/Title';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from './../../components/Loader';
import {
  Wifi as WifiIcon
} from '@material-ui/icons';

const styles = {
  paper: {
    padding: '0',
  },
  td: {
    position: 'relative'
  },
  arrow: {
    position: 'absolute',
    left: '-15px',
    top: 'calc(51% - 12px)'
  }
}

class DeviceInternetRadiosView extends React.Component {
  constructor(props) {
    super(props);
    this.deviceID = props.match.params.id;
    this.state = {
      cities: [],
      device: null,
      snackbar: false,
      alertMessage: '',
      sources: null,
      sourcesMap: [],
      sourcesNames: {},
      extendMessage: null,
      messageIndex: {index: null, message: null, error: null},
      markIndex: null
    }
  }

  handleCloseSnackbar(event, reason) {
    this.setState({snackbar: false})
  }

  handleChange(event, adapterIndex) {
    const sourceID = event.target.value;
    const index = adapterIndex || event.target.getAttribute('adapter-index');
    const names = this.state.sourcesNames;
    Api
      .putBoxInternetRadio(this.deviceID, sourceID, index)
      .then(res => {
        if (res.success) {
          const newSources = [ ...this.state.sources ];
          newSources[index].id = sourceID;
          const newMessageIndex = {
            ...this.state.messageIndex,
            index: parseInt(index, 10),
            message: 'Guardado correctamente',
            error: null
          };
          this.setState({
            sources: newSources,
            messageIndex: newMessageIndex,
            markIndex: null 
          });
        }
        else {
          this.setState({
            messageIndex: {
              index: parseInt(index, 10),
              message: `Ya se está grabando la estación "${names[res.occupied_source]}" `,
              error: true
            },
            markIndex: res.occupied_index,
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  showAlert(message) {
    this.setState({
      snackbar: true,
      alertMessage: message
    })
  }

  extendSources() {
    return Api.extendBoxInternetRadios(this.deviceID).then(res => {
      if (res.success) {
        const sources = this.state.sources
        sources.push({status: 'idle'})
        this.setState({
          sources: sources,
          extendMessage: 'Extendido correctamente'
        })
      } else {
        this.setState({extendMessage: `Error ${res.error}`})
      }
      setTimeout(() => {
        this.setState({extendMessage: null})
      }, 3000)
    })
    .catch(error => {
      this.setState({extendMessage: `Error ${error}`})
    })
  }

  getSourceLabel(source) {
    const city = this.state.citiesMap[source.city];
    return (
      (city ? `[${city.description}] ` : '')
      + (source.frequency ? `${source.frequency} - ` : '')
      + source.name
    )
  }

  render() {
    const state = this.state;
    const { device } = this.state;
    const msgi = this.state.messageIndex;
    const thereare = state.sources && state.sources.length;
    const showIndex = false;
    if (!isLoaded(this.props.auth) || !this.state.sources || !this.state.cities.length) {
      return <Loader/>;
    }
    return (
      <Container press>
        <Paper style={styles.paper}>
          <Title pre="Caja" text={device.description || this.deviceID} icon={WifiIcon}/>
          <Table>
            <TableBody>
              <TableRow>
                {showIndex
                  ? <TableCell style={{ textAlign: 'center' }} width="50">Adaptador</TableCell>
                  : null }
                <TableCell>Origen de grabación</TableCell>
                <TableCell>Última actualización</TableCell>
                <TableCell>Comprimir audio</TableCell>
                <TableCell></TableCell>
              </TableRow>
              {
                this.state.sources ? this.state.sources.map((row, index) => {
                  return (
                    <TableRow
                      row={row}
                      key={index}
                      >
                      {
                        showIndex
                          ? <TableCell style={{textAlign: 'center'}}>{ index }</TableCell>
                          : null
                      }
                      <TableCell style={styles.td}>
                        <Autocomplete
                          value={this.state.sourcesMap.find(source => source.id === row.id)}
                          onChange={(e, value) => {
                            if (value) {
                              this.handleChange({ 
                                target: { value: value.id } },
                                index.toString() 
                              )
                            }
                          }}
                          options={this.state.sourcesMap}
                          // adapter-index={index}
                          getOptionLabel={source => this.getSourceLabel(source)}
                          style={{ width: 300 }}
                          renderInput={params => (
                            <TextField {...params} label="Estación" variant="outlined" fullWidth/>
                          )}
                          />
                        {
                          index === state.markIndex? 
                            <ArrowForwardIcon width="16" color="error" style={styles.arrow} />
                            : null
                        }
                        {
                          msgi.index === index? 
                            <div style={{color: msgi.error ? 'red' : 'green', padding: '5px 8px'}}>
                              { state.messageIndex.message }
                            </div>
                            : null
                        }
                      </TableCell>
                      <TableCell>{moment(row.lastUpdate).format('LLL')}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch value="1"
                              checked={row.ensureSamplerate ? true : false}
                              onChange={() => {
                                const value = row.ensureSamplerate ? null : 16000;
                                this.changeSourceSamplerate(index, value);
                                row.ensureSamplerate = value;
                                this.forceUpdate();
                              }} 
                              />
                          }
                          label={row.ensureSamplerate ? (row.ensureSamplerate / 1000) + ' kHz' : ''}
                        />
                      </TableCell>
                    </TableRow>
                  )
                }) : null
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    textAlign: thereare ? 'left' : 'center',
                    padding: !thereare ? '30px 0' : null
                  }}>
                  {!thereare
                    ? (<h3>No se han asignado radios de Internet a esta caja</h3>): null}
                  <Button
                    variant="contained" color="primary"
                    onClick={this.extendSources.bind(this)}
                    size={!thereare ? 'large' : 'medium'}>
                    {!thereare ? 'Comienza agregando uno' : 'Extender Espacio'}
                  </Button>
                  {state.extendMessage ? (
                    <Button>
                      {state.extendMessage}
                    </Button>
                  ) : null}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Container>
    )
  }

  changeSourceSamplerate(index, value) {
    this.props.firebase
      .update(`devices/${this.deviceID}/radiosInternet/${index}`, {
        ensureSamplerate: value
      });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.cities && this.props.cities) {
      const cities = [];
      const citiesMap = {...this.props.cities};
      citiesMap['nocity'] = { description: 'Sin ciudad' };
      for (let key in citiesMap) {
        let city = citiesMap[key];
        city.id = key;
        city.sources = [];
        cities.push(city);
      }
      this.setState({
        cities: cities,
        citiesMap: citiesMap
      });
    }
  }

  async componentDidMount() {
    const self = this;

    if (this.props.auth) {
      
      Api.sessionTokenID = this.props.auth.stsTokenManager.accessToken;
      Api.getBoxInternetRadios(this.deviceID).then(res => {
        const names = {};
        res.sources.forEach(item => {
          names[item.id] = item.name;
        })

        // Ordernar por frecuencia de radio
        if (res.sources) {
          let pa, pb;
          res.sources.sort((a, b) => {
            if (a.city > b.city) {
              return -1;
            } else if (a.city < b.city) {
              return 1;
            } else {
              pa = parseFloat(a.frequency);
              pb = parseFloat(b.frequency);
              if (pa === pb) {
                return 0;
              }
              else if (pa > pb) {
                return 1;
              } 
              else {
                return -1;
              }
            }
          })
        }

        self.setState({
          sourcesMap: res.sources,
          sourcesNames: names
        })

        Api.getBoxTunedInternetRadios(this.deviceID).then(res => {
          if (res.success) {
            if (res.sources) {
              res.sources.forEach(src => {
                const source = this.state.sourcesMap[src.id];
                if (source && source.city) {
                  const city = this.state.citiesMap[source.city];
                  if (city) {
                    city.sources.append(src);
                  } else {
                    this.state.citiesMap['nocity'].sources.append(src);
                  }
                }
              })
            }
            self.setState({
              sources: res.sources ? res.sources : [],
              device: res.box,
              cities: this.state.cities
            })
          } else {
            // TODO: something
          }
        })
      })
    }
  }
}

const enhance = compose(
  withFirebase,
  firebaseConnect(['cities']),
  connect((state) => {
    return ({
    cities: state.firebase.data.cities,
    auth: state.firebase.auth
  })})
);

export default enhance(DeviceInternetRadiosView);